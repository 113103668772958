/**
 * The IDs of app rail drawers.
 */
export const PLATFORM_APPRAIL_DRAWER_IDS = {
  STORAGE_EXPLORER: 'storage-explorer' as const,
  NAVIGATOR       : 'navigator' as const,
  WSO_NAVIGATOR   : 'wso-navigator' as const,
  WSO_TEMPLATES   : 'wso-templates' as const,
  WSO_SHAPES      : 'wso-shapes' as const,
  DASHBOARD_ASSETS: 'dashboard-assets' as const,
  DASHBOARD_DATA  : 'dashboard-data' as const,
  DASHBOARD_PAGES : 'dashboard-pages' as const,
};
