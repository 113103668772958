import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogIcon,
  DialogTitle,
  Spacer,
  Stack,
  Tooltip,
} from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

/**
 * @param {Object} props
 * @param {() => void} props.onReopenProject Callback to reopen the project in the web app
 * @param {boolean} props.open Whether the dialog is open
 * @param {(tryAgain?: boolean) => void} props.onClose Callback to close the dialog
 */
export function NewReopenProjectFailedDialog({
  open,
  onClose,
  onReopenProject,
}) {
  const [t] = useTranslation();
  const handleOnReopenProject = useCallback(() => {
    onReopenProject();
    onClose();
  }, [onClose, onReopenProject]);

  return (
    <Dialog
      data-testid="reopen-project-failed-dialog"
      open={open}
      onClose={onClose}>
      <Stack direction="row">
        <DialogIcon severity="error" />
        <Stack
          direction="column"
          width="100%">
          <DialogTitle>
            {t('connection.unableToOpenTheProject')}
            <Spacer />
            <Tooltip
              placement="bottom"
              title={t('connection.close')}>
              <Button
                color="default"
                icon={<CloseIcon />}
                variant="text"
                onClick={onClose} />
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            {t('connection.unableToOpenTheProjectMessage')}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleOnReopenProject}>
              {t('connection.tryAgain')}
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={onClose}>
              {t('shared.cancel')}
            </Button>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default NewReopenProjectFailedDialog;
