import type { ModuleConfig } from '../types';
import { RemoteDataCenter, loadRemoteDataCenter } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'DATACENTER',
  title    : 'datacenter',
  icon     : 'DataCenterIcon',
  // Use `MSSO` productId since these are included if you have a subscription to Minitab Online.
  productId: 'e68147de1c46451bafadcfcc44e196cc',
  card     : {
    title         : 'datacenter',
    description   : 'dataCenterDescription',
    tagline       : 'dataSource',
    icon          : 'DataCenterIcon',
    heroIcon      : 'DataCenterHeroIcon',
    fillColor     : '#ABC3AA',
    hoverFillColor: '#3B783A',
  },
  preloadRemoteModule: loadRemoteDataCenter,
  remoteModule       : RemoteDataCenter,
  storage            : {
    defaultProjectName: 'datacenterDefaultProject',
    defaultExtension  : '.mdpx',
    defaultPlaneState : {},
    filter            : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xlsx'],
    passthroughFilter : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xlsx'],
  },
  prompts: {
    unsavedChanges: {
      title  : 'dataCenterUnsavedChangesPromptTitle',
      message: 'dataCenterUnsavedChangesPromptMessage',
      actions: {
        confirm: 'dataCenterUnsavedChangesPromptConfirm',
      },
    },
  },
};

export default CONFIG;
