import { useCallback, useMemo } from 'react';
import { useEventCallback } from '@mtb/ui';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  STORAGE_PROVIDER_KEYS,
} from '../constants';
import ProjectService from '../services/projects';
import {
  canPlatformOpenInDesktop,
  getProjectDesktopFileScheme,
} from '../utils';
import { useNewCloudStorageStore } from './useCloudStorageStore';

/**
 * Custom hook for managing a cloud storage project.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the cloud storage project.
 */
export const useNewCloudStorageProject = (projectId) => {
  const { projects } = useNewCloudStorageStore();
  const project = useMemo(() => projects[projectId], [projects, projectId]);
  const breadcrumbs = useMemo(
    () => project?.breadcrumbs,
    [project?.breadcrumbs],
  );

  /**
   * Gets the breadcrumbs for the cloud storage project.
   */
  const handleGetBreadcrumbs = useCallback(
    async () => {}, // await cloudStorage.getProjectBreadcrumbs(projectId),
    [],
  );

  /**
   * Gets the checkoutUser for the cloud storage project.
   */
  const handleGetCheckoutUser = useCallback(() => {
    return ProjectService.getCheckoutUser(projectId);
  }, [projectId]);

  /**
   * Handles the download of the cloud storage project.
   */
  const handleDownloadProject = useCallback(() => {
    ProjectService.downloadProject(projectId);
  }, [projectId]);

  /**
   * Renames the cloud storage project.
   */
  const handleRenameProject = useCallback(
    (newName) => {
      return ProjectService.renameProject(projectId, newName);
    },
    [projectId],
  );

  /**
   * Moves the cloud storage project to the given folder.
   */
  const handleMoveProject = useCallback(
    /**
     * @param {import('@').StorageProviderItem} newFolder
     */
    (newFolder) => {}, // cloudStorage.moveProject(projectId, newFolder),
    [],
  );

  /**
   * Shares the cloud storage project.
   */
  const handleShareProject = useCallback(
    async () => {}, // await cloudStorage.shareProject(projectId),
    [],
  );

  /**
   * Shares the cloud storage project.
   */
  const handleOpenInDesktop = useCallback(
    (events) => {
      return ProjectService.openInDesktop(projectId, events);
    },
    [projectId],
  );

  const handleReopenProject = useCallback(
    (events) => {
      return ProjectService.reopenProject(projectId, events);
    },
    [projectId],
  );

  /**
   * Flushes the cloud storage project.
   */
  const handleFlushProject = useCallback(() => {
    return ProjectService.flushProject(projectId);
  }, [projectId]);

  /**
   * Closes the cloud storage project.
   */
  const handleCloseProject = useCallback(
    () => {}, // cloudStorage.closeProject(projectId),
    [],
  );

  /**
   * Enables auto-save for the cloud storage project.
   */
  const handleEnableAutoSave = useCallback(() => {
    return ProjectService.enableAutoSave(projectId);
  }, [projectId]);

  /**
   * Disables auto-save for the cloud storage project.
   */
  const handleDisableAutoSave = useCallback(
    () => {}, // cloudStorage.disableAutoSave(projectId),
    [],
  );

  /**
   * Toggles auto-save for the cloud storage project.
   */
  const handleToggleAutoSave = useCallback(() => {
    return ProjectService.toggleAutoSave(projectId);
  }, [projectId]);

  /**
   * Saves the cloud storage project to the given provider.
   **/
  const handleSaveToProvider = useCallback(
    (providerKey) => {
      return ProjectService.saveToProvider(projectId, providerKey);
    },
    [projectId],
  );

  /**
   * Verifies the connection of the cloud storage project.
   */
  const handleVerifyConnection = useEventCallback(() => {
    return ProjectService.syncProjectInfo(projectId);
  });

  /**
   * Syncs the project information with cloud storage API.
   */
  const handleSyncProject = useCallback(
    () => {}, // cloudStorage.syncProjectInfo(projectId),
    [],
  );

  /**
   * Checks in the cloud storage project.
   */
  const handleCheckIn = useCallback(
    () => {}, // cloudStorage.checkInProject(projectId),
    [],
  );

  /**
   * Checks out the cloud storage project.
   */
  const handleCheckOut = useCallback(
    () => {}, // cloudStorage.checkOutProject(projectId),
    [],
  );

  const handleSetOperation = useCallback(
    (operation) => {
      ProjectService.updateProject(projectId, {
        operation,
      });
    },
    [projectId],
  );

  const isAutoSaving = useMemo(() => {
    return (
      project?.connection.autoSaveStatus === AUTO_SAVE_STATUS.STARTED &&
      project?.connection.cloudStatus === CLOUD_STATUS.OWNED
    );
  }, [project?.connection.autoSaveStatus, project?.connection.cloudStatus]);

  const hasConnection = useMemo(
    () => project?.connection.type !== STORAGE_PROVIDER_KEYS.LOCAL,
    [project?.connection.type],
  );

  const isOwned = useMemo(
    () => project?.connection.cloudStatus === CLOUD_STATUS.OWNED,
    [project?.connection.cloudStatus],
  );

  const isReadOnly = useMemo(
    () => project?.connection.cloudStatus === CLOUD_STATUS.READONLY,
    [project?.connection.cloudStatus],
  );

  const canInteractWithProject = useMemo(() => {
    return !Boolean(project?.operation);
  }, [project?.operation]);

  const canInteractWithCloudProvider = useMemo(() => {
    return (
      canInteractWithProject &&
      project?.connection.cloudStatus === CLOUD_STATUS.OWNED
    );
  }, [canInteractWithProject, project?.connection.cloudStatus]);

  const canToggleAutoSave = useMemo(() => {
    return canInteractWithProject;
  }, [canInteractWithProject]);

  const hasDesktopScheme = useMemo(() => {
    return (
      canPlatformOpenInDesktop() &&
      Boolean(getProjectDesktopFileScheme(project))
    );
  }, [project]);

  const canOpenInDesktop = useMemo(() => {
    return hasDesktopScheme && canInteractWithCloudProvider && isAutoSaving;
  }, [canInteractWithCloudProvider, hasDesktopScheme, isAutoSaving]);

  const canRenameProject = useMemo(() => {
    return canInteractWithCloudProvider && isAutoSaving;
  }, [canInteractWithCloudProvider, isAutoSaving]);

  const canMoveProject = useMemo(() => {
    return canInteractWithCloudProvider && isAutoSaving;
  }, [canInteractWithCloudProvider, isAutoSaving]);

  const canShareProject = useMemo(() => {
    return (
      canInteractWithCloudProvider &&
      isAutoSaving &&
      project?.type === STORAGE_PROVIDER_KEYS.ONE_DRIVE
    );
  }, [canInteractWithCloudProvider, isAutoSaving, project?.type]);

  return useMemo(
    () => ({
      ...project,
      autoSaveStatus  : project?.connection.autoSaveStatus,
      cloudStatus     : project?.connection.cloudStatus,
      operation       : project?.operation,
      breadcrumbs,
      hasConnection,
      hasDesktopScheme,
      isAutoSaving,
      isOwned,
      isReadOnly,
      canInteractWithProject,
      canInteractWithCloudProvider,
      canToggleAutoSave,
      canOpenInDesktop,
      canRenameProject,
      canMoveProject,
      canShareProject,
      move            : handleMoveProject,
      rename          : handleRenameProject,
      download        : handleDownloadProject,
      flush           : handleFlushProject,
      close           : handleCloseProject,
      share           : handleShareProject,
      checkIn         : handleCheckIn,
      checkOut        : handleCheckOut,
      openInDesktop   : handleOpenInDesktop,
      enableAutoSave  : handleEnableAutoSave,
      disableAutoSave : handleDisableAutoSave,
      reopenProject   : handleReopenProject,
      toggleAutoSave  : handleToggleAutoSave,
      saveToProvider  : handleSaveToProvider,
      getBreadcrumbs  : handleGetBreadcrumbs,
      getCheckoutUser : handleGetCheckoutUser,
      verifyConnection: handleVerifyConnection,
      sync            : handleSyncProject,
      setOperation    : handleSetOperation,
      clearOperation  : () => handleSetOperation(null),
    }),
    [
      project,
      breadcrumbs,
      hasConnection,
      hasDesktopScheme,
      isAutoSaving,
      isOwned,
      isReadOnly,
      canInteractWithProject,
      canInteractWithCloudProvider,
      canToggleAutoSave,
      canOpenInDesktop,
      canRenameProject,
      canMoveProject,
      canShareProject,
      handleMoveProject,
      handleRenameProject,
      handleDownloadProject,
      handleFlushProject,
      handleCloseProject,
      handleShareProject,
      handleCheckIn,
      handleCheckOut,
      handleOpenInDesktop,
      handleEnableAutoSave,
      handleDisableAutoSave,
      handleReopenProject,
      handleToggleAutoSave,
      handleSaveToProvider,
      handleGetBreadcrumbs,
      handleGetCheckoutUser,
      handleVerifyConnection,
      handleSyncProject,
      handleSetOperation,
    ],
  );
};
