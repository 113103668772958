import type { EnhancedStore, UnknownAction } from '@reduxjs/toolkit';
import type { EqualityFn, NoInfer, ReactReduxContextValue, TypedUseSelectorHook } from 'react-redux';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { cloneElement, createContext, createElement, useCallback } from 'react';
import { CloudStorage, CloudStorageApi } from '@mtb/cloud-storage';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import config from '../../config';
import MODULES, { FEATURE_FLAGGED_MODULES } from '../../modules';
import { requestIdleCallback } from '../utils/request-idle-callback';
import { actions, asyncActions, reducer } from './reducers';
import { createStore } from './redux-helpers/create-store';
import selectors from './selectors';

const platformStore = createStore(reducer, actions, selectors, asyncActions);

export type PlatformStoreType = typeof platformStore;

export type PlatformState = ReturnType<PlatformStoreType['getState']>;

export type PlatformDispatch = PlatformStoreType['dispatch'];

const PlatformContext = createContext<ReactReduxContextValue<unknown, UnknownAction> | null>(null);

const useStore = createStoreHook(PlatformContext);

const useDispatch: () => PlatformDispatch = createDispatchHook(PlatformContext);

const useSelectorBase: TypedUseSelectorHook<PlatformState> = createSelectorHook(PlatformContext);

const useSelector = <TSelected>(
  selector: (state: PlatformState) => TSelected,
  equalityFn?: EqualityFn<NoInfer<TSelected>>,
): TSelected => useSelectorBase(selector, equalityFn);

/**
 * The PlatformProvider component.
 * Wrap react-redux Provider with some initialization logic for overriding the default state
 */
const PlatformProvider: FunctionComponent<
  PropsWithChildren<React.ReactNode> & { loading: React.ReactElement }
> = ({ loading, children }) => {
  const handleOnBeforeLift = useCallback(async () => {
    await platformStore.actions.initializeUser();
    if (!platformStore.getState().user.claims.valid) {
      // authentication client is redirecting to identity provider
      // do not let the rest of the app initialize
      await new Promise(() => undefined);
    }
    if (config.feature_flag_cs_store_v2) {
      CloudStorageApi.initialize(config);
    } else {
      CloudStorage.initialize(config);
    }
    platformStore.actions.initializePlanes();
    requestIdleCallback(() => FEATURE_FLAGGED_MODULES?.DISCOVER && MODULES.DISCOVER.preloadRemoteModule?.());
    requestIdleCallback(
      () => FEATURE_FLAGGED_MODULES?.LEARNING_CENTER && MODULES.LEARNING_CENTER.preloadRemoteModule?.(),
    );
  }, []);

  return createElement(Provider, {
    context : PlatformContext,
    store   : platformStore as unknown as EnhancedStore<PlatformState>,
    children: [
      ...(loading ? [cloneElement(loading, { key: 'loading' })] : []),
      createElement(PersistGate, {
        key         : 'gate',
        // TODO: We should be able to handle this in tests better.
        onBeforeLift: process.env.NODE_ENV !== 'test' ? handleOnBeforeLift : undefined,
        persistor   : platformStore.persistor,
        children,
      }),
    ],
  });
};

export { PlatformProvider, platformStore, useDispatch, useSelector, useStore };

export default platformStore;
