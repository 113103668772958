import type { ModuleConfig } from '../types';
import { RemoteDashboard, loadRemoteDashboard } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'DASHBOARD',
  title    : 'minitabDashboards',
  icon     : 'DashboardsIcon',
  featureId: '7a218321eda941a4bc9590c088746be0',
  card     : {
    title         : 'minitabDashboards',
    description   : 'minitabDashboardsDescription',
    tagline       : 'dashboard',
    icon          : 'DashboardsIcon',
    heroIcon      : 'DashboardHeroIcon',
    fillColor     : '#C7A3BF',
    hoverFillColor: '#83286F',
  },
  preloadRemoteModule: loadRemoteDashboard,
  remoteModule       : RemoteDashboard,
  storage            : {
    defaultProjectName: 'minitabDashboardsDefaultProject',
    defaultExtension  : '.mdbx',
    defaultPlaneState : { isDirty: false },
    filter            : ['.mdbx'],
    passthroughFilter : ['.mtw', '.mwx'],
  },
};

export default CONFIG;
