import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import createIntegratedRemoteModuleLoader from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteBrainstorm = createIntegratedRemoteModuleLoader(
  'mtb_workspace',
  'BRAINSTORM',
  remoteModuleUrlConfigs.BRAINSTORM,
);

const LazyRemoteBrainstorm = lazy(loadRemoteBrainstorm);

const RemoteBrainstorm = withErrorBoundary(
  withSuspense(LazyRemoteBrainstorm, () => createElement(CenteredCircularProgress)),
  'RemoteBrainstorm',
);

export { loadRemoteBrainstorm, RemoteBrainstorm };

