
import type { ModuleConfig, ModuleConfigKey } from '../../../types';
import ModuleClient from '../../../clients/module';
import MODULES from '../../../modules';
import platformStore from '../../store';

/**
 * ModuleService is the wrapper around ModuleClient that serves as the orchestrator for managing
 * and interacting with all the configured remote modules within Platform. It acts as a central
 * interface for interacting with the remote ModuleClient.
 */
class ModuleService {
  /**
   * Gets the root module configuration.
   * @returns The root module configuration.
   */
  get ROOT(): ModuleConfig {
    return ModuleClient.ROOT;
  }

  /**
   * Gets the platform module configuration.
   * @returns The platform module configuration.
   */
  get PLATFORM(): ModuleConfig {
    return ModuleClient.PLATFORM;
  }

  /**
   * Gets the MSSO module configuration.
   * @returns The MSSO module configuration.
   */
  get MSSO(): ModuleConfig {
    return ModuleClient.MSSO;
  }

  /**
   * Gets the BRAINSTORM module configuration.
   * @returns The BRAINSTORM module configuration.
   */
  get BRAINSTORM(): ModuleConfig {
    return ModuleClient.BRAINSTORM;
  }

  /**
   * Gets the WSO module configuration.
   * @returns The WSO module configuration.
   */
  get WSO(): ModuleConfig {
    return ModuleClient.WSO;
  }

  /**
   * Gets the DATACENTER module configuration.
   * @returns The DATACENTER module configuration.
   */
  get DATACENTER(): ModuleConfig {
    return ModuleClient.DATACENTER;
  }

  /**
   * Gets the DASHBOARD module configuration.
   * @returns The DASHBOARD module configuration.
   */
  get DASHBOARD(): ModuleConfig {
    return ModuleClient.DASHBOARD;
  }

  /**
   * Gets the DISCOVER module configuration.
   * @returns The DISCOVER module configuration.
   */
  get DISCOVER(): ModuleConfig {
    return ModuleClient.DISCOVER;
  }

  /**
   * Gets the LEARNING_CENTER module configuration.
   * @returns The LEARNING_CENTER module configuration.
   */
  get LEARNING_CENTER(): ModuleConfig {
    return ModuleClient.LEARNING_CENTER;
  }

  constructor() {
    // Expose the modules for testing purposes on the client side
    // to make setup and mocking easier.
    if (process.env.NODE_ENV === 'test') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line - Ignore indexing error.
      this.__MODULES__ = MODULES;
    }
  }

  /**
   * Checks if the user has access to the given module configuration.
   * @param moduleConfig
   * @returns True if the user has access to the given module configuration, false otherwise.
   */
  hasModuleAccess(moduleConfig: ModuleConfig): boolean {
    const user = platformStore.selectors.user(platformStore.getState());
    return ModuleClient.hasModuleAccess(moduleConfig, user);
  }

  /**
   * Gets the module configuration available in the Platform based on feature flags.
   * @param moduleConfigs - The module configurations to filter.
   * @returns The module configurations available in the Platform based on feature flags.
   */
  getEnabledModules(): ModuleConfig[] {
    const user = platformStore.selectors.user(platformStore.getState());
    return ModuleClient.getEnabledModules(user);
  }

  /**
   * Gets the module configuration for the given module key.
   * @param moduleConfigKey - The key of the module configuration.
   * @returns The module configuration for the given module key.
   */
  getEnabledModule(moduleConfigKey: ModuleConfigKey): ModuleConfig | undefined {
    const user = platformStore.selectors.user(platformStore.getState());
    return ModuleClient.getEnabledModule(moduleConfigKey, user);
  }

  /**
   * Gets the default module configuration based on the given extension.
   * @param extension - The extension to get the default module for.
   * @returns The default module configuration based on the given extension.
   */
  getDefaultModuleByExtension(extension: string): ModuleConfig | undefined {
    const user = platformStore.selectors.user(platformStore.getState());
    return ModuleClient.getDefaultModuleByExtension(extension, user);
  }

  /**
   * Gets the default module configuration based on the given extension.
   * @param extension - The extension to get the default module for.
   * @returns The default module configuration based on the given extension.
   */
  getModulesByExtension(extension: string): ModuleConfig[] {
    const user = platformStore.selectors.user(platformStore.getState());
    return ModuleClient.getModulesByExtension(extension, user);
  }
}

export default ModuleService;
