import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  Spacer,
  Stack,
  Surface,
  Tooltip,
  Typography,
  styled,
} from '@mtb/ui';
import {
  CLOUD_EXPLORER_COLUMNS,
  CloudExplorer,
  CloudExplorerTable,
  CloudExplorerTableBody,
  CloudExplorerTableContainer,
} from '../../components/CloudExplorer';
import {
  CHANGE_LOCATION_FILTER,
  CLOUD_STORAGE_CATEGORIES,
} from '../../constants';
import { useTranslation } from '../../services/i18n';
import { getFolderName } from '../../utils';

const StyledNavButton = styled(Button)(() => ({
  opacity                          : 0,
  '.mtb-ui-table-row-hover:hover &': {
    opacity: 1,
  },
  '.mtb-ui-table-row-root.mtb-ui-focus-visible &': {
    opacity: 1,
  },
}));

const SAVE_TO_FOLDER_DIALOG_ID = 'save-to-folder-dialog';

export function NewSaveToDialog({
  provider,
  onClose,
  open,
}) {
  const [t] = useTranslation('connection');
  const breadcrumbActionsRef = useRef();
  const [breadcrumbFolder, setBreadcrumbFolder] = useState();
  const [selected, setSelected] = useState();

  const title = useMemo(
    () => getFolderName(provider, breadcrumbFolder),
    [breadcrumbFolder, provider],
  );

  const handleOnSelectionChange = useCallback(
    ({ selected }) => setSelected(selected),
    [],
  );

  const handleOnBreadcrumbChange = useCallback(
    ({ breadcrumbs }) => setBreadcrumbFolder(breadcrumbs[breadcrumbs.length - 1]),
    [],
  );

  const handleOnBackClick = useCallback(
    () => breadcrumbActionsRef.current.popBreadcrumb(),
    [breadcrumbActionsRef],
  );

  const handleOnClose = useCallback(
    (confirm) => async () => {
      if (!confirm) {
        return onClose(false);
      }

      onClose?.(selected ?? breadcrumbFolder ?? null);
    },
    [breadcrumbFolder, onClose, selected],
  );

  const handleOnClick = useCallback(
    (row) => () => {
      breadcrumbActionsRef.current.pushBreadcrumb(row);
      setBreadcrumbFolder(row);
    },
    [],
  );

  const OPEN_FOLDER_COLUMN = useMemo(() => ({
    id    : 'nav',
    render: (row) => (
      <Tooltip
        placement="bottom"
        title={t('connection.openFolder')}>
        <StyledNavButton
          icon={<KeyboardArrowRightIcon />}
          rounded
          size="x-small"
          sx={{ display: 'inline-flex' }}
          variant="highlight"
          onClick={handleOnClick(row)} />
      </Tooltip>
    ),
    disableSkeleton: true,
    width          : 80,
    align          : 'right',
  }), [handleOnClick, t]);

  return (
    <Dialog
      data-testid="save-to-dialog"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleOnClose(false)}>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          flex="1 1 auto"
          gap={1}
          height="100%">
          <Tooltip
            placement="bottom"
            title={t('connection.previousFolder')}>
            <Button
              color="default"
              disabled={!breadcrumbFolder}
              icon={<KeyboardArrowLeftIcon />}
              variant="text"
              onClick={handleOnBackClick} />
          </Tooltip>
          <Typography>{title}</Typography>
          <Spacer />
          <Tooltip
            placement="bottom"
            title={t('connection.close')}>
            <Button
              aria-label={t('connection.close')}
              color="default"
              icon={<CloseIcon />}
              variant="text"
              onClick={handleOnClose(false)} />
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          as={Surface}
          height={400}
          variant="outlined"
          width="100%">
          <CloudExplorer
            actions={breadcrumbActionsRef}
            defaultCategory={CLOUD_STORAGE_CATEGORIES.ALL}
            defaultFilter={CHANGE_LOCATION_FILTER}
            defaultProvider={provider}
            disableCache
            id={SAVE_TO_FOLDER_DIALOG_ID}>
            <CloudExplorerTableContainer onChange={handleOnBreadcrumbChange}>
              <CloudExplorerTable
                columns={[CLOUD_EXPLORER_COLUMNS.NAME, OPEN_FOLDER_COLUMN]}
                onChange={handleOnSelectionChange}>
                <CloudExplorerTableBody />
              </CloudExplorerTable>
            </CloudExplorerTableContainer>
          </CloudExplorer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose(true)}>{t('connection.select')}</Button>
      </DialogActions>
    </Dialog>
  );
}
