import { useMemo, useState, useCallback } from 'react';
import i18n from '../../../services/i18n';

/**
 * Sorts items by last modified date.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByLastModifiedDate = (items, asc) =>
  items
    .sort((a, b) =>
      // reversed since we want to sort by the delta of lastAccessed
      new Date(a.lastModifiedDateTime) < new Date(b.lastModifiedDateTime)
        ? asc
          ? 1
          : -1
        : asc
          ? -1
          : 1,
    )
    .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by last accessed date.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByLastAccessedDate = (items, asc) =>
  items
    .sort((a, b) =>
      // reversed since we want to sort by the delta of lastAccessed
      new Date(a.lastAccessedDateTime) < new Date(b.lastAccessedDateTime)
        ? asc
          ? 1
          : -1
        : asc
          ? -1
          : 1,
    )
    .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by name.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByName = (items, asc) =>
  (asc
    ? items.sort((a, b) =>
      a.name.localeCompare(b.name, i18n.language, { numeric: true }),
    )
    : items.sort(
      (a, b) =>
        -a.name.localeCompare(b.name, i18n.language, { numeric: true }),
    )
  ).sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by owner.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByOwner = (items, asc) =>
  asc
    ? items
      .sort((a, b) =>
        a.createdBy?.name.localeCompare(b.createdBy?.name, i18n.language, {
          numeric          : true,
          ignorePunctuation: true,
        }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1))
    : items
      .sort(
        (a, b) =>
          -a.createdBy?.name.localeCompare(b.createdBy?.name, i18n.language, {
            numeric          : true,
            ignorePunctuation: true,
          }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * @type {import('@').UseTableSort}
 */
export const useTableSort = (
  items,
  defaultSortBy = 'lastModified',
  defaultAscOrder = true,
) => {
  const [sort, setSort] = useState({
    sortBy  : defaultSortBy,
    ascOrder: defaultAscOrder,
  });

  /**
   * @type {import('@').StorageExplorerTableSetTableSort}
   */
  const handleSetTableSort = useCallback(
    ({ sortBy = 'lastModified', ascOrder = true } = {}) =>
      setSort({ sortBy, ascOrder }),
    [],
  );

  /**
   * Callback to toggle the sorting order.
   * @type {import('@').StorageExplorerTableToggleTableOrder}
   */
  const handleToggleTableOrder = useCallback(() => {
    setSort(({ sortBy, ascOrder }) => ({ sortBy, ascOrder: !ascOrder }));
  }, []);

  /**
   * Memoized sorted array of items based on the sorting configuration.
   * @type {import('@').StorageExplorerTableSortedRows}
   */
  const sortedRows = useMemo(() => {

    switch (sort.sortBy) {
      case 'lastModified':
        return sortByLastModifiedDate(items.slice(), sort.ascOrder);
      case 'lastAccessed':
        return sortByLastAccessedDate(items.slice(), sort.ascOrder);
      case 'owner':
        return sortByOwner(items.slice(), sort.ascOrder);
      default:
      case 'name':
        return sortByName(items.slice(), sort.ascOrder);
    }
  }, [items, sort.sortBy, sort.ascOrder]);

  return /** @type {import('@').StorageExplorerTableContext}*/ {
    rows            : sortedRows,
    ascOrder        : sort.ascOrder,
    sortBy          : sort.sortBy,
    toggleTableOrder: handleToggleTableOrder,
    setTableSort    : handleSetTableSort,
  };
};
