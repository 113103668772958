import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initializeUser } from './thunk';

export const initializeUserReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return (
    builder
      .addCase(initializeUser.pending, () => undefined)
      .addCase(initializeUser.fulfilled, () => undefined)
      .addCase(initializeUser.rejected, () => undefined)
  );
};
