import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CloseIcon,
  Button,
  Spacer,
  Tooltip,
} from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

export const FileSizeTooLargeDialog = ({ onClose, open }) => {
  const [t] = useTranslation('connection');

  const handleOnClose = useCallback(
    (confirm) => (event) => {
      event.stopPropagation();
      onClose(confirm);
    },
    [onClose],
  );

  return (
    <Dialog
      data-testid="file-size-too-large-dialog"
      open={open}
      onClose={handleOnClose(false)}>
      <DialogTitle>
        {t('connection.cannotOpenLocalFile')}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnClose(true)} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{t('connection.fileTooLargeMessage')}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleOnClose(true)}>
          {t('shared.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileSizeTooLargeDialog;
