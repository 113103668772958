import { CheckedOutOutlinedIcon, Skeleton, Stack, Typography } from '@mtb/ui';
import { Trans } from '../../services/i18n';

const ICON_SIZE = { height: 24, width: 24 };

/**
 * @param {Object} props - Component props.
 * @param {boolean} [props.loading] - Whether or not to show a skeleton frame.
 * @param {string} props.checkoutUserName - The username to display as the checkout user.
 * @returns {JSX.Element} - Returns a JSX element displaying the user that has currently checked the item out.
 */
export const CheckoutStatus = ({ loading = false, checkoutUserName }) => (
  <Stack
    align="center"
    direction="row"
    gap={0.75}>
    {loading ? (
      <>
        <Skeleton
          sx={ICON_SIZE}
          variant="circular" />
        <Skeleton
          sx={{ flexGrow: 1, flexShrink: 0 }}
          variant="text" />
      </>
    ) : (
      <>
        <CheckedOutOutlinedIcon sx={ICON_SIZE} />
        <Trans
          components={{
            bold: <Typography
              sx={{ display: 'inline' }}
              weight="bold" />,
            normal: <Typography sx={{ display: 'inline' }} />,
          }}
          i18nKey="connection.checkedOutItem"
          values={{ userName: checkoutUserName }} />
      </>
    )}
  </Stack>
);
