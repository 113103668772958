import PlatformModuleBase from './base';

/**
 * StaticPlatformModule is a singleton instance of the PlatformModuleBase class.
 * This `Platform` module subclass is provided to a remote module to offer base `Platform` functionality.
 * It supports "planeless" module instances that can interact with the `Platform` without including additional controls for managing a Plane.
 * This class serves as a centralized location to expose a public API for all "planeless" remote module interactions,
 * allowing control and management of the interactions we expose.
 */
export const staticPlatformModule = new PlatformModuleBase();
export type StaticPlatformModule = typeof staticPlatformModule;
export default staticPlatformModule;
