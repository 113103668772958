import type RemoteModuleConfig from '../../core/remote-module-config';
import type { ModuleConfigKey } from '../../modules/types';

/**
 * ModuleConfigClient manages remote module configurations configured during the `init` function provided
 * by the remote module. It stores the configurations in a map for later access and integration within Platform.
 */
class ModuleConfigClient {
  /**
   * The created module configurations.
   */
  #remoteModuleConfigs = new Map<ModuleConfigKey, RemoteModuleConfig>();

  /**
   * Sets the configured remote module configuration into the stored module configurations.
   * @param moduleConfig - The module configuration to set.
   */
  setRemoteModuleConfig(moduleConfig: RemoteModuleConfig) {
    this.#remoteModuleConfigs.set(moduleConfig.key, moduleConfig);
  }

  /**
   * Creates a remote module configuration for one of the known modules.
   * If the module has already been created, it will return the existing module configuration.
   * @param moduleConfigKey - The key of the module configuration to create.
   */
  getRemoteModuleConfig(moduleConfigKey: ModuleConfigKey): RemoteModuleConfig | undefined {
    const moduleConfig = this.#remoteModuleConfigs.get(moduleConfigKey);
    return moduleConfig;
  }

  /**
   * Clears all the remote module configs
   */
  clearRemoteModuleConfigs() {
    this.#remoteModuleConfigs.clear();
  }
}

const moduleConfigClient = new ModuleConfigClient();
export default moduleConfigClient;
