import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import { createStaticRemoteModuleLoader } from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteLearningCenter = createStaticRemoteModuleLoader(
  'mtb_platformContent',
  'LEARNING_CENTER',
  remoteModuleUrlConfigs.LEARNING_CENTER,
);

const LazyRemoteLearningCenter = lazy(loadRemoteLearningCenter);

const RemoteLearningCenter = withErrorBoundary(
  withSuspense(LazyRemoteLearningCenter, () => createElement(CenteredCircularProgress)),
  'RemoteLearningCenter',
);

export { loadRemoteLearningCenter, RemoteLearningCenter };

