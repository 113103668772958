import type { ModuleConfigKey } from '../../modules/types';
import RemoteModuleConfigClaims from './claims';
import RemoteModuleConfigEvents from './events';
import RemoteModuleConfigRehydrateState from './rehydrate-state';

/**
 * ModuleConfig serves as the primary interface that defines the different configurations for a registered module.
 * within platform core. This class is used internally when ModuleConfigManager creates a new module configuration.
 */
export class RemoteModuleConfig {
  #moduleKey: ModuleConfigKey;
  get key() {
    return this.#moduleKey;
  }

  #claims: RemoteModuleConfigClaims;
  get claims() {
    return this.#claims;
  }

  #events: RemoteModuleConfigEvents;
  get events() {
    return this.#events;
  }

  #rehydrateState: RemoteModuleConfigRehydrateState;
  get rehydrateState() {
    return this.#rehydrateState;
  }

  /**
   * Creates an instance of a ModuleConfig.
   * @param moduleKey - The moduleKey of the module.
   */
  constructor(moduleKey: ModuleConfigKey) {
    this.#moduleKey = moduleKey;
    this.#claims = new RemoteModuleConfigClaims();
    this.#events = new RemoteModuleConfigEvents();
    this.#rehydrateState = new RemoteModuleConfigRehydrateState();
  }
}

export default RemoteModuleConfig;
