import { useMemo } from 'react';
import { Stack } from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import { useStorageExplorer } from '../hooks';

export const StorageExplorerTableContainer = ({ children }) => {
  const { category } = useStorageExplorer();
  const showTable = useMemo(
    () => category !== CLOUD_STORAGE_CATEGORIES.NONE,
    [category],
  );

  if (!showTable) {
    return null;
  }

  return (
    <Stack
      gap={2}
      height="100%"
      width="100%">
      {children}
    </Stack>
  );
};
