import type { Plane, PlaneId, PlaneState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { deepMerge } from '@mtb/utilities';

export const updatePlane = {
  reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; plane: Partial<Plane> }>) => {
    if (!state[action.payload.planeId]) {
      return;
    }
    state[action.payload.planeId] = deepMerge(state[action.payload.planeId], action.payload.plane) as Plane;
  },
  prepare: (planeId: PlaneId, plane: Partial<Plane>) => {
    return { payload: { planeId, plane } };
  },
};
