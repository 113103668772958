import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { cleanupPlanes } from './thunk';

export const cleanupPlanesReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return (
    builder
      .addCase(cleanupPlanes.pending, () => undefined)
      .addCase(cleanupPlanes.fulfilled, () => undefined)
      .addCase(cleanupPlanes.rejected, () => undefined)
  );
};
