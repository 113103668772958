import type { StorageExplorerItem, StorageProviderItem } from '@';
import { useCallback, useMemo } from 'react';
import { TableRow } from '@mtb/ui';
import { ON_OPEN_REASONS } from '../../../../constants';
import { useCloudExplorerItem } from '../../../../hooks';
import { useTable } from '../../context';
import { CloudExplorerTableContextMenu } from './CloudExplorerTableContextMenu';
import { CloudExplorerTableRowCell } from './CloudExplorerTableRowCell';
import { CloudExplorerTableRowContext } from './context';

type CloudExplorerTableRowProps = {
  row: StorageExplorerItem | StorageProviderItem;
};
export const CloudExplorerTableRow = ({
  row: rowProp,
}: CloudExplorerTableRowProps) => {
  const { columns } = useTable();
  const item = useCloudExplorerItem(rowProp);

  const handleOnKeyDown: React.KeyboardEventHandler<HTMLTableRowElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        item.open(ON_OPEN_REASONS.ENTER_KEY);
      }
    },
    [item],
  );

  const handleOnDoubleClick = useCallback(
    () => item.open(ON_OPEN_REASONS.DOUBLE_CLICK),
    [item],
  );

  const handleOnMouseDown = useCallback(
    () => item.select(),
    [item],
  );

  const row = useMemo(
    () => ({
      type                   : item.type,
      id                     : item.id,
      driveId                : item.driveId,
      name                   : item.name,
      extension              : item.extension,
      icon                   : item.icon,
      folder                 : item.folder,
      createdBy              : item.createdBy,
      parentFolderUrl        : item.parentFolderUrl,
      lastModifiedElapsedTime: item.lastModifiedElapsedTime,
      lastModifiedDateTime   : item.lastModifiedDateTime,
      lastAccessedElapsedTime: item.lastAccessedElapsedTime,
      lastAccessedDateTime   : item.lastAccessedDateTime,
      checkoutUser           : item.checkoutUser,
    } as unknown as StorageExplorerItem),
    [item],
  );

  return (
    <CloudExplorerTableRowContext.Provider value={row}>
      <CloudExplorerTableContextMenu>
        <TableRow
          data-testid="cloud-explorer-table-row"
          hover
          selected={item?.isSelected}
          tabIndex={0}
          onDoubleClick={handleOnDoubleClick}
          onKeyDown={handleOnKeyDown}
          onMouseDown={handleOnMouseDown}>
          {columns.map((column) => (
            <CloudExplorerTableRowCell
              key={column.id}
              column={column}
              row={row} />
          ))}
        </TableRow>
      </CloudExplorerTableContextMenu>
    </CloudExplorerTableRowContext.Provider>
  );
};
