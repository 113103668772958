import type { ClaimsResponse, SubscriptionsResponse } from './types';
import type { ClaimsState, UserState, UserSubscription } from '../../core/store/reducers/user/types';
import type { ClaimsFeatures } from '../../types';
import type { RequestResponse } from '@mtb/utilities';
import { FetchWrapper as DefaultFetchWrapper } from '@mtb/utilities';
import LoggerClient from '../logger';

class AuthenticationClient {
  #logger = LoggerClient.createNamedLogger('AuthenticationClient');
  /**
   * The interval in milliseconds to send a heartbeat to the server.
   * (7.5 * 60 * 1000) = 7.5 minutes
   */
  #heartbeatInterval = 7.5 * 60 * 1000;
  #heartbeatTimer: number | undefined;

  /**
   * Wrapper around DefaultFetchWrapper to reuse the same options for all requests.
   */
  #request(options: { resource: string; params?: Record<string, unknown>; fetchOptions?: Record<string, unknown> }) {
    return DefaultFetchWrapper.request(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Ignore until the types from @mtb/utilities are updated.
      {
        verb        : 'GET',
        type        : 'json',
        fetchOptions: {
          credentials: 'include',
          ...options.fetchOptions,
        },
        ...options,
      },
      false,
    );
  }

  async fetchSeatStatus({
    identifyingParty,
    subscriptionId,
    productId,
    seatId,
  }: {
    identifyingParty: string;
    subscriptionId: string;
    productId: string;
    seatId: string;
  }) {
    try {
      if (window.location.hostname === 'localhost' || window.location.hostname === 'online-local.minitab.com') {
        return;
      }

      const response = await this.#request({
        resource: `${identifyingParty}/api/v1/seats/status`,
        params  : {
          subscriptionId,
          productId,
          seatId,
        },
      });

      if (!response.ok) {
        throw new Error('Invalid seat status');
      }
    } catch (error) {
      this.#logger.error('Failed to fetch seat status', error);
      throw error;
    }
  }

  /**
   * Fetches the user's claims from the LP.
   * @returns
   */
  async fetchClaims(): Promise<ClaimsResponse> {
    try {
      const response = await this.#request({
        resource: '/auth/claims',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch claims');
      }

      return response.data as ClaimsResponse;
    } catch (error) {
      const response = error as RequestResponse;
      this.#logger.error(`${response?.status}: ${response?.statusText} `, error as unknown);
      throw error;
    }
  }

  async fetchSubscriptions({ identifyingParty }: { identifyingParty: string }) {
    try {
      const response = await this.#request({
        resource: `${identifyingParty}/api/v1/user/subscriptions`,
      });

      if (!response.ok) {
        return [];
      }

      const subscriptions = response.data as SubscriptionsResponse[];
      return subscriptions.map(subscription => ({
        productId : subscription.ProdId,
        productUrl: subscription.ProdUrl,
      }));
    } catch (error) {
      this.#logger.error('Failed to fetch subscriptions', error);
      return [];
    }
  }

  addHeartbeatListener(listener: () => void) {
    if (this.#heartbeatTimer) {
      window.clearInterval(this.#heartbeatTimer);
    }
    this.#heartbeatTimer = window.setInterval(listener, this.#heartbeatInterval);
  }

  removeHeartbeatListener() {
    if (this.#heartbeatTimer) {
      window.clearInterval(this.#heartbeatTimer);
    }
  }

  login() {
    window.location.replace(`/auth/login?return=${encodeURIComponent(window.location.href)}`);
  }

  logout() {
    this.removeHeartbeatListener();
    window.location.replace('/auth/logout');
  }

  /**
   * Checks if the user has a subscription to the given productId.
   * If no productId is provided, assumes the user has a subscription.
   * @param productId - The productId of the module.
   * @returns True if the user has a subscription to the module, false otherwise.
   */
  hasSubscription = (subscriptions: UserSubscription[], productId?: string) =>
    subscriptions.some((subscription: UserSubscription) => subscription.productId === productId);

  /**
   * Checks if the user has a feature claim.
   * @param claimsFeatures The user's claims.
   * @param featureId The featureId to check for.
   * @returns True if the user has the feature claim, false otherwise.
   */
  hasClaimsFeature = (claimsFeatures: ClaimsFeatures, featureId?: string) =>
    claimsFeatures.some((claimsFeatureId: string) => claimsFeatureId === featureId);

  /**
   * Checks if the user is a high school user.
   * @param user - The user state to check.
   * @returns True if the user is a high school user, false otherwise.
   */
  isHighSchoolUser(user: UserState) {
    return this.hasClaimsFeature(user.claims.features, 'highschool');
  }

  /**
   * Opens a new window to the account page.
   * @param claims The user's claims.
   */
  manageAccount(claims: ClaimsState) {
    if (!claims) {
      return;
    }

    const openWindow = window.open(`${claims.identifyingparty}/account`, '_blank');
    if (openWindow) {
      openWindow.focus();
    }
  }
}

export const authenticationClient = new AuthenticationClient();
export default authenticationClient;
