import { useContext } from 'react';
import { CloudExplorerTableRowContext } from './context';

export const useTableRow = () => {
  const context = useContext(CloudExplorerTableRowContext);
  if (!context) {
    throw new Error('useTableRow must be used within a CloudExplorerTableRow');
  }
  return context;
};
