import { useMemo } from 'react';
import { useMenu } from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import {
  useNewCloudStorageProject,
  useNewCloudStorageStore,
  useProvider,
} from '../../hooks';
import { StorageProjectMenuContext, initialState } from './context';

/**
 * @param {import('@').StorageProjectMenuContext & { children?: import('react').ReactNode }} props
 */
export const StorageProjectMenu = ({
  projectId,
  onFlush,
  onReopenProject,
  children,
}) => {
  const menuProps = useMenu({ id: 'storage-project' });
  const { defaultProvider } = useNewCloudStorageStore();
  const project = useNewCloudStorageProject(projectId);
  const oneDrive = useProvider(STORAGE_PROVIDER_KEYS.ONE_DRIVE);
  const googleDrive = useProvider(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE);
  const hasAuthedProvider = useMemo(
    () => oneDrive.isSignedIn || googleDrive.isSignedIn,
    [googleDrive.isSignedIn, oneDrive.isSignedIn],
  );

  const values = useMemo(() => {
    return {
      ...initialState,
      hasAuthedProvider,
      defaultProvider,
      oneDrive,
      googleDrive,
      project,
      menuProps,
      onFlush,
      onReopenProject,
    };
  }, [
    hasAuthedProvider,
    defaultProvider,
    oneDrive,
    googleDrive,
    project,
    menuProps,
    onFlush,
    onReopenProject,
  ]);

  return (
    <StorageProjectMenuContext.Provider value={values}>
      {children}
    </StorageProjectMenuContext.Provider>
  );
};

export default StorageProjectMenu;
