import React, { useCallback } from 'react';
import { Link, Stack } from '@mtb/ui';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { Trans } from '../../services/i18n';
import { getFolderName } from '../../utils';

export const SaveToButton = () => {
  const { type, autoSaveFolder, saveTo, setAutoSaveFolder, getAutoSaveFolder } =
    useCloudStorageStore();

  const handleOnClick = useCallback(async () => {
    const saveToFolder = await saveTo();
    if (!saveToFolder) {
      return;
    }

    if (saveToFolder?.id === 'root') {
      const root = getAutoSaveFolder(true);
      setAutoSaveFolder({ ...root, type });
    }

    if (saveToFolder?.folder && saveToFolder?.id !== autoSaveFolder?.id) {
      setAutoSaveFolder({ ...saveToFolder, type });
    }
  }, [autoSaveFolder?.id, getAutoSaveFolder, saveTo, setAutoSaveFolder, type]);

  return (
    <Stack
      data-testid="save-to-root"
      direction="row"
      gap={1}
      mb={2}>
      <Trans
        components={[
          <Link
            data-testid="save-to-link"
            onClick={handleOnClick} />,
        ]}
        i18nKey={'connection.projectsSavedTo'}
        values={{ projectLink: getFolderName(type, autoSaveFolder) }} />
    </Stack>
  );
};
