import { useMemo } from 'react';
import {
  ArrowDropDownIcon,
  Button,
  CheckCircleOutlineIcon,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  VisibilityIcon,
  WarningOutlinedIcon,
  useIsSmallScreen,
} from '@mtb/ui';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  CONNECTION_STATUS,
} from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

const STATUSES = {
  SAVED: {
    label: CONNECTION_STATUS.SAVED,
    icon : (
      <CheckCircleOutlineIcon
        color="success"
        data-testid="storage-project-menu-button-saved-icon" />
    ),
  },
  NOT_SAVED: {
    label: CONNECTION_STATUS.NOT_SAVED,
    icon : (
      <WarningOutlinedIcon
        color="warning"
        data-testid="storage-project-menu-button-warning-icon" />
    ),
  },
  READONLY: {
    label: CONNECTION_STATUS.READONLY,
    icon : (
      <VisibilityIcon
        color="error"
        data-testid="storage-project-menu-button-readonly-icon" />
    ),
  },
};

const getStatus = (status) => {
  return {
    label: status,
    icon : (
      <CircularProgress
        data-testid="storage-project-menu-button-progress-icon"
        size={16}
        thickness={4} />
    ),
  };
};

export function StorageProjectMenuButton({ ...other }) {
  const [t] = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const { project, menuProps } = useStorageProjectMenu();

  const menuStatus = useMemo(() => {
    if (project.operation) {
      return getStatus(project.operation);
    } else if (project.cloudStatus === CLOUD_STATUS.READONLY) {
      return STATUSES.READONLY;
    } else if (project.autoSaveStatus === AUTO_SAVE_STATUS.STARTED) {
      return STATUSES.SAVED;
    }
    return STATUSES.NOT_SAVED;
  }, [project.autoSaveStatus, project.cloudStatus, project.operation]);

  return (
    <Button
      color="default"
      data-testid="storage-project-menu-button"
      sx={{ '& svg': { marginRight: 0 } }}
      variant="text"
      {...menuProps.buttonProps}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        gap={1}>
        {!isSmallScreen && (
          <>
            <Tooltip title={project.displayName ?? ''}>
              <Typography
                data-testid="storage-project-menu-button-project-name"
                maxWidth={150}
                noWrap>
                {project.displayName ?? ''}
              </Typography>
            </Tooltip>
            <Typography>-</Typography>{' '}
          </>
        )}
        <Stack
          alignItems="center"
          direction="row"
          gap={1}>
          {menuStatus.icon}
          <Typography>{t(`connection.${menuStatus.label}`)}</Typography>
          <ArrowDropDownIcon />
        </Stack>
      </Stack>
    </Button>
  );
}
