import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { closePlane } from './thunk';

export const closePlaneReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return builder
    .addCase(closePlane.pending, (state, action) => {
      const id = action.meta.arg || '';
      if (id && state[id]) {
        state[id].requestId = action.meta.requestId;
      }
    })
    .addCase(closePlane.fulfilled, (state, action) => {
      const id = action.meta.arg || '';
      if (id && state[id]) {
        delete state[id];
      }
    })
    .addCase(closePlane.rejected, (state, action) => {
      const id = action.meta.arg || '';
      if (id && state[id]) {
        delete state[id];
      }
    });
};
