import type { PlaneError } from '../error';
import type { PlaneError as PlaneErrorType, PlaneId, PlaneState, PlaneStatusTypes } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PLANE_STATUS_TYPES } from '../constants';

export const setPlaneError = {
  reducer: (
    state: PlaneState,
    action: PayloadAction<{ planeId: PlaneId; status: PlaneStatusTypes; error: PlaneErrorType }>,
  ) => {
    if (state[action.payload.planeId]) {
      state[action.payload.planeId].status = action.payload.status;
      state[action.payload.planeId].error = action.payload.error;
    }
  },
  prepare: (planeId: PlaneId, error: PlaneError) => {
    return {
      payload: {
        planeId,
        status: PLANE_STATUS_TYPES.ERROR,
        error : error.serialize(),
      },
    };
  },
};
