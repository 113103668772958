import type {
  StorageProviderAutoSaveFolder,
  StorageProviderKey,
} from '../../types';
import { useCallback, useMemo } from 'react';
import ProviderService from '../services/providers';
import { useNewCloudStorageStore } from './useCloudStorageStore';

export function useProvider(key: StorageProviderKey) {
  const { defaultProvider, providers } = useNewCloudStorageStore();
  const isSignedIn = useMemo(
    () => Boolean(providers[key]?.tokens),
    [key, providers],
  );
  const account = useMemo(() => providers[key]?.account, [providers, key]);
  const defaultSaveFolder = useMemo(
    () => providers[key]?.defaultSaveFolder,
    [providers, key],
  );
  const isDefault = useMemo(
    () => defaultProvider === key,
    [defaultProvider, key],
  );

  const handleToggleAuth = useCallback(() => {
    isSignedIn ? ProviderService.signOut(key) : ProviderService.signIn(key);
  }, [isSignedIn, key]);

  const handleToggleDefault = useCallback(() => {
    ProviderService.toggleDefaultProvider(key);
  }, [key]);

  const handleSetDefaultSaveFolder = useCallback(
    (folder: StorageProviderAutoSaveFolder | null) => {
      ProviderService.setDefaultSaveFolder(key, folder);
    },
    [key],
  );

  return {
    account,
    defaultSaveFolder,
    isDefault,
    isSignedIn,
    toggleAuth          : handleToggleAuth,
    toggleDefault       : handleToggleDefault,
    setDefaultSaveFolder: handleSetDefaultSaveFolder,
  };
}
