import type { CloudStorageCategoryKey, StorageProviderKey } from '@';
import type { PropsWithChildren } from 'react';
import { Children, useCallback, useMemo } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mtb/ui';
import { useCloudExplorer } from '../../hooks';

type ProviderCategoryButtonProps = PropsWithChildren<{
  title: string;
  category: CloudStorageCategoryKey;
  provider: StorageProviderKey;
}>;
export const ProviderCategoryButton = (
  {
    children,
    title,
    category,
    provider,
  }: ProviderCategoryButtonProps,
) => {
  const child = Children.only(children);
  const {
    provider: explorerProvider,
    setProvider,
    category: explorerCategory,
    setCategory,
  } = useCloudExplorer();

  const isSelected = useMemo(
    () => category === explorerCategory && provider === explorerProvider,
    [category, explorerCategory, provider, explorerProvider],
  );

  const handleSelectCategory = useCallback(
    () => {
      setProvider(provider);
      setCategory(category);
    },
    [category, setCategory, provider, setProvider],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <ListItemButton
      selected={isSelected}
      onClick={handleSelectCategory}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <ListItemIcon>{child}</ListItemIcon>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
