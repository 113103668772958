import React, {
  useState,
  createElement,
  useMemo,
  useCallback,
  cloneElement,
} from 'react';
import {
  MenuItem,
  ListItemText,
  Menu,
  Button,
  Stack,
  useEventCallback,
} from '@mtb/ui';
import { useStorageExplorer } from '../../components';
import { STORAGE_PROVIDERS, STORAGE_PROVIDER_KEYS } from '../../constants';
import {
  useCloudStorageStore,
  useAllCloudStorageItems,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
} from '../../hooks';
import { useTranslation } from '../../services/i18n';

export const ChangeStorageProviderMenu = ({ children, onClick, ...other }) => {
  const [t] = useTranslation('connection');
  const cloudStorage = useCloudStorageStore();
  const storageExplorer = useStorageExplorer();
  const [anchorEl, setAnchorEl] = useState(null);
  const allItems = useAllCloudStorageItems();
  const recentItems = useRecentCloudStorageItems();
  const sharedItems = useSharedCloudStorageItems();
  const handleOnClick = useEventCallback((e) => setAnchorEl(e.currentTarget));
  const handleOnClose = useEventCallback(() => setAnchorEl(null));
  const handleOnProviderClick = useCallback(
    (provider) => () => {
      allItems.clear();
      recentItems.clear();
      sharedItems.clear();
      cloudStorage.changeProvider(provider);
      onClick?.(provider);
      handleOnClose();
    },
    [allItems, cloudStorage, handleOnClose, onClick, recentItems, sharedItems],
  );

  const providerOptions = useMemo(
    () =>
      (storageExplorer?.providers)
        .filter((provider) => provider !== STORAGE_PROVIDER_KEYS.LOCAL)
        .map((provider) => ({
          ...STORAGE_PROVIDERS[provider],
          selected: provider === cloudStorage.type,
          onClick : handleOnProviderClick(provider),
        })),
    [cloudStorage.type, handleOnProviderClick, storageExplorer?.providers],
  );

  return (
    <>
      {!children && (
        <Button
          data-testid="change-storage-provider-button"
          variant="highlight"
          onClick={handleOnClick}
          {...other}>
          {children || t('connection.change')}
        </Button>
      )}
      {children &&
        cloneElement(children, {
          onClick      : handleOnClick,
          'data-testid': 'change-storage-provider-button',
        })}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        data-testid="change-storage-provider-menu"
        open={Boolean(anchorEl)}
        onClose={handleOnClose}>
        {providerOptions.map((provider) => (
          <MenuItem
            key={provider.key}
            onClick={provider.onClick}>
            <Stack
              alignItems="Center"
              direction="row"
              gap={1}>
              {createElement(provider.icon)}
              <ListItemText primary={t(provider.name)} />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
