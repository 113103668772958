import { useCallback } from 'react';
import {
  Button,
  GoogleDriveIcon,
  ListItemText,
  Menu,
  MenuItem,
  MicrosoftOneDriveIcon,
  Stack,
  useMenu,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export const StorageProjectMenuSignInButton = () => {
  const [t] = useTranslation('connection');
  const { googleDrive, oneDrive } = useStorageProjectMenu();
  const { buttonProps, menuProps } = useMenu({
    id: 'sign-in-storage-provider',
  });

  const handleProviderClick = useCallback(
    (providerKey) => {
      menuProps.onClose();
      switch (providerKey) {
        case STORAGE_PROVIDER_KEYS.ONE_DRIVE:
          oneDrive.toggleAuth();
          break;
        case STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE:
          googleDrive.toggleAuth();
          break;
        default:
          break;
      }
    },
    [googleDrive, menuProps, oneDrive],
  );

  return (
    <>
      <Button
        variant="contained"
        {...buttonProps}>
        {t('connection.signInToRepository')}
      </Button>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => handleProviderClick(STORAGE_PROVIDER_KEYS.ONE_DRIVE)}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <MicrosoftOneDriveIcon />
            <ListItemText primary={t('connection.oneDrive')} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleProviderClick(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE)
          }>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <GoogleDriveIcon />
            <ListItemText primary={t('connection.googleDrive')} />
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
