import { useCallback } from 'react';
import {
  Alert,
  AlertActions,
  AlertContent,
  AlertIcon,
  Button,
  Stack,
} from '@mtb/ui';
import { useCloudStorageStore } from '../../../hooks/useCloudStorageStore';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

const StorageExplorerPickerButton = () => {
  const [t] = useTranslation();
  const store = useCloudStorageStore();
  const { refresh } = useStorageExplorer();

  const openPicker = useCallback(async () => {
    const didPickItems = await store.openPicker();
    if (didPickItems) {
      await refresh();
    }
  }, [refresh, store]);

  return <Button onClick={openPicker}>{t('picker.grantAccess')}</Button>;
};

export const StorageExplorerPickerAlert = ({ direction = 'row' }) => {
  const [t] = useTranslation();
  const store = useCloudStorageStore();

  if (!store.supportsPicker()) {
    return null;
  }

  return (
    <Alert
      severity="info"
      sx={{ width: '100%', flexShrink: 0 }}>
      <AlertIcon />
      <Stack
        direction={direction}
        gap={1}
        sx={{ width: '100%' }}>
        <AlertContent>
          {t('picker.whitelistFiles')}
        </AlertContent>
        <AlertActions>
          <StorageExplorerPickerButton />
        </AlertActions>
      </Stack>
    </Alert>
  );
};
