import type { OnOpenReason } from './constants';
import type { CloudStorageCategoryKey, StorageProviderItem } from '@';
import type React from 'react';
import { createContext } from 'react';

// The Cloud Explorer does not use the type 'local' as a value in the context state
export type NewStorageProviderKey = 'gdrive' | 'msgraph'
export type CloudExplorerContextType = {
  id: string;
  onOpen: (item: StorageProviderItem, reason?: OnOpenReason) => Promise<void>;
  onError: (error?: unknown) => void;
  renderOpenInMenuItems: JSX.Element[];
  category: CloudStorageCategoryKey;
  setCategory: React.Dispatch<CloudStorageCategoryKey>;
  provider: NewStorageProviderKey;
  setProvider: React.Dispatch<NewStorageProviderKey>;
  isProviderSignedIn: boolean;
  defaultFilter: string[];
  selectedQueriedItemsActions?: React.MutableRefObject<{ refetchItems: () => void } | undefined>;
  actions?: React.MutableRefObject<unknown>;
  disableCache: boolean;
};

export const initialState: CloudExplorerContextType = {
  id                         : '',
  onError                    : () => undefined,
  onOpen                     : () => Promise.resolve(),
  renderOpenInMenuItems      : [],
  category                   : '' as CloudStorageCategoryKey,
  setCategory                : () => undefined,
  provider                   : '' as NewStorageProviderKey,
  setProvider                : () => undefined,
  isProviderSignedIn         : false,
  defaultFilter              : [],
  selectedQueriedItemsActions: undefined,
  actions                    : undefined,
  disableCache               : false,
};

export const CloudExplorerContext = createContext(initialState);
