import type { StorageProviderItem } from '@';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { usePrevious } from '@mtb/ui';
import i18n from '../../../services/i18n';
import { useCloudExplorer } from './useCloudExplorer';

/**
 * Sorts items by last modified date.
 */
const sortByLastModifiedDate = (items: StorageProviderItem[], asc: boolean) =>
  items
    .sort((a, b) =>
      // reversed since we want to sort by the delta of lastAccessed
      new Date(a.lastModifiedDateTime) < new Date(b.lastModifiedDateTime)
        ? asc
          ? 1
          : -1
        : asc
          ? -1
          : 1,
    )
    .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by last accessed date.
 */
const sortByLastAccessedDate = (items: StorageProviderItem[], asc: boolean) =>
  items
    .sort((a, b) =>
      // reversed since we want to sort by the delta of lastAccessed
      new Date(a.lastAccessedDateTime) < new Date(b.lastAccessedDateTime)
        ? asc
          ? 1
          : -1
        : asc
          ? -1
          : 1,
    )
    .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by name.
 */
const sortByName = (items: StorageProviderItem[], asc: boolean) =>
  (asc
    ? items.sort((a, b) =>
      a.name.localeCompare(b.name, i18n.language, { numeric: true }),
    )
    : items.sort(
      (a, b) =>
        -a.name.localeCompare(b.name, i18n.language, { numeric: true }),
    )
  ).sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by owner.
 */
const sortByOwner = (items: StorageProviderItem[], asc: boolean) =>
  asc
    ? items
      .sort((a, b) =>
        (a.createdBy?.name ?? '').localeCompare(b.createdBy?.name ?? '', i18n.language, {
          numeric          : true,
          ignorePunctuation: true,
        }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1))
    : items
      .sort(
        (a, b) =>
          -(a.createdBy?.name ?? '').localeCompare(b.createdBy?.name ?? '', i18n.language, {
            numeric          : true,
            ignorePunctuation: true,
          }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

export const useTableSort = (
  items: StorageProviderItem[],
  defaultSortBy = 'name',
  defaultAscOrder = true,
) => {
  const { category } = useCloudExplorer();
  const [sort, setSort] = useState({
    sortBy  : defaultSortBy,
    ascOrder: defaultAscOrder,
  });

  const handleSetTableSort = useCallback(
    ({ sortBy = 'name', ascOrder = true } = {}) =>
      setSort({ sortBy, ascOrder }),
    [],
  );

  const handleToggleTableOrder = useCallback(() => {
    setSort(({ sortBy, ascOrder }) => ({ sortBy, ascOrder: !ascOrder }));
  }, []);

  const sortedRows = useMemo(() => {
    switch (sort.sortBy) {
      case 'lastModified':
        return sortByLastModifiedDate(items.slice(), sort.ascOrder);
      case 'lastAccessed':
        return sortByLastAccessedDate(items.slice(), sort.ascOrder);
      case 'owner':
        return sortByOwner(items.slice(), sort.ascOrder);
      default:
      case 'name':
        return sortByName(items.slice(), sort.ascOrder);
    }
  }, [items, sort.sortBy, sort.ascOrder]);

  const previousCategory = usePrevious(category);
  useLayoutEffect(() => {
    if (previousCategory !== category && category === 'recent') {
      handleSetTableSort({ sortBy: 'lastAccessed', ascOrder: defaultAscOrder });
    }
    if (previousCategory !== category && (category === 'all' || category === 'shared')) {
      handleSetTableSort({ sortBy: defaultSortBy, ascOrder: defaultAscOrder });
    }
  }, [category, previousCategory, defaultAscOrder, defaultSortBy, handleSetTableSort]);

  return {
    rows            : sortedRows,
    ascOrder        : sort.ascOrder,
    sortBy          : sort.sortBy,
    toggleTableOrder: handleToggleTableOrder,
    setTableSort    : handleSetTableSort,
  };
};
