import type { StoreProviderAccount } from '../../../../store/providers/types.ts';
import {
  Avatar,
  Button,
  FormControl,
  LoginIcon,
  LogoutIcon,
  Spacer,
  Stack,
  Typography,
} from '@mtb/ui';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { useCloudExplorer } from '../../hooks';

const NotSignedIn = () => {
  const [t] = useTranslation();
  return (
    <Typography
      variant="title-sm"
      weight="medium">
      {t('connection.notSignedIn')}
    </Typography>
  );
};

type ProviderAccountProps = { account: StoreProviderAccount }
const ProviderAccount = ({ account }: ProviderAccountProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={1}
      mr={1}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <Avatar
        alt={account?.name}
        data-testid="provider-account-avatar"
        src={account?.avatar} />
      <Stack>
        <Typography
          data-testid="provider-account-name"
          variant="title-sm">
          {account?.name}
        </Typography>
        <Typography data-testid="provider-account-email">
          {account?.email}
        </Typography>
      </Stack>
    </Stack>
  );
};

type ProviderAccountSettingsProps = { disabled?: boolean };
export const ProviderAccountSettings = ({ disabled = false }: ProviderAccountSettingsProps) => {
  const [t] = useTranslation();
  const { isProviderSignedIn, provider } = useCloudExplorer();
  const { account, toggleAuth } = useProvider(provider);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <FormControl as="fieldset">
      <Typography
        as="legend"
        variant="title-md"
        weight="semibold">
        {t('connection.account')}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flex="1 0 auto"
        flexWrap="wrap"
        gap={1}
        p={2}>
        {isProviderSignedIn ? <ProviderAccount account={account} /> : <NotSignedIn />}
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <Spacer />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture, theme, ownerState */}
        <Button
          color="default"
          disabled={disabled && isProviderSignedIn}
          variant="contained"
          onClick={toggleAuth}>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
          {isProviderSignedIn ? <LogoutIcon /> : <LoginIcon />}
          {t(isProviderSignedIn ? 'connection.signOut' : 'connection.signIn2')}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default ProviderAccountSettings;
