import type { UserSubscription } from '../../reducers/user/types';
import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
  user  : createSelector([state => state.user], user => ({ ...user })),
  claims: createSelector([state => state.user.claims], claims => ({
    id         : claims.id,
    name       : claims.name,
    firstname  : claims.firstname,
    lastname   : claims.lastname,
    email      : claims.email,
    roles      : claims.roles,
    features   : claims.features,
    valid      : claims.valid,
    appSettings: claims.appSettings,
  })),
  // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
  claimsFeatures: createSelector([state => state.user.claims.features], features => [...(features || [])]),
  settings      : createSelector(
    [state => state.user.settings],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    settings => ({ ...settings }),
  ),
  subscriptions: createSelector(
    [state => state.user.subscriptions],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    subscriptions => [...subscriptions] as UserSubscription[],
  ),
  locale: createSelector(
    [state => state.user.settings.locale],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    locale => ({ ...locale }),
  ),
};

export default selectors;
