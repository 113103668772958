import type { StorageProviderItem } from '../../../../types';
import type { UseQueryResult } from '@tanstack/react-query';
import { useImperativeHandle, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import ProviderClient from '../../../clients/provider';
import { CLOUD_STORAGE_CATEGORIES, STORAGE_PROVIDER_KEYS } from '../../../constants';
import { filterItems } from '../../../utils';
import { SKELETON_ITEMS } from '../CloudExplorerTableContainer/CloudExplorerTable/constants';
import { useCloudExplorer } from './useCloudExplorer';
import { useCloudExplorerTable } from './useCloudExplorerTable';

type QueryFnProps = { signal: AbortSignal };
type QueriedItems = {
  items : StorageProviderItem[];
  isPlaceholderData: boolean;
  isFetched : boolean;
  hasItems: boolean;
};
export const useQueriedItems = (): QueriedItems => {
  const { isProviderSignedIn, provider, category, selectedQueriedItemsActions } = useCloudExplorer();
  const { filter, breadcrumbs } = useCloudExplorerTable();

  const oneDriveAllItems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.ONE_DRIVE, CLOUD_STORAGE_CATEGORIES.ALL, breadcrumbs],
    queryFn : async ({ signal }: QueryFnProps) => {
      const items = await ProviderClient.getChildren(STORAGE_PROVIDER_KEYS.ONE_DRIVE, breadcrumbs.at(-1) ?? 'root', signal);
      return await ProviderClient.batchMergeCheckoutUser(STORAGE_PROVIDER_KEYS.ONE_DRIVE, items ?? [], signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.ONE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.ALL
    ),
  });

  const oneDriveRecentItems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.ONE_DRIVE, CLOUD_STORAGE_CATEGORIES.RECENT, filter],
    queryFn : async ({ signal }: QueryFnProps) => {
      const items = await ProviderClient.getRecent(STORAGE_PROVIDER_KEYS.ONE_DRIVE, filter, signal);
      return await ProviderClient.batchMergeCheckoutUser(STORAGE_PROVIDER_KEYS.ONE_DRIVE, items ?? [], signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.ONE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.RECENT
    ),
  });

  const oneDriveSharedIems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.ONE_DRIVE, CLOUD_STORAGE_CATEGORIES.SHARED, breadcrumbs],
    queryFn : async ({ signal }: QueryFnProps) => {
      const items = await ProviderClient.getShared(STORAGE_PROVIDER_KEYS.ONE_DRIVE, breadcrumbs.at(-1) ?? 'root', signal);
      return await ProviderClient.batchMergeCheckoutUser(STORAGE_PROVIDER_KEYS.ONE_DRIVE, items ?? [], signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.ONE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.SHARED
    ),
  });

  const googleDriveAllItems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, CLOUD_STORAGE_CATEGORIES.ALL, breadcrumbs],
    queryFn : async ({ signal }: QueryFnProps) => {
      return await ProviderClient.getChildren(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, breadcrumbs.at(-1) ?? 'root', signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.ALL
    ),
  });

  const googleDriveRecentItems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, CLOUD_STORAGE_CATEGORIES.RECENT, filter],
    queryFn : async ({ signal }: QueryFnProps) => {
      return await ProviderClient.getRecent(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, filter, signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.RECENT
    ),
  });

  const googleDriveSharedItems = useQuery({
    queryKey: [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, CLOUD_STORAGE_CATEGORIES.SHARED, breadcrumbs],
    queryFn : async ({ signal }: QueryFnProps) => {
      return await ProviderClient.getShared(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE, breadcrumbs.at(-1) ?? 'root', signal);
    },
    placeholderData: SKELETON_ITEMS,
    enabled        : (
      isProviderSignedIn
      && provider === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
      && category === CLOUD_STORAGE_CATEGORIES.SHARED
    ),
  });

  const emptyItems = useMemo(
    () => ({
      data             : SKELETON_ITEMS,
      isFetched        : true,
      isPlaceholderData: true,
    }),
    [],
  ) as unknown as UseQueryResult<StorageProviderItem[] | null, Error>;

  const queriedItems = useMemo(
    () => ({
      msgraph: {
        none  : emptyItems,
        all   : oneDriveAllItems,
        recent: oneDriveRecentItems,
        shared: oneDriveSharedIems,
      },
      gdrive: {
        none  : emptyItems,
        all   : googleDriveAllItems,
        recent: googleDriveRecentItems,
        shared: googleDriveSharedItems,
      },
    }),
    [
      emptyItems,
      oneDriveAllItems,
      oneDriveRecentItems,
      oneDriveSharedIems,
      googleDriveAllItems,
      googleDriveRecentItems,
      googleDriveSharedItems,
    ],
  );

  const selectedItems = useMemo(
    () => queriedItems[provider][category],
    [provider, category, queriedItems],
  );

  useImperativeHandle(
    selectedQueriedItemsActions,
    () => ({
      refetchItems: selectedItems.refetch,
    }),
    [selectedItems],
  );

  const filteredItems = useMemo(() => {
    if (selectedItems.isPlaceholderData) {
      return selectedItems.data;
    }
    return filterItems(selectedItems.data ?? [], filter);
  }, [filter, selectedItems]);

  return useMemo(
    () => ({
      items            : filteredItems ?? [],
      isPlaceholderData: selectedItems.isPlaceholderData,
      isFetched        : selectedItems.isFetched,
      hasItems         : !!filteredItems?.length,
    }),
    [filteredItems, selectedItems.isPlaceholderData, selectedItems.isFetched],
  );
};
