export const PLATFORM_PORTAL_IDS = {
  NAVIGATOR: 'navigator-portal' as const,
  CARD_LIST: 'card-list-actions' as const,
  CONTENT  : 'content' as const,
  DATA     : 'dashboard-data' as const,
  ASSETS   : 'dashboard-assets' as const,
  PAGES    : 'dashboard-pages' as const,
  TEMPLATES: 'templates' as const,
  SHAPES   : 'shapes' as const,
};

export const PLATFORM_DATA_ATTRIBUTES = {
  ROOT                       : 'data-platform-root' as const,
  STORAGE_PROJECT_MENU_BUTTON: 'data-platform-storage-project-menu-button' as const,
  HELP_MENU_BUTTON           : 'data-platform-help-menu-button' as const,
  USER_SETTINGS_BUTTON       : 'data-platform-user-settings-button' as const,
  USER_PROFILE_BUTTON        : 'data-platform-user-profile-button' as const,
  APPRAIL                    : 'data-platform-app-rail' as const,
  PORTAL_ID                  : 'data-portalid' as const,
  NAVIGATOR                  : 'data-platform-navigator' as const,
};

export const PLATFORM_SELECTORS = {
  ROOT                       : `*[${PLATFORM_DATA_ATTRIBUTES.ROOT}]` as const,
  STORAGE_PROJECT_MENU_BUTTON: `*[${PLATFORM_DATA_ATTRIBUTES.STORAGE_PROJECT_MENU_BUTTON}]` as const,
  HELP_MENU_BUTTON           : `*[${PLATFORM_DATA_ATTRIBUTES.HELP_MENU_BUTTON}]` as const,
  USER_SETTINGS_BUTTON       : `*[${PLATFORM_DATA_ATTRIBUTES.USER_SETTINGS_BUTTON}]` as const,
  USER_PROFILE_BUTTON        : `*[${PLATFORM_DATA_ATTRIBUTES.USER_PROFILE_BUTTON}]` as const,
  APPRAIL                    : `*[${PLATFORM_DATA_ATTRIBUTES.APPRAIL}]` as const,
  NAVIGATOR                  : `*[${PLATFORM_DATA_ATTRIBUTES.NAVIGATOR}]` as const,
};

export const PLATFORM_ROOT_SELECTOR = PLATFORM_SELECTORS.ROOT;

/**
 * Gets the root DOM element.
 */
export function getRoot(): HTMLElement {
  return document.querySelector(PLATFORM_ROOT_SELECTOR) ?? document.body;
}

/**
 * Checks if the root DOM element is the Platform root element.
 */
export function isRootPlatform(): boolean {
  return getRoot() === document.querySelector(PLATFORM_ROOT_SELECTOR);
}

/**
 * Checks if the current environment is within the Platform environment.
 */
export function isPlatform(): boolean {
  // @ts-expect-error Webpack will fill this value as a boolean rather than a string
  return process.env.IS_PLATFORM === true;
}
