import type { ProviderKey, ProviderStoreSlice, StoreProvider } from './types';
import Storage from '../storage';

const STORAGE_PROVIDERS_KEY = 'mtb-cloud-storage-providers';


export class ProviderStore {
  #storage = new Storage();

  private migrateOldStorage() {
    // TODO
  }

  constructor() {
    this.migrateOldStorage();
  }

  private getSlice(): ProviderStoreSlice {
    return (
      this.#storage.getItem(STORAGE_PROVIDERS_KEY) || {
        defaultProvider: null,
        providers      : {} as Record<ProviderKey, StoreProvider>,
      }
    );
  }

  private setSlice(slice: ProviderStoreSlice) {
    this.#storage.setItem(STORAGE_PROVIDERS_KEY, slice);
  }

  getProvider(key: ProviderKey): StoreProvider | null {
    const slice = this.getSlice();
    return slice.providers[key] || null;
  }

  setProvider(key: ProviderKey, provider: StoreProvider) {
    const slice = this.getSlice();
    slice.providers[key] = provider;
    this.setSlice(slice);
  }

  removeProvider(key: ProviderKey) {
    const slice = this.getSlice();
    delete slice.providers[key];
    this.setSlice(slice);
  }

  setDefaultProvider(key: ProviderKey) {
    const slice = this.getSlice();
    slice.defaultProvider = key;
    this.setSlice(slice);
  }

  getDefaultProvider(): ProviderKey | null {
    const slice = this.getSlice();
    return slice.defaultProvider || null;
  }

  removeDefaultProvider() {
    const slice = this.getSlice();
    slice.defaultProvider = null;
    this.setSlice(slice);
  }

  getProviders(): Record<ProviderKey, StoreProvider> {
    return this.getSlice().providers;
  }
}

const providerStorage = new ProviderStore();

export default providerStorage;
