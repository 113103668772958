import type { PlaneId, PlaneState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PLANE_STATUS_TYPES } from '../constants';

export const setPlaneLoading = {
  reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; status: 'loading' | 'idle' }>) => {
    if (state[action.payload.planeId]) {
      state[action.payload.planeId].status = action.payload.status;
    }
  },
  prepare: (planeId: PlaneId, loading: boolean) => {
    return {
      payload: {
        planeId,
        status: loading ? PLANE_STATUS_TYPES.LOADING : PLANE_STATUS_TYPES.IDLE,
      },
    };
  },
};
