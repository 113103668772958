import type { AsyncUserStateThunkState } from '../types';
import { miniSerializeError } from '@reduxjs/toolkit';
import AuthenticationClient from '../../../../../clients/authentication';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const fetchUserSubscriptions = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/fetchUserSubscriptions',
  async (_, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const claims = getState().user.claims;
      if (!claims.valid || !claims.identifyingparty) {
        throw new Error('Invalid claims');
      }

      const subscriptions = await AuthenticationClient.fetchSubscriptions({
        identifyingParty: claims.identifyingparty,
      });
      return fulfillWithValue(subscriptions);
    } catch (error) {
      return rejectWithValue(miniSerializeError(error));
    }
  },
);
