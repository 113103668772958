import { TableBody } from '@mtb/ui';
import { useTable } from '../context';
import { CloudExplorerTableRow } from './CloudExplorerTableRow';

export function CloudExplorerTableBody() {
  const { rows } = useTable();
  return (
    <TableBody data-testid="cloud-explorer-table-body">
      {rows.map((row) => (
        <CloudExplorerTableRow
          key={row.id}
          row={row} />
      ))}
    </TableBody>
  );
}
