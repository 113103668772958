import { Stack, Typography } from '@mtb/ui';
import { StorageExplorerTableRowActions } from './StorageExplorerTableRowActions';

const createRandomString = (minLength, maxLength) => {
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  return Array.from({ length }, () =>
    String.fromCharCode(Math.floor(Math.random() * 26) + 97),
  ).join('');
};

/**
 * The Skeleton items to display when loading, we generate random strings for values such as the name, owner, and lastModifiedElapsedTime
 * so that the skeletons will take up the dimensions of the content. This helps create skeletons that are more visually
 * consistent with the actual content and it's variations in dimensions.
 */
export const SKELETON_ITEMS = Array.from({ length: 25 }, (_, i) => ({
  id                     : `skeleton-${i}`,
  name                   : createRandomString(13, 37),
  lastModifiedElapsedTime: createRandomString(8, 13),
  lastAccessedElapsedTime: createRandomString(8, 13),
  createdBy              : {
    name: createRandomString(11, 21),
  },
  folder: false,
}));

export const STORAGE_EXPLORER_COLUMNS = {
  NAME: {
    id    : 'name',
    label : 'connection.name',
    render: (row) => (
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        title={row?.name}>
        {row?.icon}
        <Typography noWrap>{row?.name}</Typography>
      </Stack>
    ),
    width: 300,
  },
  LAST_ACCESSED: {
    id    : 'lastAccessed',
    label : 'connection.opened',
    render: (row) => <Typography noWrap>{row?.lastAccessedElapsedTime}</Typography>,
    width : 150,
    align : 'left',
  },
  LAST_MODIFIED: {
    id    : 'lastModified',
    label : 'connection.lastModified',
    render: (row) => <Typography noWrap>{row?.lastModifiedElapsedTime}</Typography>,
    width : 150,
    align : 'left',
  },
  OWNER: {
    id    : 'owner',
    label : 'connection.owner',
    render: (row) => <Typography noWrap>{row?.createdBy?.name}</Typography>,
    width : 175,
    align : 'left',
  },
  ACTIONS: {
    id             : 'actions',
    label          : '',
    render         : StorageExplorerTableRowActions,
    width          : 150,
    paddingY       : 0.5,
    align          : 'right',
    disableSkeleton: true,
  },
};
