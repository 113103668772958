/**
 * Checks if the file is of an accepted type.
 * @param file - The file to be validated.
 * @param accept - The accepted file types.
 */
export const isFileInvalidType = (file: File, accept: string[] = []) => {
  return (
    accept.length && !accept.some((f) => file.name.toLowerCase().endsWith(f))
  );
};

/**
 * Checks if the file is too large.
 * @param file - The file to be validated.
 * @param maxSize - The maximum size of the file.
 */
export const isFileTooLarge = (file: File, maxSize?: number): boolean => {
  if (!maxSize) {
    return false;
  }
  return file.size > maxSize;
};

/**
 * Checks if the file is an image.
 * @param file - The file to be validated.
 * @returns - True if the file is an image, false otherwise.
 */
export const isFileImage = (file: File): boolean => {
  return /^image\//.test(file.type);
};

export const ERRORS = {
  INVALID_FILE_TYPE: /** @type {const} */ ('INVALID_FILE_TYPE'),
  FILE_TOO_LARGE   : /** @type {const} */ ('FILE_TOO_LARGE'),
};

/**
 * @param {File} file - The file to be validated.
 * @param {} [options] - The options for validating the file.
 * @returns {{ isValid: boolean; error: null | ERRORS[keyof ERRORS] }}
 */
type Validation = { isValid: boolean; error: null | typeof ERRORS[keyof typeof ERRORS] }
type ValidationOptions = { accept?: string[]; maxSize?: number }
export const validateFile = (file: File, { accept, maxSize }: ValidationOptions = {}): Validation => {
  if (isFileInvalidType(file, accept)) {
    return { isValid: false, error: ERRORS.INVALID_FILE_TYPE };
  }

  if (isFileTooLarge(file, maxSize)) {
    return { isValid: false, error: ERRORS.FILE_TOO_LARGE };
  }

  return { isValid: true, error: null };
};
