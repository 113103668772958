import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogIcon,
  DialogContent,
  DialogTitle,
  Spacer,
  Stack,
  Tooltip,
} from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

export function ConfirmDialog({
  title,
  message,
  open,
  onClose,
  cancelButtonText,
  confirmButtonText,
}) {
  const [t] = useTranslation();
  const handleOnConfirm = useCallback(() => onClose(true), [onClose]);
  const handleOnCancel = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog
      data-testid="confirm-dialog"
      open={open}
      onClose={handleOnCancel}>
      <DialogTitle>
        {title}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnCancel} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="text"
          onClick={handleOnCancel}>
          {cancelButtonText ?? t('shared.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleOnConfirm}>
          {confirmButtonText ?? t('shared.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function NewConfirmDialog({
  title,
  message,
  open,
  onClose,
  cancelButtonText,
  confirmButtonText,
  severity,
}) {
  const [t] = useTranslation();
  const handleOnConfirm = useCallback(() => onClose(true), [onClose]);
  const handleOnCancel = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog
      data-testid="confirm-dialog"
      open={open}
      onClose={handleOnCancel}>
      <Stack direction="row">
        {severity && <DialogIcon severity={severity} />}
        <Stack
          direction="column"
          width="100%">
          <DialogTitle>{t(title)}</DialogTitle>
          <DialogContent>{t(message)}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleOnConfirm}>
              {t(confirmButtonText)}
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleOnCancel}>
              {t(cancelButtonText)}
            </Button>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default ConfirmDialog;
