import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initializePlanes } from './thunk';

export const initializePlanesReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return (
    builder
      .addCase(initializePlanes.pending, () => undefined)
      .addCase(initializePlanes.fulfilled, () => undefined)
      .addCase(initializePlanes.rejected, () => undefined)
  );
};
