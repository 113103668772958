import type {
  InterceptedAuth,
  SignInEventListener,
  SignOutEventDetail,
  SignOutEventListener,
} from './types';
import type { StoreProvider } from '../../store/providers/types';
import type { ConnectionInfo } from '../projects/types';
import type { DeepPartial } from '../projects/utils';
import type {
  GetItemByIdOptions,
  InterceptedOpenState,
  StorageProviderAutoSaveFolder,
  StorageProviderDriveId,
  StorageProviderItem,
  StorageProviderItemId,
  StorageProviderKey,
} from '@';
import { deepMerge } from '@mtb/utilities';
import CloudStorageApi from '../../api';
import DialogClient from '../../clients/dialog';
import ProviderClient from '../../clients/provider';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import { setOnRefreshTokenError } from '../../providers/ProviderBase/ProviderApi';
import { paddedExpiration } from '../../providers/utils';
import ProviderStore from '../../store/providers';
import { updateLoginCounter } from '../store-utils';
import { ProviderEvents } from './constants';

class ProviderService {
  private eventTarget = new EventTarget();

  onSignIn(listener: SignInEventListener): void {
    this.eventTarget.addEventListener(ProviderEvents.SIGN_IN, listener);
  }

  onSignOut(listener: SignOutEventListener): void {
    this.eventTarget.addEventListener(
      ProviderEvents.SIGN_OUT,
      listener as EventListener,
    );
  }

  async validateProviders(): Promise<void> {
    const validations: Promise<boolean>[] = [];

    for (const provider of Object.values(ProviderStore.getProviders())) {
      validations.push(this.validateProvider(provider.type));
    }

    await Promise.all(validations);
  }

  signIn(key: StorageProviderKey): void {
    this.eventTarget.dispatchEvent(new CustomEvent(ProviderEvents.SIGN_IN));
    ProviderClient.login(key);
  }

  async validateProvider(key: StorageProviderKey): Promise<boolean> {
    const signOut = () => this.signOut(key, true);
    try {
      const provider = this.getProviderInternal(key);
      if (!provider.tokens) {
        signOut();
        return false;
      }

      const newTokens = await CloudStorageApi.refreshToken(
        key,
        provider.tokens.refreshToken,
        { retries: false },
      );

      if (!newTokens) {
        signOut();
        return false;
      }

      this.updateProvider(key, {
        tokens: {
          accessToken: newTokens.access_token,
          refreshToken:
              newTokens.refresh_token ?? provider.tokens.refreshToken,
          expiresAt: paddedExpiration(newTokens.expires_in),
        },
      });
      return true;
    } catch (error) {
      signOut();
      return false;
    }
  }

  async handleInterceptedAuth({ state, response }: InterceptedAuth) {
    const type = state.type;
    if (type === STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      updateLoginCounter();
    }

    const account = await ProviderClient.getAccount(type, response);
    if (!account) {
      return;
    }

    const provider: StoreProvider = {
      type,
      account: {
        id               : account.id,
        email            : account.email ?? '',
        name             : account.name ?? '',
        avatar           : account.picture ?? '',
        isInsightsEnabled: false,
      },
      tokens: {
        accessToken : response.access_token,
        refreshToken: response.refresh_token,
        expiresAt   : paddedExpiration(response.expires_in),
      },
      defaultSaveFolder: null,
    };

    ProviderStore.setProvider(type, provider);

    if (ProviderStore.getDefaultProvider() === null) {
      ProviderStore.setDefaultProvider(type);
    }

    this.syncInsightsSettings(type);
    this.syncProfilePic(type);
  }

  getProviderInternal(key: StorageProviderKey): StoreProvider {
    const provider = ProviderStore.getProvider(key);
    if (!provider) {
      throw new Error('Provider not found');
    }
    return provider;
  }

  updateProvider(
    key: StorageProviderKey,
    updates: DeepPartial<StoreProvider>,
  ): void {
    const provider = this.getProviderInternal(key);
    const updatedProvider = deepMerge(provider, updates) as StoreProvider;
    ProviderStore.setProvider(key, updatedProvider);
  }

  async syncProfilePic(key: StorageProviderKey): Promise<void> {
    if (key !== STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      return;
    }

    const profilePic = await ProviderClient.getAccountPicture(key);
    if (!profilePic) {
      return;
    }

    this.updateProvider(key, { account: { avatar: profilePic } });
  }

  async syncInsightsSettings(key: StorageProviderKey): Promise<void> {
    if (key !== STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      return;
    }

    const insightsSettings = await ProviderClient.getInsightsSettings(key);

    this.updateProvider(key, {
      account: { isInsightsEnabled: insightsSettings.isEnabled },
    });
  }

  async signOut(
    key: StorageProviderKey,
    disableInteraction = false,
  ): Promise<void> {
    if (!disableInteraction) {
      const confirmed = await DialogClient.confirmProviderSignout();
      if (!confirmed) {
        return;
      }
    }
    this.eventTarget.dispatchEvent(
      new CustomEvent<SignOutEventDetail>(ProviderEvents.SIGN_OUT, {
        detail: { type: key },
      }),
    );
    await ProviderClient.logout(key);
    ProviderStore.removeProvider(key);
    if (ProviderStore.getDefaultProvider() === key) {
      ProviderStore.removeDefaultProvider();
    }
  }

  async toggleDefaultProvider(key: StorageProviderKey): Promise<void> {
    const defaultProvider = ProviderStore.getDefaultProvider();
    if (!defaultProvider) {
      ProviderStore.setDefaultProvider(key);
    } else if (defaultProvider === key) {
      ProviderStore.removeDefaultProvider();
    } else {
      const confirmed = await DialogClient.confirmDefaultSaveChange();
      if (confirmed) {
        ProviderStore.setDefaultProvider(key);
      }
    }
  }

  setDefaultSaveFolder(
    key: StorageProviderKey,
    folder: StorageProviderAutoSaveFolder | null,
  ): void {
    this.updateProvider(key, { defaultSaveFolder: folder });
  }

  async getItem(
    connection: ConnectionInfo,
    options?: GetItemByIdOptions,
  ): Promise<StorageProviderItem | null> {
    return await ProviderClient.getItem(connection, options);
  }

  /**
   * Gets the item from the intercepted state.
   * @param state - The intercepted state.
   * @returns The storage item from the provider.
   */
  async getItemFromInterceptedState(state: InterceptedOpenState) {
    return await ProviderClient.getItemFromInterceptedState(
      state.connectionType,
      state,
    );
  }

  /**
   * Downloads the item from the provider.
   * @param type - The provider type.
   * @param id - The item id.
   * @param driveId - The drive id.
   * @returns The item contents as a blob.
   */
  async downloadItem(
    type: StorageProviderKey,
    id: StorageProviderItemId,
    driveId: StorageProviderDriveId,
  ): Promise<Blob> {
    return await ProviderClient.downloadItem(type, id, driveId);
  }

  async renameItem(
    connection: ConnectionInfo,
    name: string,
  ): Promise<string | void> {
    return await ProviderClient.renameItem(connection, name);
  }

  async duplicateItem(
    connection: ConnectionInfo,
  ): Promise<StorageProviderItem> {
    return await ProviderClient.duplicateItem(connection);
  }
}

const providerService = new ProviderService();

setOnRefreshTokenError((type: StorageProviderKey) => {
  providerService.signOut(type, true);
  DialogClient.alertConnectionLost();
});

export default providerService;
