import type { CreateSnackbarArg, Snackbar, SnackbarState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { DEFAULT_SNACKBAR_PROPS, SNACKBAR_SEVERITIES } from '../constants';
import { createSnackbarSchema } from './schema';

export const addSnack = {
  reducer: (state: SnackbarState, action: PayloadAction<Snackbar>) => {
    state.snackPack.push(action.payload);
  },
  prepare: (snackbar: CreateSnackbarArg): { payload: Snackbar } => {
    const id = uuid();
    const planeId = snackbar.planeId || null;
    const severity =
      snackbar.severity && SNACKBAR_SEVERITIES.includes(snackbar.severity)
        ? snackbar.severity
        : DEFAULT_SNACKBAR_PROPS.severity;
    const icon = snackbar.icon || DEFAULT_SNACKBAR_PROPS.icon;
    const autoHideDuration = snackbar.autoHideDuration || DEFAULT_SNACKBAR_PROPS.autoHideDuration;
    const anchorOrigin = snackbar.anchorOrigin || DEFAULT_SNACKBAR_PROPS.anchorOrigin;
    const payload = {
      id,
      planeId,
      title  : snackbar.title,
      content: snackbar.content,
      icon,
      severity,
      autoHideDuration,
      anchorOrigin,
    };
    const { valid, errors } = createSnackbarSchema().validate(payload);
    if (!valid) {
      throw new Error(
        ['addSnack validation failed with errors:', ...errors.map(e => `snackbar${e.instancePath} ${e.message}`)].join(
          '\n  ',
        ),
      );
    }
    return { payload };
  },
};
