import {
  FileUploadOutlinedIcon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';
import { FileUploader } from './FileUploader';

export const OpenLocal = () => {
  const [t] = useTranslation();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <List>
      <FileUploader>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <ListItemButton>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
          <ListItemIcon>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
            <FileUploadOutlinedIcon />
          </ListItemIcon>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
          <ListItemText>
            <Typography
              noWrap
              variant="title-sm"
              weight="regular">
              {t('connection.openLocal')}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </FileUploader>
    </List>
  );
};
