import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES } from '../constants';
import { PlaneError } from '../error';
import { restorePlane } from './thunk';

export const restorePlaneReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return builder
    .addCase(restorePlane.pending, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id].status = PLANE_STATUS_TYPES.PENDING;
        state[id].requestId = action.meta.requestId;
      }
    })
    .addCase(restorePlane.fulfilled, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id].health = action.payload.health;
        state[id].cloudStorageId = action.payload.cloudStorageId;
        state[id].status = PLANE_STATUS_TYPES.LOADING;
        delete state[id].requestId;
      }
    })
    .addCase(restorePlane.rejected, (state, action) => {
      const id = action.meta.arg;
      if (id && state[id]) {
        state[id].health = PLANE_HEALTH_TYPES.UNHEALTHY;
        state[id].status = PLANE_STATUS_TYPES.ERROR;
        if (action.error) {
          const err = new PlaneError('Failed to restore plane');
          err.info = {
            title      : 'failedProjectRestoreTitle',
            description: 'failedProjectRestoreDescription',
          };
          state[id].error = err.serialize();
        }
        delete state[id].requestId;
      }
    });
};
