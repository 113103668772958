import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogIcon,
  DialogTitle,
  Spacer,
  Stack,
  Tooltip,
} from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

export function NewAlertDialog({ title, message, severity, open, onClose }) {
  const [t] = useTranslation();
  const handleOnConfirm = useCallback(() => onClose(true), [onClose]);
  const handleOnCancel = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog
      data-testid="alert-dialog"
      open={open}
      onClose={handleOnCancel}>
      <Stack direction="row">
        {severity && <DialogIcon severity={severity} />}
        <Stack
          direction="column"
          width="100%">
          <DialogTitle>
            {t(title)}
            <Spacer />
            <Tooltip
              placement="bottom"
              title={t('connection.close')}>
              <Button
                color="default"
                icon={<CloseIcon />}
                variant="text"
                onClick={handleOnCancel} />
            </Tooltip>
          </DialogTitle>
          <DialogContent>{t(message)}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleOnConfirm}>
              {t('shared.OK')}
            </Button>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default NewAlertDialog;
