import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  Spacer,
  Stack,
  Surface,
  Tooltip,
  Typography,
  styled,
} from '@mtb/ui';
import {
  DEFAULT_COLUMNS,
  StorageExplorer,
  StorageExplorerTable,
  StorageExplorerTableBody,
} from '../../components/StorageExplorerV1';
import {
  CHANGE_LOCATION_FILTER,
  CLOUD_STORAGE_CATEGORIES,
} from '../../constants';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { useTranslation } from '../../services/i18n';
import { getFolderName } from '../../utils';

const StyledNavButton = styled(Button)(() => ({
  opacity                          : 0,
  '.mtb-ui-table-row-hover:hover &': {
    opacity: 1,
  },
  '.mtb-ui-table-row-root.mtb-ui-focus-visible &': {
    opacity: 1,
  },
}));

const SAVE_TO_DIALOG_ID = 'save-to-dialog';

/**
 * @param {{ defaultFolder: string, open: boolean, onClose: (confirm?: boolean) => void }} props
 */
export function SaveToDialog({
  onClose,
  open,
  defaultFolder: defaultFolderProp,
}) {
  const [t] = useTranslation('connection');
  const actionsRef = useRef();
  const { getItemById, getAutoSaveFolder, autoSaveFolder, type } =
    useCloudStorageStore();
  const defaultFolder = useMemo(
    () => defaultFolderProp ?? autoSaveFolder,
    [autoSaveFolder, defaultFolderProp],
  );
  const [breadcrumbFolder, setBreadcrumbFolder] = useState(defaultFolder);
  const [selected, setSelected] = useState(null);
  const title = useMemo(
    () => getFolderName(type, breadcrumbFolder),
    [breadcrumbFolder, type],
  );

  const handleOnChange = useCallback(
    ({ selected: nextSelected, folder: nextFolder }) => {
      setBreadcrumbFolder(nextFolder);
      setSelected(nextSelected);
    },
    [],
  );

  const handleOnBackClick = useCallback(async () => {
    actionsRef.current.setIsLoading(true);

    if (breadcrumbFolder?.parentId === 'root') {
      actionsRef.current.setFolder(null);
      return;
    }

    const parentFolder = await getItemById(
      breadcrumbFolder?.parentId,
      breadcrumbFolder?.driveId,
    );
    if (!parentFolder.root) {
      actionsRef.current.setFolder(parentFolder);
    } else {
      actionsRef.current.setFolder(null);
    }
  }, [breadcrumbFolder?.driveId, breadcrumbFolder?.parentId, getItemById]);

  const handleOnClose = useCallback(
    (confirm) => async () => {
      if (!confirm) {
        return onClose(false);
      }

      let folder = selected ?? breadcrumbFolder;
      if (confirm && !folder) {
        const root = await getAutoSaveFolder(true);
        folder = { ...root, type };
      }
      onClose?.(folder);
    },
    [breadcrumbFolder, getAutoSaveFolder, onClose, selected, type],
  );
  const handleOnClick = useCallback(
    (row) => () => actionsRef.current.setFolder(row),
    [],
  );

  const COLUMNS = [
    {
      id    : 'nav',
      render: (row) => (
        <Tooltip
          placement="bottom"
          title={t('connection.openFolder')}>
          <StyledNavButton
            icon={<KeyboardArrowRightIcon />}
            rounded
            size="x-small"
            sx={{ display: 'inline-flex' }}
            variant="highlight"
            onClick={handleOnClick(row)} />
        </Tooltip>
      ),
      disableSkeleton: true,
      width          : 80,
      align          : 'right',
    },
  ];

  return (
    <Dialog
      data-testid="save-to-dialog"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleOnClose(false)}>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          flex="1 1 auto"
          gap={1}
          height="100%">
          <Tooltip
            placement="bottom"
            title={t('connection.previousFolder')}>
            <span>
              <Button
                color="default"
                disabled={!breadcrumbFolder}
                icon={<KeyboardArrowLeftIcon />}
                variant="text"
                onClick={handleOnBackClick} />
            </span>
          </Tooltip>
          <Typography>{title}</Typography>
          <Spacer />
          <Tooltip
            placement="bottom"
            title={t('connection.close')}>
            <Button
              aria-label={t('connection.close')}
              color="default"
              icon={<CloseIcon />}
              variant="text"
              onClick={handleOnClose(false)} />
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          as={Surface}
          height={400}
          variant="outlined"
          width="100%">
          <StorageExplorer
            actions={actionsRef}
            defaultCategory={CLOUD_STORAGE_CATEGORIES.ALL}
            defaultFolder={defaultFolder}
            disableCache
            filter={CHANGE_LOCATION_FILTER}
            id={SAVE_TO_DIALOG_ID}
            onChange={handleOnChange}>
            <StorageExplorerTable columns={[DEFAULT_COLUMNS?.[0], COLUMNS[0]]}>
              <StorageExplorerTableBody />
            </StorageExplorerTable>
          </StorageExplorer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose(true)}>{t('connection.select')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SaveToDialog;
