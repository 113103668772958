import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { validateUser } from './thunk';

export const validateUserReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return (
    builder
      .addCase(validateUser.pending, () => undefined)
      .addCase(validateUser.fulfilled, () => undefined)
      .addCase(validateUser.rejected, () => undefined)
  );
};
