import type { UpdateBrowserHistoryOptions, UpdateBrowserHistoryParams } from './types';
import { ROOT_MODULE_KEY } from '../../constants';
import { kebabCase } from '../../utilities/chase-case';

class BrowserHistoryClient {
  updateBrowserHistory(
    { currentModuleKey, currentPlaneId }: UpdateBrowserHistoryParams,
    { replace = false }: UpdateBrowserHistoryOptions = {},
  ): void {
    const formattedModuleKey =
      !currentPlaneId && currentModuleKey === ROOT_MODULE_KEY ? '' : currentModuleKey;
    const path = [kebabCase(formattedModuleKey), currentPlaneId].filter(Boolean).join('/plane/');
    window.history[replace ? 'replaceState' : 'pushState']({ currentModuleKey, currentPlaneId }, '', `/${path}`);
  }
}

export const browserHistoryClient = new BrowserHistoryClient();
export default browserHistoryClient;
