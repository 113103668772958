import React, { useState, useMemo } from 'react';
import {
  RadioGroup,
  useDidMount,
  TextField,
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@mtb/ui';
import PropTypes from 'prop-types';
import { useTranslation } from '../../services/i18n';

// As more cloud options come online, add them to the cloudOptions array.
export const cloudOptions = [
  { value: 'msgraph', label: 'connection.oneDrive' },
  {
    value     : 'gdrive',
    label     : 'connection.googleDrive',
    subOptions: [{ value: 'gdrive-save-root', label: 'connection.saveRoot' }],
  },
];
export const localOption = [{ value: 'local', label: 'connection.openLocal' }];
export const noOption = [{ value: 'none', label: 'connection.noAutoSave' }];

export const FileActionDialog = (props) => {
  const {
    defaultFileName,
    defaultOption,
    description,
    fileInputOnChangeCallback,
    radioButtonOnChangeCallback,
    radioButtonOptions,
  } = props;
  const [t] = useTranslation();
  const [fileName, setFileName] = useState(defaultFileName);

  const hasOneOption = useMemo(
    () => radioButtonOptions?.length === 1,
    [radioButtonOptions?.length],
  );
  const defaultValue = useMemo(
    () => defaultOption || radioButtonOptions?.[0]?.value,
    [defaultOption, radioButtonOptions],
  );

  const onChange = (event) => {
    event.preventDefault();
    setFileName(event.target.value);
    fileInputOnChangeCallback?.(event.target.value);
  };

  const handleOnRadioChange = (event) => {
    radioButtonOnChangeCallback?.(event.target.value);
  };

  useDidMount(() => {
    if (hasOneOption) {
      radioButtonOnChangeCallback?.(radioButtonOptions?.[0]?.value);
    }
  });

  return (
    <Stack>
      {fileInputOnChangeCallback && (
        <TextField
          label={t('connection.projectInputLabel')}
          value={fileName}
          onChange={onChange} />
      )}
      <Typography>{t(description)}</Typography>
      <RadioGroup
        defaultValue={defaultValue}
        label="file-options">
        {radioButtonOptions.map((option) => (
          <Stack key={option.value}>
            <FormControlLabel
              control={<Radio value={option.value} />}
              label={option.label}
              onClick={handleOnRadioChange} />
            {option?.subOptions?.map((suboption) => (
              <FormControlLabel
                key={suboption.value}
                control={<Checkbox value={suboption.value} />}
                label={suboption.label}
                sx={{ ml: 1 }}
                onClick={handleOnRadioChange} />
            ))}
          </Stack>
        ))}
      </RadioGroup>
      {!fileInputOnChangeCallback && (
        <TextField
          id="open-file-input"
          sx={(t) => t.mixins.visuallyHidden()}
          type="file" />
      )}
    </Stack>
  );
};

FileActionDialog.propTypes = {
  defaultFileName            : PropTypes.string,
  defaultOption              : PropTypes.string,
  description                : PropTypes.string.isRequired,
  fileInputOnChangeCallback  : PropTypes.func,
  radioButtonOnChangeCallback: PropTypes.func.isRequired,
  radioButtonOptions         : PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FileActionDialog;
