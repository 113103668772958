import type { CloudStorageItemConnection } from '../cloud-storage/types';
import { STORAGE_PROVIDER_KEYS } from '@mtb/cloud-storage';
import CloudStorageClient from '../cloud-storage';
import LoggerClient from '../logger';
import { createDataRef } from './utils';

export class CloudStorageProject {
  #id: string | undefined;
  get #item() {
    if (!this.#id) {
      return;
    }
    return CloudStorageClient.getProjectById(this.#id);
  }
  get #connection(): Partial<CloudStorageItemConnection> | undefined {
    const connection = this.#item?.connection;
    return {
      id     : connection?.itemId,
      driveId: connection?.driveId,
      type   : connection?.type,
    };
  }

  #logger = LoggerClient.createNamedLogger(`CloudStorageProject:${this.name}`);

  get id() {
    return this.#id;
  }

  get name() {
    return this.#item?.name;
  }

  get displayName() {
    return this.#item?.displayName;
  }

  get extension() {
    return this.#item?.extension;
  }

  get isLocal() {
    return !this.#connection?.type || this.#connection.type === STORAGE_PROVIDER_KEYS.LOCAL;
  }

  get dataId() {
    return createDataRef(this.#connection);
  }

  constructor(projectId: string) {
    this.#id = projectId;
  }

  /**
   * Gets the cloud item info for this cloud storage project
   * @returns - A promise that resolves to the cloud item backing the cloud storage project
   */
  async #cloudItem() {
    if (!this.#connection?.id) {
      return null;
    }
    return CloudStorageClient.getItemById(this.#connection as CloudStorageItemConnection);
  }

  /**
   * Gets the URL location of the item to view in the cloud provider
   * @returns - A promise that resolves with the location the item is saved to.
   */
  async getCloudLocation(): Promise<string | null> {
    return (await this.#cloudItem())?.parentFolderUrl || null;
  }

  /**
   * Gets the content of the project as a blob.
   * @returns - A promise that resolves with the downloaded item.
   */
  async blob(): Promise<Blob | null> {
    if (!this.#connection?.id) {
      return null;
    }
    return await CloudStorageClient.downloadItem(this.#connection as CloudStorageItemConnection);
  }

  /**
   * Gets the content of the project as a blob.
   * @returns - A promise that resolves with the downloaded item.
   */
  async download(): Promise<boolean> {
    if (!this.id) {
      return false;
    }
    try {
      return await CloudStorageClient.downloadProject(this.id);
    } catch (error) {
      this.#logger.warn(error);
      return false;
    }
  }

  /**
   * Closes the project in cloud storage
   * @returns - A promise that resolves with a boolean indicating success
   */
  async close(): Promise<boolean> {
    if (!this.id) {
      return false;
    }
    try {
      return await CloudStorageClient.closeProject(this.id);
    } catch (error) {
      this.#logger.warn(error);
      return false;
    } finally {
      this.#id = undefined;
    }
  }
}

export default CloudStorageProject;
