import { useCallback } from 'react';
import {
  Button,
  GoogleDriveIcon,
  ListItemText,
  Menu,
  MenuItem,
  MicrosoftOneDriveIcon,
  Stack,
  Typography,
  useMenu,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export const StorageProjectMenuSelectAutosaveProvider = () => {
  const [t] = useTranslation('connection');
  const { buttonProps, menuProps } = useMenu({
    id: 'storage-project-menu-choose-autosave-provider',
  });
  const { defaultProvider, googleDrive, oneDrive, project } =
    useStorageProjectMenu();

  const handleProviderClick = useCallback(
    (provider) => {
      menuProps.onClose();
      project.saveToProvider(provider);
    },
    [menuProps, project],
  );

  const handleButtonClick = useCallback(
    (e) => {
      if (defaultProvider) {
        handleProviderClick(defaultProvider);
      } else if (googleDrive.isSignedIn && oneDrive.isSignedIn) {
        buttonProps.onClick(e);
      } else {
        const signedInProvider = googleDrive.isSignedIn
          ? STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
          : STORAGE_PROVIDER_KEYS.ONE_DRIVE;
        handleProviderClick(signedInProvider);
      }
    },
    [buttonProps, defaultProvider, googleDrive.isSignedIn, handleProviderClick, oneDrive.isSignedIn],
  );

  return (
    <>
      <Button
        disabled={!project.canInteractWithProject}
        title={t('connection.enableAutosave')}
        variant="contained"
        {...buttonProps}
        onClick={handleButtonClick}>
        <Typography
          noWrap
          sx={{ maxWidth: 125 }}>
          {t('connection.enableAutosave')}
        </Typography>
      </Button>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => handleProviderClick(STORAGE_PROVIDER_KEYS.ONE_DRIVE)}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <MicrosoftOneDriveIcon />
            <ListItemText primary={t('connection.oneDrive')} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleProviderClick(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE)
          }>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <GoogleDriveIcon />
            <ListItemText primary={t('connection.googleDrive')} />
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
