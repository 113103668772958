import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import createIntegratedRemoteModuleLoader from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteMinitabOnline = createIntegratedRemoteModuleLoader('mtb_msso', 'MSSO', remoteModuleUrlConfigs.MSSO);

const LazyRemoteMinitabOnline = lazy(loadRemoteMinitabOnline);

const RemoteMinitabOnline = withErrorBoundary(
  withSuspense(LazyRemoteMinitabOnline, () => createElement(CenteredCircularProgress)),
  'RemoteMinitabOnline',
);

export { loadRemoteMinitabOnline, RemoteMinitabOnline };
