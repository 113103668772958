import { useCallback, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Spacer,
  Stack,
  Typography,
} from '@mtb/ui';
import dialogClient from '../../../../clients/dialog';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { getFolderName } from '../../../../utils';
import { useStorageExplorer } from '../../hooks';
import { StorageExplorerPickerAlert } from '../../StorageExplorerPickerAlert';

export const ProviderSaveSettings = () => {
  const [t] = useTranslation();
  const { provider, disableProviderSettings } = useStorageExplorer();
  const { defaultSaveFolder, isDefault, toggleDefault, setDefaultSaveFolder } =
    useProvider(provider);
  const saveFolderName = useMemo(
    () => getFolderName(provider, defaultSaveFolder),
    [defaultSaveFolder, provider],
  );

  const handleOnClick = useCallback(async () => {
    const selection = await dialogClient.selectFolder(provider);

    // clicked away / closed dialog
    if (typeof selection === 'boolean') {
      return;
    }

    // no selection return null, set root, otherwise folder
    const folder =
      selection === null
        ? null
        : {
          id      : selection.id,
          name    : selection.displayName,
          parentId: selection.parentId,
          driveId : selection.driveId,
          webUrl  : selection.parentFolderUrl,
        };

    setDefaultSaveFolder(folder);
  }, [provider, setDefaultSaveFolder]);

  return (
    <FormControl as="fieldset">
      <Typography
        as="legend"
        variant="title-md"
        weight="semibold">
        {t('connection.settings')}
      </Typography>
      <Stack
        alignItems="center"
        data-testid="account-info"
        direction="row"
        flex="1 0 auto"
        flexWrap="wrap"
        gap={1}
        p={2}>
        <StorageExplorerPickerAlert />
        <FormControlLabel
          control={
            <Checkbox
              checked={isDefault}
              disabled={disableProviderSettings}
              onClick={toggleDefault} />
          }
          label={
            <>
              <Typography
                color="text.primary"
                variant="title-sm"
                weight="semibold">
                {t('connection.saveNewFilesTo')}
              </Typography>
              <Typography
                color="text.secondary"
                variant="title-sm"
                weight="regular">
                {saveFolderName}
              </Typography>
            </>
          }
          value="end" />
        <Spacer />
        <Button
          color="default"
          disabled={disableProviderSettings}
          variant="contained"
          onClick={handleOnClick}>
          {t('connection.change')}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default ProviderSaveSettings;
