import type { OnOpenReason } from './constants';
import type { NewStorageProviderKey } from './context';
import type { CloudStorageCategoryKey, StorageProviderItem } from '@';
import type { PropsWithChildren } from 'react';
import React, { useRef } from 'react';
import { Stack } from '@mtb/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CLOUD_STORAGE_CATEGORIES, STORAGE_PROVIDER_KEYS } from '../../constants';
import { useProvider, useSessionStorage } from '../../hooks';
import { CloudExplorerContext } from './context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime      : 30 * 1000,
      refetchInterval: 120 * 1000,
    },
  },
});

const DEFAULT_CLOUD_EXPLORER_PROVIDER = STORAGE_PROVIDER_KEYS.ONE_DRIVE;
const DEFAULT_CLOUD_EXPLORER_CATEGORY = CLOUD_STORAGE_CATEGORIES.NONE;
const isCustomDefaults = (provider: NewStorageProviderKey, category: CloudStorageCategoryKey) => (
  // This check can be used to ensure that custom defaults are respected
  provider !== DEFAULT_CLOUD_EXPLORER_PROVIDER || category !== DEFAULT_CLOUD_EXPLORER_CATEGORY
);

type CloudExplorerProps = PropsWithChildren<{
  id: string;
  onOpen: (item: StorageProviderItem, reason?: OnOpenReason) => Promise<void>;
  onError: () => void;
  renderOpenInMenuItems: JSX.Element[];
  defaultCategory?: CloudStorageCategoryKey;
  defaultProvider?: NewStorageProviderKey;
  defaultFilter: string[];
  disableCache?: boolean;
  actions?: React.MutableRefObject<unknown>;
  sx?: object;
}>;

export const CloudExplorer = ({
  id,
  onOpen,
  onError,
  renderOpenInMenuItems,
  defaultProvider = DEFAULT_CLOUD_EXPLORER_PROVIDER,
  defaultCategory = DEFAULT_CLOUD_EXPLORER_CATEGORY,
  defaultFilter,
  disableCache = false,
  actions,
  sx,
  children,
}: CloudExplorerProps) => {
  const selectedQueriedItemsActions = useRef();

  const { isSignedIn: isOneDriveSignedIn } = useProvider(STORAGE_PROVIDER_KEYS.ONE_DRIVE);
  const { isSignedIn: isGoogleDriveSignedIn } = useProvider(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE);

  const [provider, setProvider] = useSessionStorage<NewStorageProviderKey>({
    noun        : 'provider',
    id,
    // Set default provider to Google Drive if OneDrive is not signed in and Google Drive is
    // session storage initial values will only be set at the start of the session and will not be updated by this afterwards
    initialValue: (!isOneDriveSignedIn && isGoogleDriveSignedIn && !isCustomDefaults(defaultProvider, defaultCategory))
      ? STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
      : defaultProvider,
    disableCache,
  });
  const [category, setCategory] = useSessionStorage<CloudStorageCategoryKey>({
    noun        : 'category',
    id,
    // Set default category to all if either provider is signed in
    // session storage initial values will only be set at the start of the session and will not be updated by this afterwards
    initialValue: (isOneDriveSignedIn || isGoogleDriveSignedIn) && !isCustomDefaults(defaultProvider, defaultCategory)
      ? CLOUD_STORAGE_CATEGORIES.ALL
      : defaultCategory,
    disableCache,
  });

  const { isSignedIn: isProviderSignedIn } = useProvider(provider);

  return (
    <QueryClientProvider client={queryClient}>
      <CloudExplorerContext.Provider
        value={{
          id,
          onOpen,
          onError,
          category,
          setCategory,
          provider,
          setProvider,
          isProviderSignedIn,
          defaultFilter,
          renderOpenInMenuItems,
          disableCache,
          selectedQueriedItemsActions,
          actions,
        }}>
        <Stack
          direction="row"
          gap={3}
          sx={{
            flex     : '1 0 auto',
            height   : '100%',
            width    : '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            ...sx,
          }}>
          {children}
        </Stack>
      </CloudExplorerContext.Provider>
    </QueryClientProvider>
  );
};
