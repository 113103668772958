export enum CLOUD_STORAGE_ERRORS {
  NONE = 0,
  NOT_ENOUGH_STORAGE = 1,
  INVALID_SAVE_LOCATION = 2,
}

export class CloudStorageError extends Error {
  constructor(message: string, code: CLOUD_STORAGE_ERRORS) {
    super(message);
    this.name = 'CloudStorageError';
    this.code = code;
  }

  static Throw(message: string, code: CLOUD_STORAGE_ERRORS) {
    throw new CloudStorageError(message, code);
  }

  code: CLOUD_STORAGE_ERRORS;
}
