import type { AsyncPlaneThunkState } from '../types';
import type { StorageProviderKey } from '@mtb/cloud-storage/types';
import { CloudStorageApi } from '@mtb/cloud-storage';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { closePlane } from '../close-plane';

export const closeAllProviderPlanes = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/closeAllProviderPlanes',
  async (provider: StorageProviderKey, { dispatch, getState }) => {
    if (!provider) {
      return;
    }

    const providerProjects = CloudStorageApi.Project.getProviderProjects(provider);
    if (!providerProjects.length) {
      return;
    }

    const closeProviderPlanePromises: Promise<unknown>[] = [];

    Object.values(getState().planes).forEach((plane) => {
      if (plane.cloudStorageId && providerProjects.includes(plane.cloudStorageId)) {
        closeProviderPlanePromises.push(dispatch(closePlane(plane.id)));
      }
    });

    await Promise.all(closeProviderPlanePromises);
  },
);
