import type { AsyncPlaneThunkState } from '../types';
import ModuleClient from '../../../../../clients/module';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const cleanupPlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/cleanupPlane',
  async (planeId: string, { getState }) => {
    const storePlane = getState().planes[planeId];
    await ModuleClient.Events.cleanup(storePlane);
  },
);
