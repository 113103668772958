import { useMemo } from 'react';
import { Divider, FormControl, FormLabel, Spacer, Stack } from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';
import { StorageProjectMenuSelectAutosaveProvider } from '../StorageProjectMenuSelectAutosaveProvider';
import { StorageProjectMenuSignInButton } from '../StorageProjectMenuSignInButton';
import { AutosaveSwitch } from './AutosaveSwitch';

const AUTOSAVING_TITLES = {
  [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : 'connection.autoSavingOneDrive',
  [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: 'connection.autoSavingGoogleDrive',
  none                                : 'connection.autoSave',
};

export function StorageProjectMenuAutoSave() {
  const [t] = useTranslation();
  const { hasAuthedProvider, project } = useStorageProjectMenu();
  const autosavingProviderTitle = useMemo(
    () =>
      t(AUTOSAVING_TITLES[project.hasConnection ? project.connection.type : 'none']),
    [t, project.hasConnection, project.connection.type],
  );

  return (
    <>
      <FormControl>
        {hasAuthedProvider ? (
          <Stack
            alignItems="center"
            direction="row">
            <FormLabel>{autosavingProviderTitle}</FormLabel>
            <Spacer />
            {project.hasConnection ? (
              <AutosaveSwitch />
            ) : (
              <StorageProjectMenuSelectAutosaveProvider />
            )}
          </Stack>
        ) : (
          <StorageProjectMenuSignInButton />
        )}
      </FormControl>
      <Divider />
    </>
  );
}
