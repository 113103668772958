import React, { useMemo } from 'react';
import { Divider, Stack, Typography } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { StorageProviderLogo } from '../../StorageProviderLogo';
import { useStorageExplorer } from '../hooks';
import { StorageExplorerPickerAlert } from '../StorageExplorerPickerAlert';

export function StorageExplorerFooter() {
  const [t] = useTranslation();
  const { dense } = useStorageExplorer();
  const variant = useMemo(() => (dense ? 'body-sm' : 'body-md'), [dense]);
  const size = useMemo(() => (dense ? 'sm' : 'md'), [dense]);

  return (
    <>
      <StorageExplorerPickerAlert direction="column" />
      <Divider />
      <Stack
        align="center"
        bgcolor="background.100"
        bottom={0}
        direction="row"
        gap={1}
        height={60}
        position="sticky"
        px={2}
        py={1}>
        <Typography variant={variant}>{t('connection.connectedTo')}</Typography>
        <StorageProviderLogo size={size} />
      </Stack>
    </>
  );
}

export default StorageExplorerFooter;
