import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES } from '../constants';
import { PlaneError } from '../error';
import { checkPlaneHealth } from './thunk';

function getPlaneHealthError() {
  const err = new PlaneError('Check plane health failed');
  err.info = {
    title      : 'failedProjectRestoreTitle',
    description: 'failedProjectRestoreDescription',
  };
  return err.serialize();
}

export const checkPlaneHealthReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return (
    builder
      .addCase(checkPlaneHealth.pending, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          state[id].requestId = action.meta.requestId;
        }
      })
      .addCase(checkPlaneHealth.fulfilled, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          const isUnhealthy = action.payload === PLANE_HEALTH_TYPES.UNHEALTHY;
          if (isUnhealthy) {
            state[id].health = PLANE_HEALTH_TYPES.UNHEALTHY;
            state[id].status = PLANE_STATUS_TYPES.ERROR;
            state[id].error = getPlaneHealthError();
          } else {
            state[id].health = action.payload;
            state[id].status = PLANE_STATUS_TYPES.IDLE;
          }
          delete state[id].requestId;
        }
      })
      .addCase(checkPlaneHealth.rejected, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          state[id].health = PLANE_HEALTH_TYPES.UNHEALTHY;
          state[id].status = PLANE_STATUS_TYPES.ERROR;
          state[id].error = getPlaneHealthError();
          delete state[id].requestId;
        }
      })
  );
};
