import type { PropsWithChildren } from 'react';
import {
  FolderOpenedOutlinedIcon,
  MenuItem,
  NestedMenuItem,
  OpenInNewIcon,
  Stack,
  useEventCallback,
} from '@mtb/ui';
import { useTableRow } from '..';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuList,
} from '../../../../../../../menus';
import { useTranslation } from '../../../../../../../services/i18n';
import { useCloudExplorer } from '../../../../../hooks';
import { useTable } from '../../../context';

type CloudExplorerTableContextMenuProps = PropsWithChildren<object>;
export const CloudExplorerTableContextMenu = ({
  children,
  ...other
}: CloudExplorerTableContextMenuProps) => {
  const [t] = useTranslation();
  const { renderOpenInMenuItems } = useCloudExplorer();
  const { disableContextMenus } = useTable();
  const row = useTableRow();

  const handleOnOpenFileLocationClick = useEventCallback(() =>
    window.open(row.parentFolderUrl, '_blank', 'noopener,noreferrer'),
  );

  if (disableContextMenus || row.folder) {
    return children;
  }

  return (
    // @ts-expect-error - Bad MtbUI Types
    <ContextMenu {...other}>
      {/* @ts-expect-error - Bad MtbUI Types */}
      <ContextMenuContent>{children}</ContextMenuContent>
      {/* @ts-expect-error - Bad MtbUI Types */}
      <ContextMenuList>
        {/* @ts-expect-error - Bad MtbUI Types */}
        <NestedMenuItem
          label={
            <Stack
              align="center"
              direction="row">
              {/* @ts-expect-error - Bad MtbUI Types */}
              <FolderOpenedOutlinedIcon sx={{ mr: 1 }} />
              {t('connection.openIn')}
            </Stack>
          }>
          {/* @ts-expect-error - Bad MtbUI Types */}
          {renderOpenInMenuItems?.({ item: row })}
        </NestedMenuItem>
        {Boolean(row.parentFolderUrl) && (
          // @ts-expect-error - Bad MtbUI Types
          <MenuItem onClick={handleOnOpenFileLocationClick}>
            {/* @ts-expect-error - Bad MtbUI Types */}
            <OpenInNewIcon sx={{ mr: 1 }} />
            {t('connection.openFileLocation')}
          </MenuItem>
        )}
      </ContextMenuList>
    </ContextMenu>
  );
};
