import type { UserSettings, UserState } from '../types';
import I18nClient from '../../../../../clients/i18n';

export const updateLocale = {
  reducer: (state: UserState, action: { payload: { locale: Partial<UserSettings['locale']> } }) => {
    const language =
      action.payload.locale.translation || action.payload.locale.language || state.settings.locale.translation;
    const region = action.payload.locale.locale || action.payload.locale.region || state.settings.locale.locale;
    const i18nLanguage = I18nClient.getLocale(language, 'language');
    const i18nRegion = I18nClient.getLocale(region, 'region');

    state.settings.locale = {
      ...state.settings.locale,
      ...i18nLanguage,
      ...i18nRegion,
      ...action.payload.locale,
    };
    localStorage.setItem(I18nClient.localePreferenceKey, JSON.stringify(state.settings.locale));
    I18nClient.changeLanguage(state.settings.locale.code);
  },
  prepare: (locale: Partial<UserSettings['locale']>) => {
    return { payload: { locale } };
  },
};
