import {
  FolderOpenedOutlinedIcon,
  MenuItem,
  NestedMenuItem,
  OpenInNewIcon,
  Stack,
  useEventCallback,
} from '@mtb/ui';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuList,
} from '../../../../menus';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorer } from '../../hooks';
import { useStorageExplorerTableRow } from '../StorageExplorerTableRow';

export const StorageExplorerTableContextMenu = ({
  children,
  actions,
  ...other
}) => {
  const [t] = useTranslation();
  const { renderOpenInMenuItems, disableContextMenus } = useStorageExplorer();
  const row = useStorageExplorerTableRow();

  const handleOnOpenFileLocationClick = useEventCallback(() =>
    window.open(row.parentFolderUrl, '_blank', 'noopener,noreferrer'),
  );

  if (disableContextMenus || row.folder) {
    return children;
  }

  return (
    <ContextMenu
      actions={actions}
      {...other}>
      <ContextMenuContent>{children}</ContextMenuContent>
      <ContextMenuList>
        <NestedMenuItem
          label={
            <Stack
              align="center"
              direction="row">
              <FolderOpenedOutlinedIcon sx={{ mr: 1 }} />
              {t('connection.openIn')}
            </Stack>
          }>
          {renderOpenInMenuItems?.({ item: row })}
        </NestedMenuItem>
        {Boolean(row.parentFolderUrl) && (
          <MenuItem onClick={handleOnOpenFileLocationClick}>
            <OpenInNewIcon sx={{ mr: 1 }} />
            {t('connection.openFileLocation')}
          </MenuItem>
        )}
      </ContextMenuList>
    </ContextMenu>
  );
};
