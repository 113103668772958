import type { StorageProviderKey } from '@';
import type { PropsWithChildren } from 'react';
import { Children, useCallback, useMemo } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useCloudExplorer } from '../../hooks';

type ProviderHeaderProps = PropsWithChildren<{
  title: string;
  provider: StorageProviderKey;
}>;
export const ProviderHeader = ({ children, title, provider }: ProviderHeaderProps) => {
  const child = Children.only(children);
  const {
    provider: explorerProvider,
    setProvider,
    category: explorerCategory,
    setCategory,
  } = useCloudExplorer();

  const isSelected = useMemo(
    () => explorerProvider === provider && explorerCategory === CLOUD_STORAGE_CATEGORIES.NONE,
    [explorerProvider, provider, explorerCategory],
  );

  const handleProviderChange = useCallback(() => {
    setProvider(provider);
    setCategory(CLOUD_STORAGE_CATEGORIES.NONE);
  }, [provider, setProvider, setCategory]);

  return (
    <ListItemButton
      selected={isSelected}
      sx={{
        // @ts-expect-error - abnormal, but OK, SX property
        '&.mtb-ui-disabled': {
          opacity: 1,
        },
      }}
      onClick={handleProviderChange}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <ListItemIcon>{child}</ListItemIcon>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
