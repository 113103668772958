import { useCallback, useState } from 'react';
import {
  Avatar,
  Popper,
  Stack,
  Surface,
  Typography,
  useDidMount,
} from '@mtb/ui';
import { useCloudStorageStore } from '../../hooks';
import { useTranslation } from '../../services/i18n';

export const FileStatus = ({ item }) => {
  const [t] = useTranslation('connection');
  const [anchorEl, setAnchorEl] = useState(null);
  const { getCheckoutUser } = useCloudStorageStore();
  const [checkoutUser, setCheckoutUser] = useState(null);

  useDidMount(() => {
    let current = true;
    (async () => {
      const checkoutUser = await getCheckoutUser(item);
      if (!current) {
        return;
      }
      setCheckoutUser(checkoutUser);
    })();
    return () => {
      current = false;
    };
  });

  const handleOnMouseEnter = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!checkoutUser) {
    return null;
  }

  return (
    <>
      <Typography
        data-testid="checkedout-status"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}>
        {t('connection.checkedOut')}
      </Typography>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-end">
        <Surface
          data-testid="checkedout-user-surface"
          rounded
          sx={{ py: 1, px: 2, minWidth: 225, maxWidth: 300 }}>
          <Stack
            alignItems="center"
            data-testid="checkedout-user"
            direction="row"
            gap={1}>
            <Avatar
              alt={checkoutUser?.displayName}
              data-testid="checkedout-user-avatar"
              src={checkoutUser?.photo} />
            <Stack>
              <Typography
                data-testid="checkedout-user-display-name"
                variant="title-sm">
                {checkoutUser?.displayName}
              </Typography>
              <Typography data-testid="checkedout-user-email">
                {checkoutUser?.email}
              </Typography>
            </Stack>
          </Stack>
        </Surface>
      </Popper>
    </>
  );
};
