import type { AsyncPlaneThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { closePlane } from '../close-plane';

export const closeAllPlanes = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/closeAllPlanes',
  async (_: void, { dispatch, getState }) => {
    const planes = Object.values(getState().planes);
    await Promise.all(planes.map(plane => dispatch(closePlane(plane.id))));
  },
);
