import { useCallback } from 'react';
import { Link } from '@mtb/ui';
import { CONNECTION_STATUS } from '../../../../constants';
import { useTranslation } from '../../../../services/i18n';
import { useStorageProjectMenu } from '../../hooks';

export function StorageProjectMenuFileDownload() {
  const [t] = useTranslation();
  const { onFlush, project } = useStorageProjectMenu();

  const handleOnClick = useCallback(async () => {
    try {
      project.setOperation(CONNECTION_STATUS.SAVING);

      if (onFlush) {
        await onFlush();
      }

      project.download();
    } finally {
      project.clearOperation();
    }
  }, [project, onFlush]);

  return (
    <Link
      disabled={!!project.operation}
      onClick={handleOnClick}>
      {t('connection.download')}
    </Link>
  );
}
