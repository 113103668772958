import { storage as storages } from '@mtb/ui';
import { CLOUD_STORAGE_KEY } from '../../constants';
import configStorage from '../../services/config';
import ProviderStore from '../../store/providers';
import { authorizedApiCall, paddedExpiration } from '../utils';
import ProviderApi from './ProviderApi';

/**
 * The storage type that can be swapped out when determining how long connections should persist.
 */
const storage = storages.localStorage;

/**
 * The number of milliseconds to ignore API calls/exceptions after a logout occurs.
 * @type {number}
 */
const logOutTolerance = 1750;

export class ProviderBase extends ProviderApi {
  /** @type {import('@').StorageProviderKey} */
  _connectionType = null;
  /** @type {string} */
  _storageKey = null;

  /**
   * @param {{ connectionType: import('@').StorageProviderKey, storageKey: string, login: import('@').LoginFunction }} params
   */
  constructor({ connectionType, storageKey, login }) {
    super({ login });
    this._connectionType = connectionType;
    this._storageKey = storageKey;
  }

  get connectionType() {
    return this._connectionType;
  }

  /**
   * @param {Parameters<typeof authorizedApiCall>['0']} config
   * @param {Parameters<typeof authorizedApiCall>['1']} [auth]
   */
  async authorizedApiCall(config, auth) {
    let tokens = auth;
    if (!tokens) {
      tokens = configStorage.config.feature_flag_cs_store_v2
        ? this.getProviderAuthTokens()
        : this.getCachedAuthTokens();
    }
    return await authorizedApiCall.call(this, config, tokens);
  }

  /**
   * Explicit auth for establishing an account initially
   * @param {import('@').InterceptedAuthResponse} auth
   * @param {(error: Error) => void} [onError]
   */
  async getOrConnectAccount(auth, onError) {
    try {
      const cachedAccount = this.getCachedAccount();
      if (cachedAccount?.auth) {
        return cachedAccount;
      }

      const account = await this.getAccount(undefined, auth);
      this.setCache(account.id, account, auth);
      return account;
    } catch (e) {
      console.warn('Connect account warning', e);
      if (typeof onError === 'function') {
        onError(e);
      }
    }
  }

  /**
   * Initializes cache for cloud connection
   * @param {string} id
   * @param {import('@').StorageProviderUser} account
   * @param {import('@').InterceptedAuthResponse} auth
   */
  setCache(id, account, auth) {
    let cache = storage.getItem(this._storageKey);
    // spread existing cache so we don't lose refresh tokens
    // timestamp the expiration as soon as we receive tokens
    auth = {
      ...(cache?.[id]?.auth || {}),
      ...auth,
      expires_at: paddedExpiration(auth.expires_in),
    };
    const newEntry = {
      [id]   : { account, auth },
      type   : this.connectionType,
      current: id,
    };
    cache = !cache ? newEntry : { ...cache, ...newEntry };
    // Set current cloud connection storage key
    storage.setItem(CLOUD_STORAGE_KEY, this._storageKey);

    // Cache our account and tokens
    storage.setItem(this._storageKey, cache);
  }

  /**
   * Sets the active account in the cache
   * @param {string} id
   */
  cacheActiveAccount(id) {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        cache.current = id;
        storage.setItem(this._storageKey, cache);
        storage.setItem(CLOUD_STORAGE_KEY, this._storageKey);
      } catch {}
    }
  }

  /**
   * Caches the tokens for the active cloud connection
   * @param {import('@').InterceptedAuthResponse} auth
   */
  setCacheTokens(auth) {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        const cachedAuth = cache[cache.current].auth;
        const newAuth = {
          ...cachedAuth,
          ...auth,
          refresh_token: auth.refresh_token || cachedAuth.refresh_token,
          expires_at   : paddedExpiration(auth.expires_in),
        };
        cache[cache.current].auth = newAuth;
        storage.setItem(this._storageKey, cache);
      } catch {}
    }
  }

  /**
   * Caches an API item
   * @param {import('@').Jsonifiable} item
   */
  setCachedItem(item) {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        // Create items object if doesn't exist
        if (!cache[cache.current].items) {
          cache[cache.current].items = {};
        }
        cache[cache.current].items[item.id] = item;
        storage.setItem(this._storageKey, cache);
      } catch {}
    }
  }

  /**
   * Clears cache for this cloud connection
   */
  clearCache() {
    storage.removeItem(CLOUD_STORAGE_KEY);
    storage.removeItem(this._storageKey);
  }

  /**
   * Gets object with auth tokens from cache
   * @returns {import('@').InterceptedAuthResponse | null}
   */
  getCachedAuthTokens() {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        return cache[cache.current].auth;
      } catch {
        return null;
      }
    }
  }

  /**
   * Gets basic account info for cloud connection
   * @returns {import('@').StorageProviderUser | undefined}
   */
  getCachedAccount() {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        return cache[cache.current].account;
      } catch {}
    }
  }

  /**
   * Gets a cached API item based on id
   * @param {string} id
   * @returns {import('@').JsonValue|undefined}
   */
  getCachedItem(id) {
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        return cache[cache.current]?.items?.[id];
      } catch {}
    }
  }

  /**
   * Gets the current or root autosave folder
   * @returns {import('@').StorageProviderAutoSaveFolder} auto save folder
   */
  getAutoSaveFolder(reset = false) {
    const webUrl = this.getRootWebUrl();
    const rootAutoSave = {
      id      : 'root',
      driveId : undefined,
      parentId: undefined,
      webUrl,
    };
    if (configStorage.config.feature_flag_cs_store_v2) {
      const defaultProvider = ProviderStore.getDefaultProvider();
      if (!defaultProvider) {
        return rootAutoSave;
      }
      const defaultSaveFolder = ProviderStore.getProvider(defaultProvider).defaultSaveFolder;
      return defaultSaveFolder ? defaultSaveFolder : rootAutoSave;
    }
    const cache = storage.getItem(this._storageKey);
    if (reset) {
      return rootAutoSave;
    }
    try {
      return cache[cache.current].autoSaveFolder || rootAutoSave;
    } catch {
      return rootAutoSave;
    }
  }

  /**
   * Caches an API item
   * @param {import('@').StorageProviderAutoSaveFolder} folder
   * @returns {void}
   */
  setAutoSaveFolder(folder) {
    if (folder?.type !== this._connectionType) {
      // do not set autosave folder to mismatching cloud drive
      return;
    }
    const cache = storage.getItem(this._storageKey);
    if (cache) {
      try {
        cache[cache.current].autoSaveFolder = folder;
        storage.setItem(this._storageKey, cache);
      } catch {}
    }
  }

  /**
   * Checks if we have an authed account
   * @returns {Promise<boolean>}
   */
  async isAuthed() {
    try {
      await this.getAccount(true);
    } catch {
      return false;
    }
    return true;
  }

  /**
   * Retrieves the connect for the item with the account tokens and user information
   * @param {string} id
   * @param {string} driveId
   * @returns {Promise<import('@').StorageProviderItemConnection>}
   */
  async getItemConnection(id, driveId) {
    const item = await this.getItemById(id, driveId);
    return {
      ...item,
      type  : this.connectionType,
      tokens: this.getCachedAuthTokens(),
      user  : this.getCachedAccount(),
    };
  }

  /**
   * @param {string} name
   * @returns {Promise<import('@').StorageProviderItemConnection>}
   */
  async createItemForConnection(name) {
    const { id, driveId } = await this.createDefaultItem(name);
    return this.getItemConnection(id, driveId);
  }

  /**
   * @param {string} name
   * @param {string} folder
   * @param {'fail' | 'replace' | 'rename'} [conflictBehavior="rename"]
   * @returns {Promise<import('@').StorageProviderItemConnection>}
   */
  async createItemInFolder(name, folder, conflictBehavior) {
    const { id, driveId } = await this.createInFolder(
      name,
      folder,
      conflictBehavior,
    );
    return this.getItemConnection(id, driveId);
  }

  /**
   * @returns {boolean}
   */
  didRecentlyLogOut() {
    const lastLogOut = storage.getItem('lastLogOut');
    if (!lastLogOut || isNaN(+lastLogOut)) {
      return false;
    }
    return Date.now() - +lastLogOut < logOutTolerance;
  }
}
