import { useCallback, useEffect, useState } from 'react';
import { Switch } from '@mtb/ui';
import { CONNECTION_STATUS } from '../../../../constants';
import { useStorageProjectMenu } from '../../hooks';

export function AutosaveSwitch() {
  const { onFlush, project } = useStorageProjectMenu();
  const [checked, setChecked] = useState(project.isAutoSaving);

  useEffect(() => {
    setChecked(project.isAutoSaving);
  }, [project.isAutoSaving]);

  const handleChecked = useCallback(async () => {
    try {
      project.setOperation(
        project.isAutoSaving
          ? CONNECTION_STATUS.SAVING
          : CONNECTION_STATUS.CHECKING_OUT,
      );
      setChecked((prev) => !prev);

      // flush the project if we're disabling autosave
      if (checked && onFlush) {
        await onFlush();
      }

      const success = await project.toggleAutoSave();
      if (!success) {
        setChecked((prev) => !prev);
      }
    } finally {
      project.clearOperation();
    }
  }, [project, checked, onFlush]);

  return (
    <Switch
      checked={checked}
      data-testid="storage-project-menu-auto-save-switch"
      disabled={!project.canToggleAutoSave}
      inputProps={{ 'data-testid': 'auto-save-switch' }}
      onClick={handleChecked} />
  );
}
