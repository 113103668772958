import { useContext, useRef } from 'react';
import { useEventCallback, useInView } from '@mtb/ui';
import { useStorageExplorerTable } from '../hooks';
import { StorageExplorerTableRowContext } from './context';

/**
 * Custom hook that provides state and functions for managing storage explorer table row.
 * @returns {import('@').StorageExplorerTableRowContext} An object containing state and functions for managing storage explorer table row.
 */
export const useStorageExplorerTableRow = () => {
  const context = useContext(StorageExplorerTableRowContext);

  if (!context) {
    throw new Error(
      'useCloudStorageExplorerTableRow must be used within a CloudStorageExplorerTableRow',
    );
  }

  return context;
};

export function useInViewTableRow() {
  const tableRowRef = useRef(null);
  const { tableContainerRef } = useStorageExplorerTable();

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    root       : tableContainerRef.current,
  });

  const handleTableRowRef = useEventCallback((node) => {
    if (!tableRowRef.current) {
      inViewRef(node);
      tableRowRef.current = node;
    }
    return node;
  });

  return [handleTableRowRef, inView];
}
