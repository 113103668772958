import type { I18nLocale } from '../../clients/i18n/types';
import type DataService from '../services/data';
import type { UserSettings, UserSubscription } from '../store/reducers/user/types';
import PlatformCore from '..';

/**
 * PlatformModuleBase is the base class that is used by all other platform module
 * classes to extend off of to provide consistent and shared functionality for
 * the different variations of platform modules.
 */
class PlatformModuleBase {
  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return PlatformCore.selectors.settings(PlatformCore.getState());
  }

  /**
   * Get user subscriptions.
   */
  get subscriptions(): UserSubscription[] {
    return PlatformCore.selectors.subscriptions(PlatformCore.getState());
  }

  /**
   * Get user locale.
   */
  get locale(): I18nLocale {
    return PlatformCore.selectors.locale(PlatformCore.getState());
  }

  /**
   * Get the data service.
   */
  get data(): DataService {
    return PlatformCore.Data;
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    return PlatformCore.useSelector(PlatformCore.selectors.claims);
  }

  /**
   * Hook to get the user's subscriptions.
   */
  useSubscriptions(): UserSubscription[] {
    return PlatformCore.useSelector(PlatformCore.selectors.subscriptions);
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return PlatformCore.useSelector(PlatformCore.selectors.locale);
  }
}

export default PlatformModuleBase;
