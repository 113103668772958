import { createSlice } from '@reduxjs/toolkit';
import initialState from './initial-state';
import { setAppRailDrawerOpen } from './set-app-rail-drawer-open';
import { setCurrentLayout } from './set-current-layout';
import { setLoading } from './set-loading';
import { setMaxPlanesDialog } from './set-max-planes-dialog';

export const slice = createSlice({
  name    : 'layout',
  initialState,
  reducers: {
    setAppRailDrawerOpen,
    setCurrentLayout,
    setLoading,
    setMaxPlanesDialog,
  },
});

const layout = {
  reducer: slice.reducer,
  actions: slice.actions,
};

export default layout;
