import { createSlice } from '../../redux-helpers/create-slice';
import { checkAllPlanesHealth, checkAllPlanesHealthReducer } from './check-all-planes-health';
import { checkPlaneHealth, checkPlaneHealthReducer } from './check-plane-health';
import { cleanupPlane, cleanupPlaneReducer } from './cleanup-plane';
import { cleanupPlanes, cleanupPlanesReducer } from './cleanup-planes';
import { closeAllPlanes, closeAllPlanesReducer } from './close-all-planes';
import { closeAllProviderPlanes, closeAllProviderPlanesReducer } from './close-all-provider-planes';
import { closePlane, closePlaneReducer } from './close-plane';
import { createPlane } from './create-plane';
import { createPlaneV1 } from './create-plane-v1';
import { initialState } from './initial-state';
import { initializePlane, initializePlaneReducer } from './initialize-plane';
import { initializePlanes, initializePlanesReducer } from './initialize-planes';
import { restorePlane, restorePlaneReducer } from './restore-plane';
import { setPlaneError } from './set-plane-error';
import { setPlaneLoading } from './set-plane-loading';
import { updatePlane } from './update-plane';
import { updatePlaneState } from './update-plane-state';

export const asyncActions = {
  checkAllPlanesHealth,
  checkPlaneHealth,
  cleanupPlane,
  cleanupPlanes,
  closeAllPlanes,
  closeAllProviderPlanes,
  closePlane,
  initializePlane,
  initializePlanes,
  restorePlane,
};

const slice = createSlice({
  name    : 'planes',
  initialState,
  reducers: {
    createPlane,
    createPlaneV1,
    setPlaneError,
    setPlaneLoading,
    updatePlane,
    updatePlaneState,
  },
  extraReducers: builder => {
    checkAllPlanesHealthReducer(builder),
    checkPlaneHealthReducer(builder),
    cleanupPlaneReducer(builder),
    cleanupPlanesReducer(builder),
    closeAllPlanesReducer(builder),
    closeAllProviderPlanesReducer(builder),
    closePlaneReducer(builder),
    initializePlaneReducer(builder),
    initializePlanesReducer(builder),
    restorePlaneReducer(builder);
  },
});

const planes = {
  actions: slice.actions,
  reducer: slice.reducer,
  asyncActions,
} as const;

export default planes;

