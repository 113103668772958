import { waitForCondition } from '@mtb/utilities';
import { GOOGLE_DRIVE, STORAGE_PROVIDER_KEYS } from '../../constants';
import configStore from '../../services/config';
import { getI18nStore } from '../../services/i18n';
import { GoogleDriveProviderAPI } from './GoogleDriveProviderAPI';

const gDriveApi = new GoogleDriveProviderAPI({
  connectionType: STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
  storageKey    : GOOGLE_DRIVE.STORAGE_KEY,
  login,
});

/** @type {import('@').LoginFunction} */
function login(state) {
  if (window.safari) {
    window._cloudExplorerAuthRedirecting = true;
  }

  // Because an application may actually be using the URL on the login action we should redirect back to where the user came from, to continue
  // the user journey (e.g. to the home page, opened project, etc).
  const returnUrl = window.location.href;
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(
    {
      scope: GOOGLE_DRIVE.SCOPES,
      state: JSON.stringify({
        type       : STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
        action     : 'filepicker',
        redirectUri: configStore.config.cloud_storage_code_url,
        returnUrl,
        ...state,
      }),
      redirect_uri : configStore.config.cloud_storage_code_url,
      response_type: 'code',
      response_mode: 'query',
      access_type  : 'offline',
      // we prompt each time because gdrive will only return a refresh token after consenting
      prompt       : 'consent',
      client_id    : configStore.config.gdrive_clientid,
    },
  )}`;
}

/** @type {import('@').LogoutFunction} */
async function logout() {
  localStorage.setItem('lastLogOut', Date.now());
  gDriveApi.clearCache();
}

/** @type {import('@').ShowShareFileDialogFunction} */
const showShareFileDialog = async (id) => {
  const shareClient = await initShareClient();
  shareClient.setItemIds([id]);
  shareClient.showSettingsDialog();
};

/**
 * Appends the picker element to the portal element
 * @param {string} portalId - The id of the portal element
 * @param {string} pickerSelector - The selector of the picker element
 * @returns {Promise<boolean>} - Resolves to true if the picker was successfully appended to the portal, false otherwise
 */
const portalPicker = async (portalId, pickerSelector) => {
  const portalMounted = await waitForCondition(
    () => !!document.getElementById(portalId),
  );

  if (portalMounted) {
    const picker = document.querySelector(pickerSelector);
    document.getElementById(portalId).appendChild(picker);
    return true;
  }

  return false;
};

/** @type {import('@').openPickerFunction} */
const openPicker = async ({ portalId }) => {
  const picker = await initPicker();
  const tokens = configStore.config.feature_flag_cs_store_v2
    ? gDriveApi.getProviderAuthTokens()
    : gDriveApi.getCachedAuthTokens();
  const oauthToken = tokens?.access_token;

  const view = new picker.DocsView(picker.ViewId.DOCS).setParent('root');

  if (window.configureView) {
    window.configureView(view);
  } else {
    view
      .setMode(picker.DocsViewMode.LIST)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true);
  }

  let pickerInstance;

  return new Promise((res) => {
    const builder = new picker.PickerBuilder()
      .setAppId(configStore.config.gdrive_clientid)
      .setOAuthToken(oauthToken)
      .addView(view)
      .setCallback((data) => {
        switch (data.action) {
          case picker.Action.PICKED:
            res(true);
            break;
          case picker.Action.CANCEL:
          case picker.Action.ERROR:
            res(false);
            break;
          case 'loaded':
            // this is called right after picker opens, we don't want to resolve the promise at this point
            break;
          default:
            // those are the cases we're not expecting to happen, but just in case, resolve the promise to avoid any hang
            res(false);
            break;
        }
      });

    if (window.configureBuilder) {
      window.configureBuilder(builder);
    } else {
      const i18nStore = getI18nStore();
      builder
        .enableFeature(picker.Feature.NAV_HIDDEN)
        .enableFeature(picker.Feature.MULTISELECT_ENABLED)
        .setLocale(i18nStore.i18n.language)
        .setTitle(i18nStore.t('picker.title'));
    }

    pickerInstance = builder.build();
    pickerInstance.setVisible(true);
    portalPicker(portalId, '.picker-dialog').then((didPortal) => {
      if (!didPortal) {
        res(false);
      }
    });
  }).then((result) => {
    pickerInstance.dispose();
    return result;
  });
};

const initGApi = async () => {
  if (!gApiAppended) {
    appendGApi();
  }
  await waitForGApi();
};

let shareClient = null;
const initShareClient = async () => {
  await initGApi();
  return new Promise((res) => {
    window.gapi.load('drive-share', () => {
      (
        shareClient || (shareClient = new window.gapi.drive.share.ShareClient())
      ).setOAuthToken(gDriveApi.getCachedAuthTokens()?.access_token);
      res(shareClient);
    });
  });
};

let gApiAppended = false;
const appendGApi = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = GOOGLE_DRIVE.API_SOURCE;
  document.head.appendChild(script);
  gApiAppended = true;
};

const waitForGApi = () => {
  return new Promise((res) => {
    const checkInterval = setInterval(() => {
      if (window.gapi) {
        clearInterval(checkInterval);
        res();
      }
    }, 10);
  });
};

let pickerLoaded = false;
const initPicker = async () => {
  if (!pickerLoaded) {
    await initGApi();
    window.gapi.load('client:picker');
    pickerLoaded = true;
  }

  return waitForPicker();
};

const waitForPicker = () => {
  return new Promise((res) => {
    const checkInterval = setInterval(() => {
      if (window.google?.picker) {
        clearInterval(checkInterval);
        res(window.google.picker);
      }
    }, 10);
  });
};

/** @type {import('@').ConnectionService<"gdrive">} */
export const gDriveService = {
  type: STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
  api : gDriveApi,
  login,
  logout,
  showShareFileDialog,
  openPicker,
};
