import type { DataItem } from './types';
import type { CloudStoragePassthroughItem } from '@mtb/cloud-storage/types';
import { STORAGE_PROVIDER_KEYS } from '@mtb/cloud-storage';
import CloudStorageClient from '../cloud-storage';
import LoggerClient from '../logger';
import CloudStorageProject from './cloud-storage-project';

class FileItem implements DataItem {
  #file: File;

  #logger = LoggerClient.createNamedLogger(`FileStorageItem:${this.name}`);

  get id() {
    return 'local';
  }

  get name() {
    return CloudStorageClient.getNameParts(this.#file?.name).displayName;
  }

  get extension() {
    return CloudStorageClient.getNameParts(this.#file?.name).extension;
  }

  get type() {
    return STORAGE_PROVIDER_KEYS.LOCAL;
  }

  get connection() {
    return {
      id     : '',
      driveId: '',
      type   : STORAGE_PROVIDER_KEYS.LOCAL,
    };
  }

  constructor(file: File) {
    try {
      if (!file || !(file instanceof File)) {
        throw new Error('Invalid file.');
      }

      this.#file = file;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Check if the name of the file matches the given name.
   * @param name - The name to check against.
   * @returns - Whether the name matches.
   */
  doesNameMatch(name: string) {
    return `${this.name}.${this.extension}` === name;
  }

  /**
   * Verify the file before opening it.
   * @returns - Whether the file is able to be opened.
   */
  verifyBeforeOpen() {
    return true;
  }

  /**
   * Duplicate the file.
   * @returns - A promise that resolves when the file has been duplicated.
   */
  async duplicate(name?: string) {
    this.#file = new File([this.#file], name || this.#file.name);
  }

  /**
   * Rename the file.
   * @param name - The new name of the file.
   * @returns - A promise that resolves when the file has been renamed.
   */
  async rename(name: string) {
    this.#file = new File([this.#file], name);
  }

  /**
   * Create a passthrough item from the file.
   * @returns - A promise that resolves with the passthrough item.
   */
  async createPassthrough(): Promise<CloudStoragePassthroughItem> {
    try {
      const passthroughItem = await CloudStorageClient.createPassthroughItem(this.#file);
      if (!passthroughItem || typeof passthroughItem === 'boolean') {
        throw new Error('Failed to create passthrough item.');
      }
      return passthroughItem;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Create a project from the file.
   * @param name - The name of the project.
   * @returns - A promise that resolves with the project.
   */
  async createProject(name: string): Promise<CloudStorageProject> {
    try {
      if (!this.doesNameMatch(name)) {
        await this.rename(name);
      }
      const project = await CloudStorageClient.createProject(this.#file);
      if (!project || typeof project === 'boolean') {
        throw new Error('Failed to create project.');
      }
      return new CloudStorageProject(project.projectId);
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  async download() {
    return this.#file;
  }
}

export default FileItem;
