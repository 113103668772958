import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  FileUploadOutlinedIcon,
} from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';
import { StorageExplorerFileUploader } from '../../StorageExplorerFileUploader';

export const OpenLocal = () => {
  const [t] = useTranslation();

  return (
    <List>
      <StorageExplorerFileUploader>
        <ListItemButton>
          <ListItemIcon>
            <FileUploadOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography
              noWrap
              variant="title-sm"
              weight="regular">
              {t('connection.openLocal')}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </StorageExplorerFileUploader>
    </List>
  );
};
