import { useCallback } from 'react';
import {
  Button,
  MoreHorizontalIcon,
  Stack,
} from '@mtb/ui';
import { useTableRow } from '..';
import { useContextMenu } from '../../../../../../../menus';
import { useTable } from '../../../context';
import { CloudExplorerTableCheckedOutUserTooltip } from './CloudExplorerTableCheckedOutUserTooltip';

export const CloudExplorerTableRowActions = () => {
  const { folder } = useTableRow();
  const { isLoading } = useTable();
  const { onContextMenu } = useContextMenu();

  const handleOnClick = useCallback(
    (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
      // @ts-expect-error - old js function
      onContextMenu(event);
    },
    [onContextMenu],
  );

  if (isLoading || folder) {
    return null;
  }

  return (
    <Stack
      align="center"
      direction="row"
      gap={1}
      justify="flex-end">
      <CloudExplorerTableCheckedOutUserTooltip />
      <Button
        color="default"
        data-testid="more-options-button-row-action"
        // @ts-expect-error - Bad MtbUI Types
        icon={<MoreHorizontalIcon size="small" />}
        size="small"
        variant="text"
        // @ts-expect-error - Bad MtbUI Types
        onClick={handleOnClick} />
    </Stack>
  );
};

export default CloudExplorerTableRowActions;
