import config from '../../config';
import LoggerClient from '../logger';
import RehydrateState from './state';

/**
 * The RehydrateStateClient class is a singleton class that manages the rehydration state for planes created
 * within the Platform. It provides methods to set, get, and clear the rehydration state for a specific plane.
 */
class RehydrateStateClient {
  #states = new Map<string, RehydrateState>();
  #logger = LoggerClient.createNamedLogger('RehydrateStateClient');

  constructor() {
    if (config.dev_tools_enabled) {
      // @ts-expect-error For debugging purposes only.
      window.RehydrateStateClient = this;
    }

    if (process.env.NODE_ENV === 'test') {
      // @ts-expect-error For testing purposes only.
      this.states = this.#states;
    }
  }

  getOrCreate(key: string): RehydrateState {
    if (!this.#states.has(key)) {
      this.#logger.log(`Creating new state with key ${key}...`);
      this.#states.set(key, new RehydrateState());
    }
    return this.#states.get(key) as RehydrateState;
  }
}

const rehydrateStateClient = new RehydrateStateClient();

export default rehydrateStateClient;
