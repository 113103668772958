import { useMemo } from 'react';
import { useEventCallback } from '@mtb/ui';
import ProviderClient from '../clients/provider';
import { CLOUD_STORAGE_CATEGORIES } from '../constants';
import { filterItems } from '../utils';
import { useCloudStorageStore } from './useCloudStorageStore';
import { useLatestFetch, useNewLatestFetch } from './useLatestFetch';

const DEFAULT_FILTER = [];

/**
 * Custom hook for getting recent cloud storage items.
 * @param {import('@').UseCloudStorageItemsParams} params - The parameters for the hook.
 * @returns {import('@').UseCloudStorageItemsResult}
 */
export function useRecentCloudStorageItems({
  id,
  filter = DEFAULT_FILTER,
  disableCache = false,
} = {}) {
  const cloudStorage = useCloudStorageStore();
  const fetchFn = useEventCallback(
    async (...args) => await cloudStorage.getRecent(filter, ...args),
  );
  const { data, fetch, clear, cancel, type, isFetching, hasFetched } =
    useLatestFetch({
      id,
      noun        : `${CLOUD_STORAGE_CATEGORIES.RECENT}-items`,
      fetchFn,
      initialValue: [],
      disableCache,
    });
  const items = useMemo(() => filterItems(data, filter), [data, filter]);
  return { fetch, clear, cancel, items, type, isFetching, hasFetched };
}

/**
 * Custom hook for getting recent cloud storage items.
 * @param {import('@').UseCloudStorageItemsParams} params - The parameters for the hook.
 * @returns {import('@').UseCloudStorageItemsResult}
 */
export function useNewRecentCloudStorageItems({
  provider,
  id,
  filter = DEFAULT_FILTER,
  disableCache = false,
} = {}) {
  const fetchFn = useEventCallback(
    async (...args) => await ProviderClient.getRecent(provider, filter, ...args),
  );
  const { data, fetch, clear, cancel, isFetching, hasFetched } =
    useNewLatestFetch({
      id,
      noun        : `${provider}-${CLOUD_STORAGE_CATEGORIES.RECENT}-items`,
      fetchFn,
      initialValue: [],
      disableCache,
    });
  const items = useMemo(() => filterItems(data, filter), [data, filter]);
  return { fetch, clear, cancel, items, isFetching, hasFetched };
}
