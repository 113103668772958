import type { AsyncPlaneThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { initializePlane } from '../initialize-plane';

export const initializePlanes = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/initializePlanes',
  async (_, { dispatch, getState }) => {
    const planes = Object.values(getState().planes);
    await Promise.all(planes.map(plane => dispatch(initializePlane(plane.id))));
  },
);
