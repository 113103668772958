import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { closeAllPlanes } from './thunk';

export const closeAllPlanesReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return builder
    .addCase(closeAllPlanes.pending, () => undefined)
    .addCase(closeAllPlanes.fulfilled, () => undefined)
    .addCase(closeAllPlanes.rejected, () => undefined);
};
