import { useMemo } from 'react';
import { Button, Divider, useEventCallback } from '@mtb/ui';
import { ProjectCheckoutStatus } from '../..';
import configStore from '../../../services/config';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuReadonlyInfo() {
  const [t] = useTranslation();
  const { project, onReopenProject, menuProps } = useStorageProjectMenu();
  const operationInProgress = useMemo(
    () => Boolean(project.operation),
    [project.operation],
  );

  const handleOnClick = useEventCallback(async () => {
    menuProps.onClose();
    project.reopenProject({
      reopenProject: onReopenProject,
    });
  }, [project]);

  return (
    <>
      <ProjectCheckoutStatus projectId={project.id} />
      {configStore.config.feature_flag_edit_readonly_projects && (
        <>
          <Button
            disabled={operationInProgress}
            onClick={handleOnClick}>
            {t('connection.edit')}
          </Button>
          <Divider />
        </>
      )}
    </>
  );
}
