import type { ConfigBase } from './ConfigBase';
import type { ClaimsState } from '../../../core/store/reducers/user/types';

type ClaimsStateKeys = keyof Omit<ClaimsState, 'valid' | 'loading' | 'currentRequestId' | 'error'>;

export type PlatformClaimsMap = Partial<{
  [K in ClaimsStateKeys]: string | ((claim: ClaimsState[K]) => ClaimsState[K]);
}>;

export type MappedClaims = Record<string, ClaimsState[ClaimsStateKeys]>;

/**
 * Represents the claims configuration for a platform remote module.
 */
class Claims {
  #protected: ConfigBase;
  #claimsMap: PlatformClaimsMap = {};
  #initialClaims: Record<string, unknown> = {};

  /**
   * Creates an instance of a Claims.
   * @param configBase - A pointer to the protected config base
   */
  constructor(configBase: ConfigBase) {
    this.#protected = configBase;
  }

  /**
   * Creates an instance of a Claims.
   * @param configBase - A pointer to the protected config base
   */
  static Create(configBase: ConfigBase) {
    return new Claims(configBase);
  }

  /**
   * Configures the platform with the claims map and initial values for claims.
   * @param config - The configuration object.
   * @param config.claimsMap - The mapping of claim properties.
   * @param config.initialClaims - The initial values for claims.
   */
  setClaimsTransformer = ({
    claimsMap = {},
    initialClaims = {},
  }: { claimsMap?: PlatformClaimsMap; initialClaims?: Record<string, unknown> } = {}): void => {
    this.#claimsMap = claimsMap;
    this.#initialClaims = initialClaims;
  };

  /**
   * Uses a selector for claims.
   */
  useTransformedClaims = (): PlatformClaimsMap & Record<string, unknown> =>
    this.#protected.core.useSelector(store => {
      const claims = store.user.claims;
      const result: PlatformClaimsMap & Record<string, unknown> = { ...this.#initialClaims };

      for (const key in this.#claimsMap) {
        if (Object.prototype.hasOwnProperty.call(claims, key)) {
          const mapping = this.#claimsMap[key as ClaimsStateKeys];
          if (mapping === undefined) {
            continue;
          }
          const value =
            typeof mapping === 'function'
              ? mapping(claims[key as ClaimsStateKeys] as any) // eslint-disable-line @typescript-eslint/no-explicit-any
              : claims[key as ClaimsStateKeys];
          result[typeof mapping === 'function' ? key : mapping] = value;
        }
      }

      return result;
    });
}

export default Claims;
