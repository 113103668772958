import type { InterceptorHandler, RemoveInterceptorHandler } from './types';

/**
 * Manages navigation and page unload events by allowing interceptors to handle unsaved changes
 * or other conditions before the user leaves the page. It provides methods to add, remove, and
 * manage interceptors that can display custom confirmation prompts, cancel navigation, or restore
 * previous states.
 */
class InterceptorClient {
  /**
   * A set to store interceptors for beforeunload event.
   */
  interceptors = new Set<InterceptorHandler>();

  /**
   * The default confirmation prompt message for unsaved changes.
   */
  defaultPrompt = 'You have unsaved changes, are you sure you want to leave?';

  /**
   * Indicates whether a navigation has been intercepted.
   */
  hasIntercepted = false;

  /**
   * Indicates whether the user has cancelled the navigation.
   */
  hasUserCancelled = false;

  /**
   * An array to store the last scroll position.
   */
  lastScroll = { left: 0, top: 0 };

  constructor() {
    /**
     * Adds a listener for the beforeunload event.
     */
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }

  /**
   * Handles the beforeunload event by invoking all interceptors.
   * @param event - The event object representing the navigation event.
   */
  handleBeforeUnload(event: Event) {
    this.interceptors.forEach(interceptor => interceptor(event));
  }

  /**
   * Checks if navigation should be canceled and displays a confirmation prompt if necessary.
   * @returns Returns true if navigation should be canceled, otherwise false.
   */
  shouldCancelNavigation(): boolean {
    this.lastScroll = { left: window.scrollX, top: window.scrollY };
    if (this.hasIntercepted) {
      return this.hasUserCancelled;
    }

    // Check if any interceptors return a prompt
    return Array.from(this.interceptors).some(interceptor => {
      const prompt = interceptor();

      if (!prompt) {
        return false;
      }

      // Abort navigation if the user cancels
      this.hasUserCancelled = !window.confirm(prompt); // eslint-disable-line no-alert

      // Set the flag to prevent future navigation attempts and prompts
      this.hasIntercepted = true;

      // Reset flags after the event loop so that navigation can proceed again
      setTimeout(() => {
        this.hasIntercepted = false;
        this.hasUserCancelled = false;
      }, 0);

      return this.hasUserCancelled;
    });
  }

  /**
   * Cancels a navigation event, preventing the user from leaving the page.
   * @param event - The event object representing the navigation event.
   * @param prompt - The prompt message to display to the user.
   * @returns Returns the prompt message as per the specification.
   */
  cancelNavigation(event: BeforeUnloadEvent, prompt: string): string {
    event.preventDefault();
    event.returnValue = prompt;
    return prompt;
  }

  /**
   * Adds an interceptor function to listen for the beforeunload event.
   * @param handler - The interceptor function to add.
   * @returns A function to remove the interceptor.
   */
  addInterceptor(handler: InterceptorHandler): RemoveInterceptorHandler {
    this.interceptors.add(handler);
    return () => this.removeInterceptor(handler);
  }

  /**
   * Removes an interceptor function from the beforeunload event listener.
   * @param handler - The interceptor function to remove.
   */
  removeInterceptor(handler: InterceptorHandler) {
    this.interceptors.delete(handler);
  }

  /**
   * Removes all interceptor functions from the beforeunload event listener.
   */
  removeAllInterceptors() {
    this.interceptors.clear();
  }

  /**
   * Restores the previous navigation path and scroll position.
   * @param {string} lastPath - The last navigation path to restore.
   */
  undoNavigation(lastPath: string) {
    window.history.pushState(null, '', lastPath);
    setTimeout(() => {
      window.scrollTo(this.lastScroll);
    }, 0);
  }
}

const interceptorClient = new InterceptorClient();
export default interceptorClient;
