import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Switch,
  Button,
  Divider,
  useEventCallback,
} from '@mtb/ui';
import { CONNECTION_STATUS } from '../../../constants';
import { useCloudStorageProject, useCloudStorageStore } from '../../../hooks';
import { ChangeStorageProviderMenu } from '../../../menus';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuAutoSave() {
  const [t] = useTranslation();
  const { onFlush, projectId, onChange } = useStorageProjectMenu();
  const project = useCloudStorageProject(projectId);
  const [checked, setChecked] = useState(project.isAutoSaving);

  const cloudStorage = useCloudStorageStore();
  const hasProvider = useMemo(() => !!cloudStorage.type, [cloudStorage]);

  useEffect(() => {
    setChecked(project.isAutoSaving);
  }, [project.isAutoSaving]);

  const handleOnClick = useEventCallback((provider) =>
    onChange(null, 'provider', provider),
  );
  const handleChecked = useCallback(async () => {
    try {
      project.setOperation(
        project.isAutoSaving
          ? CONNECTION_STATUS.SAVING
          : CONNECTION_STATUS.CHECKING_OUT,
      );
      setChecked((prev) => !prev);

      // flush the project if we're disabling autosave
      if (checked && onFlush) {
        await onFlush();
      }

      const success = await project.toggleAutoSave();
      if (!success) {
        setChecked((prev) => !prev);
      }
    } finally {
      project.clearOperation();
    }
  }, [project, checked, onFlush]);

  return (
    <>
      <FormControl>
        {hasProvider ? (
          <Stack
            alignItems="center"
            direction="row">
            <FormLabel>{t('connection.autoSave')}</FormLabel>
            <Spacer />
            <Switch
              checked={checked}
              data-testid="storage-project-menu-auto-save-switch"
              disabled={!project.canToggleAutoSave}
              inputProps={{ 'data-testid': 'auto-save-switch' }}
              onClick={handleChecked} />
          </Stack>
        ) : (
          <ChangeStorageProviderMenu onClick={handleOnClick}>
            <Button variant="contained">
              {t('connection.signInToRepository')}
            </Button>
          </ChangeStorageProviderMenu>
        )}
      </FormControl>
      <Divider />
    </>
  );
}
