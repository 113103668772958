import type { PlatformCore } from '../../../core';
import type { ModuleConfigKey } from '../../../modules/types';

/**
 * Represents the base configuration for a platform remote module.
 */
export class ConfigBase {
  /**
   * A pointer to the parent instance of Platform Core
   */
  #core: PlatformCore;

  #moduleKey: ModuleConfigKey;

  /**
   * Get the moduleKey of the module.
   */
  get moduleKey() {
    return this.#moduleKey;
  }

  /**
   * Get the module config of the module.
   */
  get module() {
    const modules = this.#core.Modules;
    const modulesKey = this.#moduleKey;
    return modules[modulesKey];
  }

  /**
   * Get the module config of the module.
   */
  get core() {
    return this.#core;
  }

  /**
   * Creates an instance of a ConfigBase.
   * @param moduleKey - The moduleKey of the module.
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  constructor(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    if (!moduleKey) {
      throw new Error('ConfigBase: moduleKey is required.');
    }
    if (!platformCore) {
      throw new Error('ConfigBase: platformCore is required.');
    }

    this.#moduleKey = moduleKey;
    this.#core = platformCore;
  }

  /**
   * Creates an instance of a ConfigBase.
   * @param moduleKey
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  static Create(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    return new ConfigBase(moduleKey, platformCore);
  }
}

export default ConfigBase;
