import { useCallback, useMemo } from 'react';
import { Alert, AlertActions, AlertContent, AlertIcon, Button, Stack, Spacer } from '@mtb/ui';
import DialogClient from '../../../clients/dialog/index.ts';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

export const StorageExplorerPickerAlert = ({ direction = 'row' }) => {
  const [t] = useTranslation();
  const { provider, refresh } = useStorageExplorer();
  const showPicker = useMemo(
    () => provider === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
    [provider],
  );

  const handleOnClick = useCallback(async () => {
    const didPickItems = await DialogClient.googlePicker();
    if (didPickItems) {
      await refresh();
    }
  }, [refresh]);

  if (!showPicker) {
    return null;
  }

  return (
    <Alert
      severity="info"
      sx={{ width: '100%', flexShrink: 0 }}>
      <AlertIcon />
      <Stack
        direction={direction}
        gap={2}
        sx={{ width: '100%' }}>
        <AlertContent>
          {t('picker.whitelistFiles')}
        </AlertContent>
        <AlertActions>
          <Button
            color="secondary"
            onClick={handleOnClick}>{t('picker.grantAccess')}</Button>
        </AlertActions>
      </Stack>
    </Alert>
  );
};
