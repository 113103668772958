import type { CloudExplorerContextType } from '../context';
import { useContext } from 'react';
import { CloudExplorerContext } from '../context';

export const useCloudExplorer = (): CloudExplorerContextType => {
  const context = useContext(CloudExplorerContext);
  if (!context) {
    throw new Error('useCloudExplorer must be used within CloudExplorer');
  }
  return context;
};
