import { createSlice } from '../../redux-helpers/create-slice';
import { fetchUserClaims, fetchUserClaimsReducer } from './fetch-user-claims';
import { fetchUserSubscriptions, fetchUserSubscriptionsReducer } from './fetch-user-subscriptions';
import { initialState } from './initial-state';
import { initializeUser, initializeUserReducer } from './initialize-user';
import { logoutUser, logoutUserReducer } from './logout-user';
import { updateLocale } from './update-locale';
import { validateUser, validateUserReducer } from './validate-user';

const asyncActions = {
  initializeUser,
  validateUser,
  fetchUserSubscriptions,
  fetchUserClaims,
  logoutUser,
} as const;

const user = createSlice({
  name    : 'user',
  initialState,
  reducers: {
    updateLocale,
  },
  extraReducers: builder => {
    initializeUserReducer(builder);
    validateUserReducer(builder),
    fetchUserSubscriptionsReducer(builder),
    fetchUserClaimsReducer(builder),
    logoutUserReducer(builder);
  },
});

const { reducer, actions, name, getInitialState } = user;

export { actions, asyncActions, getInitialState, name, reducer };

