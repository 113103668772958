import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { closeAllProviderPlanes } from './thunk';

export const closeAllProviderPlanesReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return builder
    .addCase(closeAllProviderPlanes.pending, () => undefined)
    .addCase(closeAllProviderPlanes.fulfilled, () => undefined)
    .addCase(closeAllProviderPlanes.rejected, () => undefined);
};
