import React, { useCallback, useMemo } from 'react';
import { Tab, Tabs, TabsList } from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORY_VALUES } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

export const StorageExplorerTabs = () => {
  const [t] = useTranslation();
  const { setCategory, category } = useStorageExplorer();
  const currentIndex = useMemo(
    () => CLOUD_STORAGE_CATEGORY_VALUES.indexOf(category),
    [category],
  );
  const handleSetView = useCallback(
    (_, index) => setCategory(CLOUD_STORAGE_CATEGORY_VALUES[index]),
    [setCategory],
  );

  return (
    <Tabs
      data-testid="storage-explorer-tabs"
      style={{ width: '100%' }}
      value={currentIndex}
      onChange={handleSetView}>
      <TabsList>
        {CLOUD_STORAGE_CATEGORY_VALUES.map((value, index) => (
          <Tab
            key={value}
            // Unset aria-controls to avoid a warning from the Tabs component
            // because we're not using it in tandem with a TabPanel component
            aria-controls={undefined}
            value={index}>
            {t(`connection.${value}`)}
          </Tab>
        ))}
      </TabsList>
    </Tabs>
  );
};
