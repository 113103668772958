import type { StorageProviderItem } from '@';
import { useCallback } from 'react';
import {
  Breadcrumbs,
  KeyboardArrowRightIcon,
  Link,
  Stack,
  Typography,
} from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';
import { useCloudExplorer, useCloudExplorerTable } from '../../hooks';

type PathTranslations = {
  recent: string;
  all: string;
  shared: string;
  [key: string]: string;
}
const PATH_TRANSLATIONS: PathTranslations = {
  all   : 'connection.files',
  recent: 'connection.recentFiles',
  shared: 'connection.sharedFiles',
};

export const CloudExplorerBreadcrumbs = () => {
  const [t] = useTranslation();
  const { category } = useCloudExplorer();
  const { breadcrumbs, setBreadcrumbs } = useCloudExplorerTable();

  const handleOnBreadcrumbClick = useCallback(
    (breadcrumb?: StorageProviderItem) => () => {
      if (!breadcrumb) {
        setBreadcrumbs([]);
        return;
      }

      setBreadcrumbs((prevBreadcrumbs) => {
        const index = prevBreadcrumbs.findIndex((b) => b.id === breadcrumb.id);
        return prevBreadcrumbs.slice(0, index + 1);
      });
    },
    [setBreadcrumbs],
  );

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      as={Stack}
      data-testid="storage-explorer-breadcrumbs"
      // @ts-expect-error-next-line - Height does not exist on BreadcrumbsProps.
      height={30}
      justifyContent="center"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
      separator={<KeyboardArrowRightIcon sx={{ width: 16, height: 16 }} />}
      sx={{ px: 2 }}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
      <Link
        color="default"
        underline="hover"
        onClick={handleOnBreadcrumbClick()}>
        <Typography weight="medium">
          {t(PATH_TRANSLATIONS[category])}
        </Typography>
      </Link>
      {breadcrumbs.map((breadcrumb: StorageProviderItem, i: number) => {
        const isLast = i === breadcrumbs.length - 1;
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
          <Link
            key={breadcrumb.id}
            color="default"
            disabled={isLast}
            underline={isLast ? 'none' : 'hover'}
            onClick={handleOnBreadcrumbClick(breadcrumb)}>
            <Typography
              color={isLast ? 'text.secondary' : 'text.primary'}
              weight="medium">
              {breadcrumb.name}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
