import type { StorageProviderItem, StorageProviderKey } from '@';
import {
  GooglePickerDialog,
  NewAlertDialog,
  NewConfirmDialog,
  NewOpenInDesktopDialog,
  NewReopenProjectFailedDialog,
  NewSaveToDialog,
} from '../../dialogs';
import { createAgnosticDialog } from './utils.tsx';

class DialogClient {
  confirmationDialog({
    title,
    message,
    cancelButtonText,
    confirmButtonText,
    severity,
  }: {
    title: string;
    message: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    severity?: 'info' | 'error' | 'warning' | 'success';
  }): Promise<boolean> {
    const creation = createAgnosticDialog(
      NewConfirmDialog,
      (onClose, props) => {
        if (!onClose) {
          return { title, message, ...props };
        }
        return {
          title,
          message,
          cancelButtonText,
          confirmButtonText,
          severity,
          open: true,
          onClose,
          ...props,
        };
      },
    );

    return creation();
  }

  alertDialog({
    title,
    message,
    severity,
  }: {
    title: string;
    message: string;
    severity: 'info' | 'error' | 'warning' | 'success';
  }): Promise<boolean> {
    const create = createAgnosticDialog(NewAlertDialog, (onClose, props) => {
      if (!onClose) {
        return { title, message, ...props };
      }
      return {
        message,
        title,
        severity,
        open: true,
        onClose,
        ...props,
      };
    });

    return create();
  }

  async alertAlreadyOpen(): Promise<boolean> {
    return await this.alertDialog({
      title   : 'connection.fileCannotBeOpened',
      message : 'connection.fileAlreadyOpenMessage',
      severity: 'warning',
    });
  }

  async alertOutOfStorage(): Promise<boolean> {
    return await this.alertDialog({
      title   : 'connection.outOfStorage',
      message : 'connection.outOfStorageMessage',
      severity: 'warning',
    });
  }

  async alertInvalidSaveLocation(): Promise<boolean> {
    return await this.alertDialog({
      title   : 'dialogs.invalidSaveLocation',
      message : 'dialogs.invalidSaveLocationMessage',
      severity: 'warning',
    });
  }

  async alertConnectionLost(): Promise<boolean> {
    return await this.alertDialog({
      title   : 'dialogs.lostConnection',
      message : 'dialogs.lostConnectionMessage',
      severity: 'error',
    });
  }

  async confirmProviderSignout(): Promise<boolean> {
    return await this.confirmationDialog({
      title            : 'dialogs.signOut',
      message          : 'dialogs.signOutMessage',
      confirmButtonText: 'shared.yes',
      cancelButtonText : 'shared.no',
      severity         : 'warning',
    });
  }

  async confirmDefaultSaveChange(): Promise<boolean> {
    return await this.confirmationDialog({
      title            : 'dialogs.changeAutosaveLocationConfirmation',
      message          : 'dialogs.changeAutosaveLocationMessage',
      confirmButtonText: 'dialogs.changeLocation',
      cancelButtonText : 'dialogs.cancel',
      severity         : 'warning',
    });
  }

  async confirmOverrideLock(): Promise<boolean> {
    return await this.confirmationDialog({
      title            : 'connection.projectLockedTitle',
      message          : 'connection.projectLockOverride',
      confirmButtonText: 'dialogs.overrideLock',
      cancelButtonText : 'dialogs.cancel',
      severity         : 'warning',
    });
  }

  async googlePicker(): Promise<boolean> {
    const dialog = createAgnosticDialog(
      GooglePickerDialog,
      (onClose, props) => {
        return {
          open: true,
          onClose,
          ...props,
        };
      },
    );

    const result = await dialog();
    return result;
  }

  async selectFolder(provider: StorageProviderKey): Promise<StorageProviderItem | boolean | null> {
    const dialog = createAgnosticDialog(NewSaveToDialog, (onClose, props) => {
      return {
        onClose,
        provider,
        ...props,
      };
    });
    const selection = await dialog();
    return selection;
  }

  async openInDesktop(onReopenProject: () => Promise<void>) {
    const resultPromise = createAgnosticDialog(
      NewOpenInDesktopDialog,
      (onClose, props) => {
        return {
          onClose,
          onReopenProject,
          ...props,
        };
      },
    );

    return await resultPromise();
  }

  async reopenProjectFailed(onReopenProject: () => Promise<void>) {
    const resultPromise = createAgnosticDialog(
      NewReopenProjectFailedDialog,
      (onClose, props) => {
        return {
          onClose,
          onReopenProject,
          ...props,
        };
      },
    );

    return await resultPromise();
  }
}

const client = new DialogClient();

export default client;
