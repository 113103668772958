import type { StorageExplorerColumn } from './constants';
import type { StorageExplorerItem, StorageProviderItem } from '@';
import type { SXProp } from '@mtb/ui/types/types';
import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Fade, Stack, Table, TableContainer, Typography } from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';
import { useCloudExplorer, useQueriedItems, useTableSort } from '../../hooks';
import { CloudExplorerTableBody } from './CloudExplorerTableBody';
import { CloudExplorerTableHead } from './CloudExplorerTableHead';
import { CLOUD_EXPLORER_COLUMNS } from './constants';
import { TableContext } from './context';
import EmptyTableIcon from './EmptyTableIcon';

type CloudExplorerTableProps = PropsWithChildren<{
  columns?: StorageExplorerColumn[];
  disableContextMenus?: boolean;
  disableRowActions?: boolean;
  onChange?: (state: { selected?: StorageExplorerItem | StorageProviderItem }) => void;
  sx?: SXProp;
}>
export const CloudExplorerTable = ({
  columns: columnsProp,
  disableContextMenus = false,
  disableRowActions = false,
  onChange,
  children,
  sx,
  ...other
}: CloudExplorerTableProps) => {
  const [t] = useTranslation();
  const { category } = useCloudExplorer();
  const { items, isPlaceholderData, isFetched, hasItems } = useQueriedItems();
  const { rows, ascOrder, sortBy, toggleTableOrder, setTableSort } = useTableSort(items);
  const [selected, setSelected] = useState<StorageExplorerItem | StorageProviderItem | undefined>();

  const columns: StorageExplorerColumn[] = useMemo(
    () => {
      if (columnsProp && columnsProp.length) {
        return columnsProp;
      }
      return [
        CLOUD_EXPLORER_COLUMNS.NAME,
        (category === 'recent'
          ? CLOUD_EXPLORER_COLUMNS.LAST_ACCESSED
          : CLOUD_EXPLORER_COLUMNS.LAST_MODIFIED
        ),
        CLOUD_EXPLORER_COLUMNS.OWNER,
        ...(!disableRowActions
          ? [CLOUD_EXPLORER_COLUMNS.ACTIONS]
          : []
        ),
      ];
    },
    [columnsProp, category, disableRowActions],
  );

  // Allows the parent component to hook into the current selected item through onChange
  useEffect(() => {
    onChange?.({ selected });
  }, [onChange, selected]);

  return (
    <Stack
      sx={{
        height      : '100%',
        width       : '100%',
        overflow    : 'hidden',
        borderRadius: 1,
      }}>
      <TableContainer
        sx={{
          flex         : '0 1 auto',
          height       : 'auto',
          overflowY    : 'auto',
          overflowX    : 'hidden',
          flexDirection: 'column',
          ...sx,
        }}
        {...other}>
        <Fade in>
          <Table
            data-testid="cloud-explorer-table"
            size="medium"
            stickyHeader
            sx={{ tableLayout: 'fixed' }}>
            <TableContext.Provider
              value={{
                rows,
                columns,
                isLoading: (isPlaceholderData || !isFetched),
                toggleTableOrder,
                ascOrder,
                setTableSort,
                sortBy,
                setSelected,
                selected,
                disableContextMenus,
              }}>
              {children || (
                <>
                  <CloudExplorerTableHead />
                  <CloudExplorerTableBody />
                </>
              )}
            </TableContext.Provider>
          </Table>
        </Fade>
        {(!isPlaceholderData && !hasItems) && (
          <Fade
            in
            // Increase the duration of the fade in animation to mask showing empty state
            // on the initial load of the table before we have a chance to fetch items.
            timeout={{ enter: 500 }}>
            <Stack
              align="center"
              backgroundColor="background.surface"
              sx={{ flex: '1 1 auto', pt: 7.5 }}>
              <Stack
                align="center"
                direction="row"
                justify="center"
                sx={{ width: 'min-content', height: 'min-content' }}>
                <EmptyTableIcon />
                <Typography
                  sx={{ position: 'absolute', mt: '10%' }}
                  variant="title-xl"
                  weight="medium">
                  {t('connection.noFilesFound')}
                </Typography>
              </Stack>
            </Stack>
          </Fade>
        )}
      </TableContainer>
    </Stack>
  );
};
