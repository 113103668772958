import type { StorageProviderItem } from '@';
import { useCallback, useImperativeHandle } from 'react';
import { useSessionStorage } from '../../../hooks';
import { useCloudExplorer } from './useCloudExplorer';

export const useBreadcrumbs = () => {
  const {
    id,
    actions,
    provider,
    category,
    disableCache,
  } = useCloudExplorer();

  const [breadcrumbs, setBreadcrumbs] = useSessionStorage<StorageProviderItem[]>({
    noun        : `${provider}-${category}-breadcrumbs`,
    id,
    initialValue: [],
    disableCache,
  });

  const pushBreadcrumb = useCallback((breadcrumb: StorageProviderItem) => {
    setBreadcrumbs((prev) => [...prev, breadcrumb]);
  }, [setBreadcrumbs]);

  const popBreadcrumb = useCallback(() => {
    setBreadcrumbs((prev) => prev.slice(0, -1));
  }, [setBreadcrumbs]);

  useImperativeHandle(
    actions,
    () => ({
      pushBreadcrumb,
      popBreadcrumb,
    }),
    [pushBreadcrumb, popBreadcrumb],
  );

  return {
    breadcrumbs,
    setBreadcrumbs,
    pushBreadcrumb,
    popBreadcrumb,
  };
};
