import { useEffect } from 'react';
import {
  GoogleDriveIcon,
  MicrosoftOneDriveIcon,
  Stack,
  Typography,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES, STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useCloudExplorer } from '../hooks';
import { OpenLocal } from './OpenLocalFile';
import { ProviderList } from './ProviderList';

export const CloudExplorerProviderList = () => {
  const [t] = useTranslation();
  const { isProviderSignedIn, category, setCategory } = useCloudExplorer();

  // Reset category to none if the user is signed out of their current provider
  useEffect(
    () => {
      if (category !== CLOUD_STORAGE_CATEGORIES.NONE && !isProviderSignedIn) {
        setCategory(CLOUD_STORAGE_CATEGORIES.NONE);
      }
    },
    [isProviderSignedIn, setCategory, category],
  );

  return (
    <Stack
      sx={{ minWidth: 200, maxWidth: 250, width: '20%', flex: '1 0 auto' }}>
      <Typography
        color="text.secondary"
        noWrap
        sx={{ pt: 3, pb: 1 }}
        variant="title-md"
        weight="semibold">
        {t('connection.repository')}
      </Typography>
      <ProviderList
        provider={STORAGE_PROVIDER_KEYS.ONE_DRIVE}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture
        providerIcon={<MicrosoftOneDriveIcon />}
        providerName={t('connection.oneDrive')} />
      <ProviderList
        provider={STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing onPointerEnterCapture, onPointerLeaveCapture
        providerIcon={<GoogleDriveIcon />}
        providerName={t('connection.googleDrive')} />
      <OpenLocal />
    </Stack>
  );
};
