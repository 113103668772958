import type { ModuleConfigKey } from '../../modules/types';
import ModuleClient from '../module';
import { ModuleConfig } from './module-config';
import V2EventHandler from './v2-event-handler';

/**
 * @deprecated Use `ModuleClient` from `clients` directory instead.
 */
class DeprecatedModuleClient {
  #registeredModules = new Map<ModuleConfigKey, ModuleConfig>();
  /**
   * Creates a module using the given module key.
   * @param moduleKey - The key of the module.
   * @deprecated Use v3 init-remote-module init api instead.
   */
  createModule(moduleKey: ModuleConfigKey) {
    if (this.#registeredModules.has(moduleKey)) {
      return this.#registeredModules.get(moduleKey) as ModuleConfig;
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const platformCore = require('../../core').default;
    const module = ModuleConfig.Create(moduleKey, platformCore);

    this.#registeredModules.set(moduleKey, module);
    return module;
  }

  /**
   * @param {ModuleConfig} moduleConfig
   * @deprecated Use v3 init-remote-module init api instead.
   * @todo Remove this function once everyone has switched to the v3 init-remote-module api or higher.
   */
  register(moduleConfig: ModuleConfig) {
    V2EventHandler.register(moduleConfig);
    ModuleClient.Events.flushEvents(moduleConfig.key); // v2 events are queued on the v3 module event client
  }
}

/**
 * @deprecated Use `ModuleClient` from `clients` directory instead.
 */
const deprecatedModuleClient = new DeprecatedModuleClient();
export default deprecatedModuleClient;
