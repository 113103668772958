import type { NestedPlaneState, PlaneId, PlaneState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { deepMerge } from '@mtb/utilities';

export const updatePlaneState = {
  reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; state: NestedPlaneState }>) => {
    if (state[action.payload.planeId]) {
      state[action.payload.planeId].state = deepMerge(state[action.payload.planeId].state, action.payload.state);
    }
  },
  prepare: (planeId: PlaneId, state: NestedPlaneState) => {
    return { payload: { planeId, state } };
  },
};
