import type { ModuleConfigKey } from '../../../../../modules/types';
import type { AsyncPlaneThunkState } from '../types';
import MODULES from '../../../../../modules';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { checkPlaneHealth } from '../check-plane-health';
import { closePlane } from '../close-plane';
import { PLANE_VARIANTS } from '../constants';

export const initializePlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/initializePlane',
  async (planeId: string, { dispatch, getState }) => {
    const storePlane = getState().planes[planeId];
    // temporary planes should not outlive renders
    // cleanup any remaining temporary planes when initializing
    if (storePlane.variant === PLANE_VARIANTS.TEMPORARY) {
      dispatch(closePlane(planeId));
    }
    MODULES[storePlane.module as ModuleConfigKey]?.preloadRemoteModule?.();
    await dispatch(checkPlaneHealth(storePlane.id));
  },
);
