import React, { createElement, useCallback, useMemo } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mtb/ui';
import { STORAGE_PROVIDERS, STORAGE_PROVIDER_KEYS } from '../../constants';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { useTranslation } from '../../services/i18n';
import { FileUploader } from '../FileUploader';
import { useStorageExplorer } from '../StorageExplorerV1';

export function StorageProviderList({ onClick }) {
  const { changeProvider } = useCloudStorageStore();
  const { providers } = useStorageExplorer();
  const [t] = useTranslation('connection');

  const options = useMemo(
    () =>
      providers.map((providerKey) => {
        const provider = STORAGE_PROVIDERS[providerKey];
        return {
          key : provider.key,
          name: provider.name,
          Icon: createElement(provider.icon, { size: 'large' }),
        };
      }),
    [providers],
  );

  const handleOnClick = useCallback(
    (provider) => () => {
      if (Object.keys(localStorage).some((key) => key.startsWith(provider))) {
        localStorage.removeItem('lastLogOut');
      }
      onClick?.(provider);
      changeProvider(provider);
    },
    [changeProvider, onClick],
  );

  return (
    <Stack
      gap={1}
      maxWidth="sm">
      <Typography
        as="h2"
        variant="headline-md"
        weight="semibold">
        {t('connection.signIn')}
      </Typography>
      <Typography as="p">{t('connection.signInDescription')}</Typography>
      <List>
        {options.map((option) => (
          <ListItem
            key={option.key}
            disableGutters>
            {option.key !== STORAGE_PROVIDER_KEYS.LOCAL && (
              <ListItemButton
                key={option.key}
                data-testid={`storage-provider-${option.key}`}
                onClick={handleOnClick(option.key)}>
                <ListItemAvatar>{option.Icon}</ListItemAvatar>
                <ListItemText primary={t(option.name)} />
              </ListItemButton>
            )}
            {option.key === STORAGE_PROVIDER_KEYS.LOCAL && (
              <FileUploader>
                <ListItemButton
                  key={option.key}
                  data-testid={`storage-provider-${option.key}`}>
                  <ListItemAvatar>{option.Icon}</ListItemAvatar>
                  <ListItemText primary={t(option.name)} />
                </ListItemButton>
              </FileUploader>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

export default StorageProviderList;
