import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initialState } from '../initial-state';
import { fetchUserClaims } from './thunk';

export const fetchUserClaimsReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return (
    builder
      .addCase(fetchUserClaims.pending, () => undefined)
      .addCase(fetchUserClaims.fulfilled, (state, action) => {
        state.claims = action.payload;
      })
      .addCase(fetchUserClaims.rejected, (state) => {
        state.claims = initialState.claims;
      })
  );
};
