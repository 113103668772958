import { Spacer, Stack } from '@mtb/ui';
import { CloudExplorerGooglePickerAlert } from '../../CloudExplorerGooglePickerAlert';
import { CloudExplorerFileSearch } from './CloudExplorerFileSearch';
import { CloudExplorerFileTypeFilter } from './CloudExplorerFileTypeFilter';

export const CloudExplorerToolbar = () => {
  return (
    <Stack gap={2}>
      <CloudExplorerGooglePickerAlert />
      <Stack
        direction="row"
        gap={1}
        sx={{
          padding        : 1.25,
          borderRadius   : 1,
          backgroundColor: 'background.200',
        }}>
        <CloudExplorerFileSearch />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <Spacer />
        <CloudExplorerFileTypeFilter />
      </Stack>
    </Stack>
  );
};
