import type { ValueOfDefaultFieldOptionsMap } from '@mtb/validation/dist/types/constants';
import { createSchema } from '@mtb/validation';

export const createPlaneSchema = createSchema()
  .addField('module', 'string', { isRequired: true } as ValueOfDefaultFieldOptionsMap)
  .addField('name', 'string', { isRequired: true } as ValueOfDefaultFieldOptionsMap)
  .addField('extension', 'string', { isRequired: true } as ValueOfDefaultFieldOptionsMap)
  .addField('icon', 'string', { isRequired: true } as ValueOfDefaultFieldOptionsMap)
  .addField('cloudStorageId', 'string', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
  .addField('state', 'object', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
  .addField('status', 'string', { isRequired: false } as ValueOfDefaultFieldOptionsMap);

