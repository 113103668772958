import type { SetMaxPlanesDialogAction } from './types';
import type { WritableLayoutState } from '../types';

export const setMaxPlanesDialog = {
  reducer: (state: WritableLayoutState, action: SetMaxPlanesDialogAction) => {
    state.maxPlanesDialog = action.payload.open;
  },
  prepare: (open: boolean) => {
    return { payload: { open } };
  },
};
