import type { PropsOf, Simplify } from '@';
import type { Theme } from '@mtb/ui/types/types';
import type { Root } from 'react-dom/client';
import type { ComponentType } from 'react';
import { ThemeProvider } from '@mtb/ui';
import ReactDOMClient from 'react-dom/client';
import { v5 } from 'uuid';

const createRoot = (elementId: string): Root => {
  let container = document.getElementById(elementId);
  if (!container) {
    container = document.createElement('div');
    container.id = elementId;
    document.body.appendChild(container);
  }
  return ReactDOMClient.createRoot(container);
};

type ResolvePropsFn = (onClose: (result: any) => void, args: Args) => Props;

type DialogComponent = ComponentType<any>;
type Props = PropsOf<DialogComponent>;
type Args = any[];
type Resolver = ResolvePropsFn;

type DialogRender = null | {
  resolve: (confirmed: boolean) => void;
  args: Simplify<
    Omit<Props, 'onClose'> & { onClose?: Parameters<Props['onClose']> }
  >[];
};

export function createAgnosticDialog(
  Component: DialogComponent,
  resolveProps: Resolver,
) {
  let root: Root;
  let promise: DialogRender = null;

  const componentName =
    Component.displayName ?? Component.name ?? 'agnostic-dialog';
  const elementId = v5(componentName, v5.DNS);

  const handleOnClose = (result: any) => {
    promise?.resolve(result);
    promise = null;
    root.unmount();
  };

  const render = () => {
    if (!promise) {
      return;
    }

    const props = resolveProps(handleOnClose, [...promise.args]);

    const compoundOnClose = (result: any) => {
      if (typeof props?.onClose !== 'function') {
        handleOnClose(result);
        return;
      }
      if (props.onClose === handleOnClose) {
        props.onClose(result);
        return;
      }
      if (!props.onClose(result)) {
        return;
      }
      handleOnClose(result);
    };

    root = createRoot(elementId);
    root.render(
      <ThemeProvider theme={{} as Theme}>
        <Component
          open
          {...props}
          onClose={compoundOnClose} />
      </ThemeProvider>,
    );
  };

  const dialog = (...args: Args): Promise<any> =>
    new Promise((resolve, reject) => {
      try {
        promise = { resolve, args };
        render();
      } catch (e) {
        console.error('Error rendering dialog', e);
        reject(e);
      }
    });

  return dialog;
}
