import CloudStorageClient from '../../../clients/cloud-storage';
import CloudItem from '../../../clients/data/cloud-item';
import type { DataItem } from '../../../clients/data/types';
import DialogsClient from '../../../clients/dialogs';
import ModuleClient from '../../../clients/module';
import type { ModuleConfig } from '../../../modules/types';
import platformStore from '../../store';
import type { Plane } from '../../types';
import type { CreateOptions, OpenOptions } from './types';

const MAX_NUMBER_OF_PLANES = 10;

/**
 * Verifies that the maximum number of planes has not been reached.
 */
export function verifyMaxPlanes() {
  const pesistentPlanes = platformStore.selectors.persistentPlanes(platformStore.getState());
  if (pesistentPlanes.length >= MAX_NUMBER_OF_PLANES) {
    DialogsClient.showMaxPlanesDialog({ maxNumberOfPlanes: MAX_NUMBER_OF_PLANES });
    throw new Error('Maximum number of planes reached.');
  }
}

/**
 * Verifies that the required options are provided and the CloudStorage operation is valid before creating a plane.
 * @param module
 * @param options
 */
export function verifyBeforeCreate(module: ModuleConfig, options?: CreateOptions) {
  const name = options?.name || options?.data;
  if (!name && !module.storage?.defaultProjectName) {
    throw new Error(
      `Unable to create plane: a name was not provided and ${module.key} does not have a default project name configured.`,
    );
  }

  if (options?.data instanceof CloudItem) {
    const canOpen = CloudStorageClient.verifyBeforeOpen(options.data.connection);
    if (!canOpen) {
      throw new Error('Unable to create plane: cloud storage item could not be opened.');
    }
  }
}

/**
 * Verifies that the required options are provided before opening a plane.
 * @param _module - The module to open the plane with.
 * @param options - The options to open the plane with.
 */
export function verifyBeforeOpen(module: ModuleConfig, options?: OpenOptions) {
  const user = platformStore.selectors.user(platformStore.getState());
  if (!ModuleClient.getEnabledModule(module.key, user)) {
    throw new Error(`Unable to invoke by module key: unsupported module key: ${module.key}`);
  }

  if (!options) {
    throw new Error('Failed to open analysis: options are required.');
  }

  if (!options.data) {
    throw new Error('Failed to open analysis: data item is required.');
  }

  if (options.planeId && !platformStore.selectors.plane(platformStore.getState(), options.planeId)) {
    throw new Error('Failed to open analysis: plane with the given planeId does not exist.');
  }
}

/**
 * Verifies that the required options are provided before adding a passthrough to a plane.
 * @param plane - The plane to add the passthrough to.
 * @param data - The data item to add as a passthrough.
 */
export function verifyAddPassthroughToPlane(plane: Plane, data: DataItem) {
  if (!plane) {
    throw new Error('Failed to add passthrough for plane: plane does not exist.');
  }

  if (!data) {
    throw new Error('Failed to add passthrough to plane: data item or file is required.');
  }
}
