import type { StoreProject } from './projects/types';
import type { StoreProvider } from './providers/types';
import { deepIsEqual } from '@mtb/utilities';
import ProjectStore from './projects';
import ProviderStore from './providers';

type StoreSnapshot = {
  defaultProvider: string | null;
  providers: Record<string, StoreProvider>;
  projects: Record<string, StoreProject>;
};

class CloudStorageStore {
  #previousSnapshot = this.takeSnapshot();

  private takeSnapshot(): StoreSnapshot {
    return {
      defaultProvider: ProviderStore.getDefaultProvider(),
      providers      : ProviderStore.getProviders(),
      projects       : ProjectStore.getProjects(),
    };
  }

  getSnapshot(): StoreSnapshot {
    const newSnapshot = this.takeSnapshot();
    for (const key in newSnapshot) {
      const snapKey = key as keyof StoreSnapshot;
      if (typeof newSnapshot[snapKey] === 'function') {
        continue;
      }

      if (!deepIsEqual(newSnapshot[snapKey], this.#previousSnapshot[snapKey])) {
        this.#previousSnapshot = newSnapshot;
        break;
      }
    }

    return this.#previousSnapshot;
  }
}

const cloudStorageStore = new CloudStorageStore();

export default cloudStorageStore;
