import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initialState } from '../initial-state';
import { fetchUserSubscriptions } from './thunk';

export const fetchUserSubscriptionsReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return (
    builder
      .addCase(fetchUserSubscriptions.pending, () => undefined)
      .addCase(fetchUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload;
      })
      .addCase(fetchUserSubscriptions.rejected, (state) => {
        state.subscriptions = initialState.subscriptions;
      })
  );
};
