import type { AsyncUserStateThunkState } from '../types';
import AuthenticationClient from '../../../../../clients/authentication';
import InterceptorClient from '../../../../../clients/interceptor';
import { ROOT_MODULE_KEY } from '../../../../../constants';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import layout from '../../layout';
import { asyncActions as planeAsyncActions } from '../../planes';

export const logoutUser = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/logoutUser',
  async (_, { dispatch }) => {
    dispatch(layout.actions.setCurrentLayout({ currentModuleKey: ROOT_MODULE_KEY, currentPlaneId: undefined }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore until we can make the store types better. Dispatch should be okay
    // with us using any of the actions registered to the store. So some improvements need to be made to our types.
    await dispatch(planeAsyncActions.closeAllPlanes());
    InterceptorClient.removeAllInterceptors();
    AuthenticationClient.logout();
  },
);
