import type { ModuleConfig } from '../types';
import { RemoteMinitabOnline, loadRemoteMinitabOnline } from './remote';

const CONFIG: ModuleConfig = {
  key            : 'MSSO',
  title          : 'msso',
  registeredTitle: 'mssoRegistered',
  icon           : 'MinitabOnlineIcon',
  productId      : 'e68147de1c46451bafadcfcc44e196cc',
  card           : {
    title         : 'mssoRegistered',
    description   : 'mssoDescription',
    icon          : 'MinitabOnlineIcon',
    heroIcon      : 'MinitabOnlineHeroIcon',
    tagline       : 'analytics',
    fillColor     : '#D1E8B3',
    hoverFillColor: '#8DC640',
  },
  preloadRemoteModule: loadRemoteMinitabOnline,
  remoteModule       : RemoteMinitabOnline,
  storage            : {
    defaultProjectName: 'mssoDefaultProject',
    defaultExtension  : '.mpx',
    defaultPlaneState : { isNew: false },
    filter            : ['.mpx', '.mpj'],
    passthroughFilter : ['.csv', '.dat', '.mac', '.mtb', '.mtw', '.mwx', '.txt', '.xlsx'],
  },
};

export default CONFIG;
