import { createContext, useContext } from 'react';
import { DEFAULT_COLUMNS } from './constants';

export const StorageExplorerTableContext = createContext({
  columns         : DEFAULT_COLUMNS,
  rows            : [],
  ascOrder        : true,
  sortBy          : 'recent',
  toggleTableOrder: () => undefined,
  setTableSort    : () => undefined,
});

/**
 * Custom hook that provides state and functions for managing storage explorer table.
 * @returns {import('@').StorageExplorerTableContext} An object containing state and functions for managing storage explorer table.
 */
export const useStorageExplorerTable = () => {
  const context = useContext(StorageExplorerTableContext);

  if (!context) {
    throw new Error(
      'useCloudStorageExplorer must be used within a CloudStorageExplorerTable',
    );
  }

  return context;
};
