import type { PlatformCore } from '../../../core';
import type { ModuleConfigKey } from '../../../modules/types';
import Claims from './Claims';
import ConfigBase from './ConfigBase';
import Events from './Events';
import Settings from './Settings';
import State from './State';

export { PLATFORM_APPRAIL_DRAWER_IDS } from '../../../core/store/reducers/layout/constants';

/**
 * @deprecated Use v3 init-remote-module init api instead.
 * @todo Remove this class once everyone has switched to the v3 init-remote-module api or higher.
 */
export class ModuleConfig {
  /**
   * Creates an instance of a ModuleConfig.
   * @param moduleKey - The moduleKey of the module.
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  constructor(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    this.#protected = ConfigBase.Create(moduleKey, platformCore);
    this.#state = State.Create(this.#protected);
    this.#claims = Claims.Create(this.#protected);
    this.#events = Events.Create(this.#protected);
    this.#settings = Settings.Create(this.#protected);
  }

  /**
   * Creates an instance of a ModuleConfig.
   * @param moduleKey
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  static Create(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    return new ModuleConfig(moduleKey, platformCore);
  }

  #protected: ConfigBase;

  get key() {
    return this.#protected.moduleKey;
  }

  #state: State;
  get state() {
    return this.#state;
  }

  #claims: Claims;
  get claims() {
    return this.#claims;
  }

  #events: Events;
  get events() {
    return this.#events;
  }

  #settings: Settings;
  get settings() {
    return this.#settings;
  }
}

export default ModuleConfig;
