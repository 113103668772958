import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { cleanupPlane } from './thunk';

export const cleanupPlaneReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return (
    builder
      .addCase(cleanupPlane.pending, () => undefined)
      .addCase(cleanupPlane.fulfilled, () => undefined)
      .addCase(cleanupPlane.rejected, () => undefined)
  );
};
