import React, { useCallback, useMemo } from 'react';
import { Button, OpenInDialogIcon } from '@mtb/ui';
import { CONNECTION_STATUS } from '../../../constants';
import { useCloudStorageProject, useCloudStorageStore } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuOpenInDesktop() {
  const [t] = useTranslation();
  const cloudStorage = useCloudStorageStore();
  const { onFlush, onReopenProject, projectId, menuProps } =
    useStorageProjectMenu();
  const project = useCloudStorageProject(projectId);
  const hasProvider = useMemo(() => !!cloudStorage.type, [cloudStorage]);

  const handleOnClick = useCallback(async () => {
    try {
      project.setOperation(CONNECTION_STATUS.SAVING);

      if (onFlush) {
        await onFlush();
      }

      await project.openInDesktop(onReopenProject);
      menuProps.onClose();
    } finally {
      project.clearOperation();
    }
  }, [project, onFlush, onReopenProject, menuProps]);

  if (!hasProvider) {
    return null;
  }

  return (
    <Button
      disabled={!project.canOpenInDesktop}
      variant="outlined"
      onClick={handleOnClick}>
      <OpenInDialogIcon />
      {t('connection.openInDesktopApp')}
    </Button>
  );
}
