import type { StorageProviderItem, StorageProviderKey } from '@';
import { createContext } from 'react';

export const initialCloudExplorerTableRowContext: StorageProviderItem = {
  type           : '' as StorageProviderKey,
  id             : '',
  driveId        : '',
  parentId       : '',
  name           : '',
  displayName    : '',
  extension      : '',
  webUrl         : '',
  parentFolderUrl: '',
  size           : 0,
  folder         : false,
  createdDateTime: '',
  createdBy      : {
    id         : '',
    name       : '',
    email      : '',
    displayName: '',
    picture    : '',
  },
  parentReference: {
    id     : '',
    driveId: '',
    siteId : '',
  },
  mimeType               : '',
  lastModifiedDateTime   : '',
  lastModifiedElapsedTime: '',
  lastAccessedDateTime   : '',
  lastAccessedElapsedTime: '',
  downloadUrl            : '',
  checkoutUser           : { displayName: '', email: '', photo: '' },
  root                   : false,
};

export const CloudExplorerTableRowContext = createContext(initialCloudExplorerTableRowContext);
