import * as Comlink from 'comlink';
import { v4 as uuid } from 'uuid';
import CloudStorage from './store';

const broadcastChannelMessageMarker = Symbol('BroadcastChannelMessage');
const bindMarker = (val) => {
  if ((typeof val === 'object' && val !== null) || typeof val === 'function') {
    Object.assign(val, { [broadcastChannelMessageMarker]: true });
  }
  return val;
};

// BroadcastChannel is 1 to many, thus cannot support transfers
Comlink.transferHandlers.set('proxy', {
  canHandle: (val) =>
    ((typeof val === 'object' && val !== null) || typeof val === 'function') &&
    !val[broadcastChannelMessageMarker] &&
    val[Comlink.proxyMarker],
  serialize(obj) {
    const { port1, port2 } = new MessageChannel();
    Comlink.expose(obj, port1);
    return [port2, [port2]];
  },
  deserialize(port) {
    port.start();
    return Comlink.wrap(port);
  },
});

/**
 * @typedef {import('@').Simplify<{ [K in keyof BroadcastServiceClient as K extends `on${infer Head}${infer Rest}` ? `${Lowercase<Head>}${Rest}` : never]: (...args: import('@').Simplify<import('@').Tail<Parameters<BroadcastServiceClient[K]>>>) => ReturnType<BroadcastServiceClient[K]>; }>} Handlers
 */

class BroadcastServiceClient {
  /** @type {readonly string} */
  #windowId = uuid();
  /** @type {readonly string} */
  #channelId = 'f2435c1c-8670-4141-b138-94026d87e16a';
  /** @type {BroadcastChannel | null} */
  #broadcastChannel = null;
  /** @type {import('comlink').Remote<Handlers> | null} */
  #wrappedBroadcastChannel = null;
  // eslint-disable-next-line no-restricted-globals
  #supported = 'BroadcastChannel' in self;
  constructor() {
    if (!this.#supported) {
      if (process.env.NODE_ENV !== 'test') {
        console.warn(
          'BroadcastChannel not supported; broadcast messages will be ignored',
        );
      }
      return;
    }
    this.#broadcastChannel = new BroadcastChannel(this.#channelId);
    this.#wrappedBroadcastChannel = Comlink.wrap(this.#broadcastChannel);
    const handlers = Object.getOwnPropertyNames(
      Object.getPrototypeOf(this),
    ).reduce((acc, name) => {
      if (name.startsWith('on')) {
        acc[name.replace(/^on([A-Z])/, (_, s) => s.toLowerCase())] = (
          ...args
        ) => this[name](...args.map(bindMarker));
      }
      return acc;
    }, {});
    Comlink.expose(handlers, this.#broadcastChannel);
    for (const handler of Object.keys(handlers)) {
      this[handler] = (...args) =>
        this.#wrappedBroadcastChannel?.[handler]?.apply(null, [
          ...args.map(bindMarker),
        ]);
    }
  }

  onInitialize() {
    this.broadcastOpenedItemIds(CloudStorage.openedStorageItems);
  }

  onBroadcastOpenedItemIds(itemIds = []) {
    CloudStorage.addOpenedStorageItems(itemIds);
  }

  onInitializeProject(itemId) {
    CloudStorage.addOpenedStorageItems([itemId]);
  }

  onCloseProject(itemId) {
    CloudStorage.removeOpenedStorageItems([itemId]);
  }
}

/** @type {BroadcastServiceClient & import('comlink').Remote<Handlers>} */
const broadcastServiceClient = new BroadcastServiceClient();
export default broadcastServiceClient;
