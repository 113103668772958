import type { AsyncPlaneThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { checkPlaneHealth } from '../check-plane-health';

export const checkAllPlanesHealth = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/checkAllPlanesHealth',
  async (_: void, { dispatch, getState }) => {
    const planes = Object.values(getState().planes);
    await Promise.all(planes.map(plane => dispatch(checkPlaneHealth(plane.id))));
  },
);
