import type { StorageExplorerColumn } from '../constants';
import { useCallback } from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mtb/ui';
import { useTranslation } from '../../../../../services/i18n';
import { useTable } from '../context';

export const CloudExplorerTableHead = () => {
  const [t] = useTranslation();
  const { columns, sortBy, ascOrder, setTableSort, toggleTableOrder, rows } = useTable();

  const handleOnClick = useCallback(
    (column: StorageExplorerColumn) => () => {
      if (sortBy !== column.id) {
        setTableSort({ sortBy: column.id });
      } else {
        toggleTableOrder();
      }
    },
    [sortBy, setTableSort, toggleTableOrder],
  );

  return (
    <TableHead data-testid="cloud-explorer-table-head">
      <TableRow>
        {columns.map((column) => (
          // @ts-expect-error-next-line - Bad MtbUI Types
          <TableCell
            key={column.id}
            align={column.align}
            data-testid={`cloud-explorer-table-head-${column.id}`}
            direction={ascOrder ? 'asc' : 'desc'}
            sx={{
              px             : 2,
              py             : 2,
              backgroundColor: 'background.200',
              ...(column.width && { width: column.width }),
            }}>
            {column.label.length > 0 && (
              // @ts-expect-error-next-line - Bad MtbUI Types
              <TableSortLabel
                active={sortBy === column.id}
                data-testid={`cloud-explorer-table-head-sort-label-${column.id}`}
                direction={ascOrder ? 'asc' : 'desc'}
                disabled={!rows?.length}
                sx={{ padding: 0 }}
                onClick={handleOnClick(column)}>
                <Typography weight="medium">{t(column.label)}</Typography>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
