import type { OnOpenReason } from '../constants';
import type { StorageProviderItem } from '@';
import type React from 'react';
import { createContext } from 'react';

export type CloudExplorerTableContextType = {
  filter: string[];
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
  openItem: (item: StorageProviderItem, reason?: OnOpenReason) => Promise<boolean | void>;
  breadcrumbs: StorageProviderItem[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<StorageProviderItem[]>>;
  pushBreadcrumb: (breadcrumb: StorageProviderItem) => void;
  popBreadcrumb: () => void;
};

export const initialState: CloudExplorerTableContextType = {
  filter        : [],
  setFilter     : () => undefined,
  openItem      : async () => undefined,
  breadcrumbs   : [],
  setBreadcrumbs: () => undefined,
  pushBreadcrumb: () => undefined,
  popBreadcrumb : () => undefined,
};

export const CloudExplorerTableContext = createContext(initialState);
