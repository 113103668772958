import type { DataItem } from '../../../clients/data/types';
import type { ModuleConfigKey } from '../../../types';
import type { StorageProviderItem } from '@mtb/cloud-storage/types';
import DataClient from '../../../clients/data';
import DialogsClient from '../../../clients/dialogs';
import LoggerClient from '../../../clients/logger';
import ModuleClient from '../../../clients/module';
import platformStore from '../../store';

class DataService {
  #logger = LoggerClient.createNamedLogger('DataService');

  /**
   * Create a data item from a file or cloud data item.
   * @param options - The options to create the data item with.
   * @returns - The created data item.
   */
  async create(item: string | File | StorageProviderItem): Promise<DataItem> {
    return DataClient.create(item);
  }

  /**
   * Opens the Storage Explorer dialog and returns the selected item as a DataRef.
   * @param options
   * @returns
   */
  async select(options: {
    moduleKey: ModuleConfigKey
  }): Promise<DataItem | undefined> {
    try {
      const user = platformStore.selectors.user(platformStore.getState());
      const module = ModuleClient.getEnabledModule(options.moduleKey, user);
      if (!module) {
        throw new Error(`Module with key ${options.moduleKey} is not enabled.`);
      }

      const defaultFilter = module.storage?.passthroughFilter || [];
      const item = await DialogsClient.showStorageExplorerDialog({ defaultFilter });
      if (!item) {
        return;
      }

      const data = DataClient.create(item);
      return data;
    } catch (error) {
      this.#logger.error(error);
    }
  }
}

export default DataService;
