import type ModuleConfig from './module-config';
import type Events from './module-config/Events';
import type { OnOpenFileInfo } from './types';
import type { Plane } from '../../core/store/reducers/planes/types';
import type { ModuleConfigKey } from '../../modules/types';

/**
 * @deprecated Use v3 init-remote-module init api instead.
 * @todo Remove this class once everyone has switched to the v3 init-remote-module api or higher.
 */
export class V2EventHandler {
  #moduleEvents: Map<ModuleConfig['key'], ModuleConfig['events']>;
  constructor() {
    this.#moduleEvents = new Map();
  }

  /**
   * Registers events for a module.
   * @param moduleConfig - The module name.
   */
  register(moduleConfig: ModuleConfig) {
    this.#moduleEvents.set(moduleConfig.key, moduleConfig.events);
  }

  /**
   * Registers events for a module.
   * @param moduleConfig - The module name.
   */
  has(module: ModuleConfigKey) {
    return this.#moduleEvents.has(module);
  }

  /**
   * Events can't garuntee that the module is loaded, so we may need to optionally queue the event
   * @param plane - the plane the event was fired on
   * @param event - the "on" event name
   * @param args - additional arguments to pass to the event
   */
  async #fireOrQueueEvent(plane: Plane, event: keyof Events, ...args: unknown[]): Promise<void> {
    // @ts-expect-error TS doesn't like the spread args here
    await this.#moduleEvents.get(plane.module)?.[event](plane, ...args);
  }

  async pulse(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onPulse');
  }

  async cleanup(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onCleanup');
  }

  async close(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onClose');
  }

  async flush(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onFlush');
  }

  async open(plane: Plane, fileInfo: OnOpenFileInfo) {
    return await this.#fireOrQueueEvent(plane, 'onOpen', fileInfo);
  }
}

const v2EventHandler = new V2EventHandler();
export default v2EventHandler;
