import React, { Children, useCallback, useMemo } from 'react';
import {
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mtb/ui';
import { useStorageExplorer } from '../../hooks';

export const CloudProviderCategoryButton = ({ children, title, category, provider }) => {
  const child = Children.only(children);
  const { provider: explorerProvider, setProvider, category: explorerCategory, setCategory } = useStorageExplorer();

  const isSelected = useMemo(
    () => category === explorerCategory && provider === explorerProvider,
    [category, explorerCategory, provider, explorerProvider],
  );
  const handleSelectCategory = useCallback(
    () => {
      setProvider(provider);
      setCategory(category);
    },
    [category, setCategory, provider, setProvider],
  );

  return (
    <ListItemButton
      selected={isSelected}
      onClick={handleSelectCategory}>
      <ListItemIcon>{child}</ListItemIcon>
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
