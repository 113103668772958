import { useState } from 'react';
import { useDidMount } from '@mtb/ui';
import { useCloudStorageProject } from '../../../hooks';
import configStore from '../../../services/config';
import ProjectService from '../../../services/projects';
import { CheckoutStatus } from '../component';

/**
 * @param {Object} props - Component props.
 * @param {string} props.projectId - The ID of the project to get the checkout status of.
 * @returns {JSX.Element | undefined} - Returns a JSX element displaying the user that currently has the item checked out or undefined if the checkout is unclaimed.
 */
export function ProjectCheckoutStatus({ projectId }) {
  const { getCheckoutUser } = useCloudStorageProject(projectId);

  const [loading, setLoading] = useState(true);
  const [checkoutUserName, setCheckoutUserName] = useState(undefined);
  const [isReadonlyStale, setIsReadonlyStale] = useState(false);

  useDidMount(() => {
    let current = true;
    (async () => {
      const checkoutUser = configStore.config.feature_flag_cs_store_v2
        ? await ProjectService.getCheckoutUser(projectId)
        : await getCheckoutUser();
      if (!current) {
        return;
      }
      if (checkoutUser?.displayName) {
        setCheckoutUserName(checkoutUser.displayName);
      } else {
        setIsReadonlyStale(true);
      }
      setLoading(false);
    })();
    return () => {
      current = false;
    };
  });

  if (isReadonlyStale) {
    return undefined;
  }

  return (
    <CheckoutStatus
      checkoutUserName={checkoutUserName}
      loading={loading} />
  );
}
