import type { UserState } from './types';

export const initialState: UserState = {
  claims: {
    id              : '',
    name            : '',
    firstname       : '',
    lastname        : '',
    email           : '',
    roles           : [],
    features        : [],
    valid           : false,
    identifyingparty: '',
    subscriptionid  : '',
    productid       : '',
    seatid          : '',
    appSettings     : {},
  },
  subscriptions: [],
  settings     : {
    locale: {
      code              : 'en',
      region            : 'United States',
      currency          : 'USD',
      language          : 'English',
      translation       : 'en-US',
      translationLang   : 'en',
      translationCountry: 'US',
      locale            : 'en-US',
      localeLang        : 'en',
      localeCountry     : 'US',
      decimalSeparator  : '.',
      listSeparator     : ',',
      groupSeparator    : ',',
      timezone          : 'America/New_York',
      units             : 'inches',
    },
  },
};
