import { createSlice } from '@reduxjs/toolkit';
import { addSnack } from './add-snack';
import { closeSnackbar } from './close-snackbar';
import { initialState } from './initial-state';
import { openSnackbar } from './open-snackbar';
import { removeSnack } from './remove-snack';

const slice = createSlice({
  name    : 'snackbar',
  initialState,
  reducers: {
    openSnackbar,
    closeSnackbar,
    addSnack,
    removeSnack,
  },
});

const serializableCheck = {
  ignoredActions: [slice.actions.openSnackbar.type, slice.actions.addSnack.type],
  ignoredPaths  : ['snackbar'],
};

const snackbar = {
  actions: slice.actions,
  reducer: slice.reducer,
  serializableCheck,
};

export default snackbar;

