import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import createIntegratedRemoteModuleLoader from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteDashboard = createIntegratedRemoteModuleLoader(
  'minitabDashboards',
  'DASHBOARD',
  remoteModuleUrlConfigs.DASHBOARD,
);

const LazyRemoteDashboard = lazy(loadRemoteDashboard);

const RemoteDashboard = withErrorBoundary(
  withSuspense(LazyRemoteDashboard, () => createElement(CenteredCircularProgress)),
  'RemoteDashboard',
);

export { loadRemoteDashboard, RemoteDashboard };

