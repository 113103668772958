import type { StorageExplorerColumn } from './constants';
import type { StorageExplorerItem, StorageProviderItem } from '@';
import { createContext, useContext } from 'react';

type TableContextType = {
  rows : (StorageExplorerItem | StorageProviderItem)[];
  columns : StorageExplorerColumn[];
  isLoading : boolean;
  toggleTableOrder : () => void;
  ascOrder : boolean;
  setTableSort : ({ sortBy, ascOrder }: { sortBy: string, ascOrder?: boolean}) => void;
  sortBy : string;
  setSelected : (selected: StorageExplorerItem | StorageProviderItem) => void;
  selected : StorageExplorerItem | StorageProviderItem | undefined;
  disableContextMenus?: boolean;
};

export const initialTableContext: TableContextType = {
  rows               : [],
  columns            : [],
  isLoading          : false,
  toggleTableOrder   : () => undefined,
  ascOrder           : true,
  setTableSort       : () => undefined,
  sortBy             : 'recent',
  setSelected        : () => undefined,
  selected           : undefined,
  disableContextMenus: false,
};

export const TableContext = createContext(initialTableContext);

export const useTable = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTable must be used within CloudExplorerTable');
  }
  return context;
};
