import type { PropsWithChildren } from 'react';
import { Skeleton, Stack } from '@mtb/ui';
import { useTable } from '../../../../context';

type CloudExplorerTableRowCellSkeletonProps = PropsWithChildren<{
  disabled?: boolean;
  hasIcon?: boolean;
}>;
export function CloudExplorerTableRowCellSkeleton({
  disabled = false,
  hasIcon = false,
  children,
}: CloudExplorerTableRowCellSkeletonProps) {
  const { isLoading } = useTable();

  if (!isLoading || disabled) {
    return children;
  }

  return (
    <Stack
      align="center"
      direction="row"
      gap={1}
      sx={{ width: '100%' }}>
      {hasIcon && (
        // @ts-expect-error-next-line - Bad MtbUI Types
        <Skeleton
          sx={{ width: 24, height: 24, borderRadius: 1 }}
          variant="rectangular" />
      )}
      {/* @ts-expect-error-next-line - Bad MtbUI Types */}
      <Skeleton
        sx={{ height: '1.5em' }}
        variant="text">
        {children}
      </Skeleton>
    </Stack>
  );
}

export default CloudExplorerTableRowCellSkeleton;
