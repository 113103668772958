import type { AsyncUserStateThunkState } from '../types';
import AuthenticationClient from '../../../../../clients/authentication';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { fetchUserSubscriptions } from '../fetch-user-subscriptions';
import { validateUser } from '../validate-user';

export const initializeUser = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/initializeUser',
  async (_, { dispatch }) => {
    await dispatch(validateUser());
    await dispatch(fetchUserSubscriptions());
    AuthenticationClient.addHeartbeatListener(() => dispatch(validateUser()));
  },
);
