import { createElement } from 'react';
import CenteredCircularProgress from '../../core/utils/centered-circular-progress';
import withErrorBoundary from '../../core/utils/error-boundary';
import lazy from '../../core/utils/lazy';
import { createStaticRemoteModuleLoader } from '../../core/utils/load-remote-module';
import remoteModuleUrlConfigs from '../../core/utils/remote-url-configs';
import withSuspense from '../../core/utils/with-suspense';

const loadRemoteDiscover = createStaticRemoteModuleLoader(
  'mtb_platformContent',
  'DISCOVER',
  remoteModuleUrlConfigs.DISCOVER,
);

const LazyRemoteDiscover = lazy(loadRemoteDiscover);

const RemoteDiscover = withErrorBoundary(
  withSuspense(LazyRemoteDiscover, () => createElement(CenteredCircularProgress)),
  'RemoteDiscover',
);

export { loadRemoteDiscover, RemoteDiscover };

