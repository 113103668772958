import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mtb/ui';
import { debounce } from '@mtb/utilities';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { useTranslation } from '../../services/i18n';
import { filterItems } from '../../utils';
import { useStorageExplorer } from '../StorageExplorerV1';

const FileSearchInput = ({ loading, ...other }) => {
  const [t] = useTranslation();
  return (
    <TextField
      {...other}
      InputProps={{
        ...other.InputProps,
        placeholder : t('search.placeholder'),
        endAdornment: (
          <>
            {loading ? <CircularProgress
              color="inherit"
              size={20} /> : null}
            {other.InputProps.endAdornment}
          </>
        ),
      }} />
  );
};

export const FileSearch = () => {
  const [t] = useTranslation();
  const { getItemById, searchItem } = useCloudStorageStore();
  const { filter, openItem } = useStorageExplorer();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useMemo(
    () =>
      debounce((value, callback) => {
        searchItem(value, 30).then(callback);
      }, 250),
    [searchItem],
  );

  useEffect(() => {
    let isMounted = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    setIsLoading(true);
    fetch(inputValue, (results) => {
      if (isMounted) {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [inputValue, fetch]);

  const handleOnChange = useCallback(
    async (_, value) => {
      if (!value?.id) {
        return;
      }

      const item = await getItemById(value?.id, value?.driveId, {
        cache: false,
      });
      await openItem?.(item);
    },
    [getItemById, openItem],
  );

  const noOptionsText = useMemo(
    () => (isLoading ? t('connection.loading') : t('search.noResults')),
    [isLoading, t],
  );
  const isOptionEqualToValue = useCallback(
    (option, value) => option?.id === value?.id,
    [],
  );
  const getOptionLabel = useCallback((option) => option?.name ?? '', []);
  const handleOnInputChange = useCallback(
    (_, newInputValue) => setInputValue(newInputValue),
    [],
  );
  const handleFilterOptions = useCallback(
    (options) => {
      const filteredOptions = filterItems(options, filter);
      return filteredOptions;
    },
    [filter],
  );

  return (
    <Autocomplete
      autoComplete
      clearOnBlur
      clearOnEscape
      filterOptions={handleFilterOptions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      options={options}
      renderInput={FileSearchInput}
      sx={{ width: 300 }}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange} />
  );
};
