import {
  Button,
  CheckedOutOutlinedIcon,
  Surface,
  Tooltip,
  styled,
} from '@mtb/ui';
import { CheckoutStatus } from '../../../../../../../CheckoutStatus';
import { useTableRow } from '../../hooks';

const CheckoutUserTooltip = styled(Tooltip)(() => ({
  '& .mtb-ui-tooltip-tooltip': {
    maxWidth       : 'unset',
    backgroundColor: 'transparent',
  },
}));

export function CloudExplorerTableCheckedOutUserTooltip() {
  const { checkoutUser: { displayName } = {} } = useTableRow();

  if (!displayName) {
    return null;
  }

  return (
    <>
      <CheckoutUserTooltip
        disableArrow
        placement="bottom"
        // @ts-expect-error-next-line - Bad MtbUI Types
        PopperProps={{
          offset     : -15,
          altBoundary: true,
        }}
        title={
          // @ts-expect-error-next-line - Bad MtbUI Types
          <Surface
            data-testid="checkout-status-popper"
            elevation={8}
            sx={{ p: 1.5 }}>
            <CheckoutStatus checkoutUserName={displayName} />
          </Surface>
        }>
        <Button
          as="div"
          color="default"
          data-testid="checkout-status-row-action"
          disableRipple
          // @ts-expect-error-next-line - Bad MtbUI Types
          icon={<CheckedOutOutlinedIcon size="small" />}
          size="small"
          // @ts-expect-error-next-line - Bad MtbUI Types
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          variant="text" />
      </CheckoutUserTooltip>
    </>
  );
}
