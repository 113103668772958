import type { OnOpenReason } from '../constants';
import type { StorageProviderItem } from '@';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mtb/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import { useSessionStorage } from '../../../hooks';
import projectService from '../../../services/projects';
import { useBreadcrumbs, useCloudExplorer } from '../hooks';
import { CloudExplorerTableContext } from './context';

type CloudExplorerTableContainerProps = PropsWithChildren<{
  onChange?: ({ breadcrumbs }: { breadcrumbs: StorageProviderItem[] }) => Promise<void>;
}>;
export const CloudExplorerTableContainer = ({ onChange, children }: CloudExplorerTableContainerProps) => {
  const queryClient = useQueryClient();

  const { id, provider, category, defaultFilter, disableCache, onOpen, onError } = useCloudExplorer();

  const { breadcrumbs, setBreadcrumbs, pushBreadcrumb, popBreadcrumb } = useBreadcrumbs();

  const [filter, setFilter] = useSessionStorage({
    noun        : 'filter',
    id,
    initialValue: defaultFilter,
    disableCache,
  });

  const openMutation = useMutation({
    mutationFn: ({ item, reason }: {item: StorageProviderItem, reason?: OnOpenReason}) => onOpen?.(item, reason),
    onSettled : () => {
      queryClient.invalidateQueries({ queryKey: [provider] });
    },
  });

  const handleOpenItem = useCallback(
    async (item: StorageProviderItem, reason?: OnOpenReason) => {
      if (item.folder) {
        pushBreadcrumb(item);
        return;
      }

      try {
        if (!projectService.verifyBeforeOpen({
          type   : item.type,
          itemId : item.id,
          driveId: item.driveId,
        })) {
          return false;
        }
        await openMutation.mutateAsync({ item, reason });
      } catch (error) {
        console.error(error);
        onError?.(error);
      }
    },
    [onError, pushBreadcrumb, openMutation],
  );

  useEffect(() => {
    onChange?.({ breadcrumbs });
  }, [breadcrumbs, onChange]);

  const showTable = useMemo(
    () => category !== CLOUD_STORAGE_CATEGORIES.NONE,
    [category],
  );

  if (!showTable) {
    return null;
  }

  return (
    <CloudExplorerTableContext.Provider
      value={{
        filter,
        setFilter,
        openItem: handleOpenItem,
        breadcrumbs,
        setBreadcrumbs,
        pushBreadcrumb,
        popBreadcrumb,
      }}>
      <Stack
        gap={2}
        height="100%"
        width="100%">
        {children}
      </Stack>
    </CloudExplorerTableContext.Provider>
  );
};
