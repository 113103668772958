import type { ComponentType, PropsWithChildren } from 'react';
import { createElement } from 'react';
import PlatformModule from '../platform-module';

export type WithPlatformProps = { planeId: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withStaticPlatformModule = (Component: ComponentType<any>) => {
  return (props: PropsWithChildren<WithPlatformProps>) => {
    const platformModule = PlatformModule.getStaticPlatformModule();
    return createElement(Component, {
      ...props,
      platform: platformModule,
    } as React.Attributes);
  };
};

export default withStaticPlatformModule;
