import type { AsyncPlaneThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { cleanupPlane } from '../cleanup-plane';

export const cleanupPlanes = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/cleanupPlanes',
  async (_, { dispatch, getState }) => {
    const planes = Object.values(getState().planes);
    await Promise.all(planes.map(plane => dispatch(cleanupPlane(plane.id))));
  },
);
