import type { PlaneErrorInfo, PlaneError as PlaneErrorType } from './types';
import type { CloudStorageError } from '@mtb/cloud-storage';
import type { SerializedError } from '@reduxjs/toolkit';
import { CLOUD_STORAGE_ERRORS } from '@mtb/cloud-storage';

export function GetCloudStorageErrorInfo(err: CloudStorageError): PlaneErrorInfo {
  let { title, description } = DefaultPlaneErrorInfo;
  switch (err.code) {
    case CLOUD_STORAGE_ERRORS.INVALID_SAVE_LOCATION:
      title = 'invalidSaveLocation';
      description = 'invalidSaveLocationMessage';
      break;
    default:
      break;
  }

  return { title, description };
}

export const DefaultPlaneErrorInfo: PlaneErrorInfo = {
  title      : 'tryAgain',
  description: 'unknownError',
};

export class PlaneError extends Error implements PlaneErrorType {
  originalErrorType = '';
  info: PlaneErrorInfo = DefaultPlaneErrorInfo;

  constructor(message: string) {
    super();
    this.name = 'PlaneError';
    this.message = message;
  }

  static FromCloudStorageError(err: CloudStorageError): PlaneError {
    const error = new PlaneError('CloudStorageError');
    error.originalErrorType = err.name;
    error.stack = err.stack ?? '';
    error.cause = err.cause;
    error.info = GetCloudStorageErrorInfo(err);
    return error;
  }

  static FromReduxSerialized(err: SerializedError): PlaneError {
    const error = new PlaneError(err.message ?? 'SerializedError was thrown');
    error.originalErrorType = err.name ?? 'SerializedError';
    error.stack = err.stack ?? '';
    return error;
  }

  static FromUnknownError(err: Error): PlaneError {
    const error = new PlaneError(err.message);
    error.originalErrorType = err.name;
    error.stack = err.stack ?? '';
    error.cause = err.cause;
    return error;
  }

  serialize(): PlaneErrorType {
    return {
      info   : this.info,
      message: this.message,
      name   : this.name,
      stack  : this.stack,
    };
  }
}
