/**
 * @param {any} obj
 * @returns {obj is import('./types').Jsonifiable}
 */
const isPlainObject = obj => {
  // Not typeof object
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
    return false;
  }
  // Is Object.create(null)
  if (obj.constructor === undefined) {
    return true;
  }
  // Wasn't created with Object constructor
  if (Object.prototype.toString.call(obj.constructor.prototype) !== '[object Object]') {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf');
};

/**
 * @param {number} time
 * @returns {Promise<void>}
 */
const sleep = time => new Promise(resolve => setTimeout(resolve, time));

/**
 * @param {number} value
 * @param {number} max
 * @returns {number}
 */
const backoff = (value, max) => Math.ceil(Math.pow(2, Math.log(Math.min(max, Math.max(0, value) + 1))));

/**
 * @typedef {'GET'|'POST'|'PATCH'|'PUT'|'DELETE'} HttpVerbs
 */

/**
 * @typedef {undefined | 'text' | 'json' | 'buffer' | 'arrayBuffer' | 'blob' | 'formData' | 'raw'} ResponseBodyTypes
 */

/**
 * @template {ResponseBodyTypes} [Type=undefined]
 * @typedef {Type extends "text" ? string : Type extends "json" ? import('./types').JsonValue : Type extends "buffer" ? ArrayBuffer : Type extends "arrayBuffer" ? ArrayBuffer : Type extends "blob" ? Blob : Type extends "formData" ? FormData : Type extends "raw" ? ReadableStream<Uint8Array> | null : import('./types').JsonObjectOrString} RequestResponseBody
 */

/**
 * @template {ResponseBodyTypes} [Type=undefined]
 * @typedef RequestResponse
 * @property {boolean} ok
 * @property {number} status
 * @property {string} statusText
 * @property {RequestResponseBody<Type>} data
 * @property {Headers} headers
 * @property {boolean} redirected
 * @property {ResponseType} type
 * @property {string} url
 */

/**
 * @template {HttpVerbs} Verb
 * @template {ResponseBodyTypes} [Type=undefined]
 * @typedef RequestOptions
 * @property {string} resource
 * @property {Record<string, string>} headers
 * @property {Record<string, string>} params
 * @property {Type} [type=Type]
 * @property {Verb extends 'GET' ? undefined : Verb extends 'DELETE' ? undefined : (import('./types').Jsonifiable | BodyInit)} [body]
 * @property {Verb} verb
 * @property {import('./types').Simplify<Partial<Omit<RequestInit, 'body' | 'headers' | 'method'>>>} [fetchOptions]
 */

/**
 * @template {HttpVerbs} [Verb='POST']
 * @template {ResponseBodyTypes} [Type=undefined]
 * @typedef {(options: RequestOptions<Verb, Type>) => RequestOptions<Verb, Type> | undefined} OptionsInterceptor
 */

/**
 * @typedef {(config: { url: string, options: RequestInit }) => { url: string, options: RequestInit } | undefined} RequestInterceptor
 */

/**
 * @template {ResponseBodyTypes} [Type=undefined]
 * @typedef {(response: RequestResponse<Type>) => RequestResponse<Type> | undefined} ResponseInterceptor
 */

/** @template {(arg: any) => any} T */
class Interceptors {
  /** @type {Set<T>} */
  #interceptors = new Set();

  /**
   * @returns the number of (unique) interceptors.
   */
  get size() {
    return this.#interceptors.size;
  }

  /**
   * Appends a new interceptor to the end of the interceptors.
   * @param {T} interceptor
   */
  add(interceptor) {
    this.#interceptors.add(interceptor);
    return this;
  }

  /**
   * Clears out all interceptors.
   */
  clear() {
    this.#interceptors.clear();
  }

  /**
   * Removes a specified interceptor from the interceptors.
   * @param {T} interceptor
   * @returns Returns true if the interceptor existed and has been removed, or false if the interceptor does not exist.
   */
  delete(interceptor) {
    return this.#interceptors.delete(interceptor);
  }

  /**
   * @param {T} interceptor
   * @returns a boolean indicating whether an interceptor exists in the interceptors or not.
   */
  has(interceptor) {
    return this.#interceptors.has(interceptor);
  }

  /**
   * Executes a provided function once per each interceptor, in insertion order.
   * @param {(value: T, key: T, set: Set<T>) => void} callbackfn
   */
  forEach(callbackfn) {
    this.#interceptors.forEach(callbackfn);
  }

  /**
   * Calls the specified callback function for all interceptors.
   * The return value of the callback function is the accumulated result,
   * and is provided as an argument in the next call to the callback function.
   * @template {import('./types').FirstArrayElement<Parameters<T>>} Accumulator
   * @param {(accumulator: Accumulator, value: T, index: number) => Accumulator} callbackfn
   * @param {Accumulator} initialValue
   */
  reduce(callbackfn, initialValue) {
    let accumulator = initialValue;
    let index = 0;
    this.forEach(value => {
      accumulator = callbackfn(accumulator, value, index++);
    });
    return accumulator;
  }

  /**
   * Applies all interceptors to the specified value through a reducing operation
   * and returns the result.
   * @template {import('./types').FirstArrayElement<Parameters<T>>} Accumulator
   * @param {Accumulator} value
   */
  applyTo(value) {
    return this.reduce((acc, interceptor) => interceptor(acc) ?? acc, value);
  }
}

class FetchWrapper {
  DEFAULT_NUMBER_OF_RETRIES = 3;

  optionsInterceptors = /** @type {Interceptors<OptionsInterceptor>} */ (new Interceptors());
  requestInterceptors = /** @type {Interceptors<RequestInterceptor>} */ (new Interceptors());
  responseInterceptors = /** @type {Interceptors<ResponseInterceptor>} */ (new Interceptors());

  /**
   * This is the generic handler that is used to perform different side-effects
   * when an error response or Fetch error occurs. This is designed to be a
   * good all-around starting point, but can be overwritten by the consumer
   * for any product-specific requirements (e.g. redirect to login on 401).
   *
   * Returns a truthy value if the error was handled and should be returned.
   * Otherwise, returning `false` will signal to throw the error higher.
   * @template {HttpVerbs} Verb
   * @template {ResponseBodyTypes} Type
   * @param {Error | RequestResponse<Type>} err
   * @param {RequestOptions<Verb, Type>} options
   * @param {number | false} [retries]
   * @returns {Promise<RequestResponse<Type> | false>}
   */
  async onError(err, options, retries = this.DEFAULT_NUMBER_OF_RETRIES) {
    if (
      !(err instanceof Error) &&
      (err?.status === 503 ||
        err?.status === 502 ||
        (typeof retries === 'number' && retries > 0 && ![401, 403, 404].includes(err?.status)))
    ) {
      await sleep(backoff(retries || 0, this.DEFAULT_NUMBER_OF_RETRIES) * 1000);
      return this.request(options, (retries || 1) - 1);
    }
    return false;
  }
  /**
   * @template {HttpVerbs} Verb
   * @template {ResponseBodyTypes} Type
   * @param {RequestOptions<Verb, Type>['headers']} [headers]
   * @returns {RequestOptions<Verb, Type>['headers'] & { Accept: string }}
   */
  getCommonHeaders(headers) {
    if (headers?.token) {
      headers.Authorization = `Bearer ${headers.token}`;
      delete headers.token;
    }
    return {
      Accept: 'application/vnd.api+json, application/json, text/plain, image/png',
      ...headers,
    };
  }

  /**
   * @template {HttpVerbs} Verb
   * @template {ResponseBodyTypes} Type
   * @param {RequestOptions<Verb, Type>['params']} [params]
   */
  getParamsString(params) {
    return `${Object.keys(params ?? {}).length ? '?' : ''}${new URLSearchParams(params).toString()}`;
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {Response} res
   * @param {Type} [type=Type]
   * @returns {Promise<RequestResponseBody<Type>>}
   */
  async getResponseData(res, type) {
    switch (type) {
      case 'json':
        return /** @type {Type extends "json" ? RequestResponseBody<Type> : never} */ (await res.json());
      case 'text':
        return /** @type {Type extends "text" ? RequestResponseBody<Type> : never} */ (await res.text());
      case 'buffer':
      case 'arrayBuffer':
        return /** @type {Type extends ("arrayBuffer" | "buffer") ? RequestResponseBody<Type> : never} */ (
          await res.arrayBuffer()
        );
      case 'blob':
        return /** @type {Type extends "blob" ? RequestResponseBody<Type> : never} */ (await res.blob());
      case 'formData':
        return /** @type {Type extends "formData" ? RequestResponseBody<Type> : never} */ (await res.formData());
      case 'raw':
        return /** @type {Type extends "raw" ? RequestResponseBody<Type> : never} */ (res.body);
      default:
        if (res.headers.get('content-type')?.includes('json')) {
          return /** @type {[undefined] extends [Type] ? RequestResponseBody<Type> : never} */ (await res.json());
        }
        return /** @type {[undefined] extends [Type] ? RequestResponseBody<Type> : never} */ (await res.text());
    }
  }

  /**
   * @template {HttpVerbs} Verb
   * @template {ResponseBodyTypes} Type
   * @param {Verb} method
   * @param {RequestOptions<Verb, Type>['headers']} [headers]
   * @param {RequestOptions<Verb, Type>['body']} [body]
   */
  getOptions(method, headers = {}, body = undefined, fetchOptions = {}) {
    /** @type {{ method: Verb, headers: RequestOptions<Verb, Type>['headers'] & { Accept: string, 'Content-Type'?: string }, body?: BodyInit }} */
    const options = {
      ...fetchOptions,
      headers: this.getCommonHeaders(headers),
      method,
    };
    if (isPlainObject(body)) {
      if (body !== null && body !== undefined && Object.keys(body).length) {
        options.body = JSON.stringify(body);
        options.headers['Content-Type'] = 'application/json';
      }
    } else if (body !== undefined && body !== null) {
      options.body = body;
    }
    return options;
  }

  /**
   * @template {HttpVerbs} Verb
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<Verb, Type>} options
   * @param {number | false} [retries]
   * @returns {Promise<RequestResponse<Type>>}
   */
  async request(options, retries) {
    try {
      const interceptedOptions = this.optionsInterceptors.applyTo(
        /** @type {import('./types').FirstArrayElement<Parameters<OptionsInterceptor>>} */ ({ ...options }),
      );
      const { resource, headers, params, type, body, verb, fetchOptions } = interceptedOptions;
      const { url: requestUrl, options: requestOptions } = this.requestInterceptors.applyTo({
        url    : `${resource}${this.getParamsString(params)}`,
        options: this.getOptions(verb, headers, body, fetchOptions),
      });
      const response = await fetch(requestUrl, requestOptions);
      const data = await this.getResponseData(response, type).catch(
        () => /** @type {import('types').JsonObject} */ ({}),
      );
      const requestResponse = this.responseInterceptors.applyTo(
        /** @type {RequestResponse<Type>} */ ({
          ok        : response.ok,
          status    : response.status,
          statusText: response.statusText,
          headers   : response.headers,
          redirected: response.redirected,
          type      : response.type,
          url       : response.url,
          // @ts-ignore
          data,
        }),
      );
      if (!requestResponse.ok) {
        // eslint-disable-next-line no-throw-literal
        throw requestResponse;
      }
      return /** @type {RequestResponse<Type>} */ (requestResponse);
    } catch (err) {
      const handled = await this.onError(/** @type {Error | RequestResponse<Type>} */ (err), options, retries);
      if (handled) {
        return handled;
      }
      throw err;
    }
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<'GET', Type> & { retries?: number | false } | RequestOptions<'GET', Type>['resource']} resource
   * @param {RequestOptions<'GET', Type>['params']} [params]
   * @param {RequestOptions<'GET', Type>['headers']} [headers]
   * @param {Type} [type=Type]
   * @param {RequestOptions<'GET', Type>['fetchOptions']} [fetchOptions=undefined]
   * @param {number | false} [retries=undefined]
   */
  async get(resource, params = {}, headers = {}, type = undefined, fetchOptions = undefined, retries = undefined) {
    if (typeof resource === 'string') {
      return this.request({ resource, headers, params, type, fetchOptions, verb: 'GET' }, retries);
    }
    const { retries: optionRetries, params: inParams = {}, headers: inHeaders = {}, ...options } = resource;
    return this.request({ ...options, params: inParams, headers: inHeaders, verb: 'GET' }, optionRetries);
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<'POST', Type> & { retries?: number | false } | RequestOptions<'POST', Type>['resource']} resource
   * @param {RequestOptions<'POST', Type>['params']} [params]
   * @param {RequestOptions<'POST', Type>['headers']} [headers]
   * @param {RequestOptions<'POST', Type>['body']} [body]
   * @param {Type} [type=Type]
   * @param {RequestOptions<'POST', Type>['fetchOptions']} [fetchOptions=undefined]
   * @param {number | false} [retries=undefined]
   */
  async post(
    resource,
    params = {},
    headers = {},
    body = {},
    type = undefined,
    fetchOptions = undefined,
    retries = undefined,
  ) {
    if (typeof resource === 'string') {
      return this.request({ resource, headers, params, body, type, fetchOptions, verb: 'POST' }, retries);
    }
    const {
      retries: optionRetries,
      params: inParams = {},
      headers: inHeaders = {},
      body: inBody = {},
      ...options
    } = resource;
    return this.request(
      { ...options, params: inParams, headers: inHeaders, body: inBody, verb: 'POST' },
      optionRetries,
    );
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<'PATCH', Type> & { retries?: number | false } | RequestOptions<'PATCH', Type>['resource']} resource
   * @param {RequestOptions<'PATCH', Type>['params']} [params]
   * @param {RequestOptions<'PATCH', Type>['headers']} [headers]
   * @param {RequestOptions<'PATCH', Type>['body']} [body]
   * @param {Type} [type=Type]
   * @param {RequestOptions<'PATCH', Type>['fetchOptions']} [fetchOptions=undefined]
   * @param {number | false} [retries=undefined]
   */
  async patch(
    resource,
    params = {},
    headers = {},
    body = {},
    type = undefined,
    fetchOptions = undefined,
    retries = undefined,
  ) {
    if (typeof resource === 'string') {
      return this.request({ resource, headers, params, body, type, fetchOptions, verb: 'PATCH' }, retries);
    }
    const {
      retries: optionRetries,
      params: inParams = {},
      headers: inHeaders = {},
      body: inBody = {},
      ...options
    } = resource;
    return this.request(
      { ...options, params: inParams, headers: inHeaders, body: inBody, verb: 'PATCH' },
      optionRetries,
    );
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<'PUT', Type> & { retries?: number | false } | RequestOptions<'PUT', Type>['resource']} resource
   * @param {RequestOptions<'PUT', Type>['params']} [params]
   * @param {RequestOptions<'PUT', Type>['headers']} [headers]
   * @param {RequestOptions<'PUT', Type>['body']} [body]
   * @param {Type} [type=Type]
   * @param {RequestOptions<'PUT', Type>['fetchOptions']} [fetchOptions=undefined]
   * @param {number | false} [retries=undefined]
   */
  async put(
    resource,
    params = {},
    headers = {},
    body = {},
    type = undefined,
    fetchOptions = undefined,
    retries = undefined,
  ) {
    if (typeof resource === 'string') {
      return this.request({ resource, headers, params, body, type, fetchOptions, verb: 'PUT' }, retries);
    }
    const {
      retries: optionRetries,
      params: inParams = {},
      headers: inHeaders = {},
      body: inBody = {},
      ...options
    } = resource;
    return this.request(
      { ...options, params: inParams, headers: inHeaders, body: inBody, verb: 'PUT' },
      optionRetries,
    );
  }

  /**
   * @template {ResponseBodyTypes} [Type=undefined]
   * @param {RequestOptions<'DELETE', Type> & { retries?: number | false } | RequestOptions<'DELETE', Type>['resource']} resource
   * @param {RequestOptions<'DELETE', Type>['params']} [params]
   * @param {RequestOptions<'DELETE', Type>['headers']} [headers]
   * @param {Type} [type=Type]
   * @param {RequestOptions<'DELETE', Type>['fetchOptions']} [fetchOptions=undefined]
   * @param {number | false} [retries=undefined]
   */
  async delete(resource, params = {}, headers = {}, type = undefined, fetchOptions = undefined, retries = undefined) {
    if (typeof resource === 'string') {
      return this.request({ resource, headers, params, type, fetchOptions, verb: 'DELETE' }, retries);
    }
    const { retries: optionRetries, params: inParams = {}, headers: inHeaders = {}, ...options } = resource;
    return this.request({ ...options, params: inParams, headers: inHeaders, verb: 'DELETE' }, optionRetries);
  }
}

/**
 * @typedef FetchWrapperOverrides
 * @property {FetchWrapper['DEFAULT_NUMBER_OF_RETRIES']} [DEFAULT_NUMBER_OF_RETRIES]
 * @property {FetchWrapper['onError']} [onError]
 * @property {FetchWrapper['getCommonHeaders']} [getCommonHeaders]
 * @property {FetchWrapper['getParamsString']} [getParamsString]
 * @property {FetchWrapper['getResponseData']} [getResponseData]
 * @property {FetchWrapper['getOptions']} [getOptions]
 * @property {OptionsInterceptor[]} [optionsInterceptors]
 * @property {RequestInterceptor[]} [requestInterceptors]
 * @property {ResponseInterceptor[]} [responseInterceptors]
 */

/**
 * @param {FetchWrapperOverrides} [overrides]
 * @returns {FetchWrapper}
 */
export const createFetchWrapper = ({
  DEFAULT_NUMBER_OF_RETRIES,
  onError,
  getCommonHeaders,
  getParamsString,
  getResponseData,
  getOptions,
  optionsInterceptors = [],
  requestInterceptors = [],
  responseInterceptors = [],
} = {}) => {
  const fetchWrapper = new FetchWrapper();
  fetchWrapper.DEFAULT_NUMBER_OF_RETRIES = DEFAULT_NUMBER_OF_RETRIES ?? fetchWrapper.DEFAULT_NUMBER_OF_RETRIES;
  fetchWrapper.onError = onError ?? fetchWrapper.onError;
  fetchWrapper.getCommonHeaders = getCommonHeaders ?? fetchWrapper.getCommonHeaders;
  fetchWrapper.getParamsString = getParamsString ?? fetchWrapper.getParamsString;
  fetchWrapper.getResponseData = getResponseData ?? fetchWrapper.getResponseData;
  fetchWrapper.getOptions = getOptions ?? fetchWrapper.getOptions;
  optionsInterceptors.forEach(interceptor => fetchWrapper.optionsInterceptors.add(interceptor));
  requestInterceptors.forEach(interceptor => fetchWrapper.requestInterceptors.add(interceptor));
  responseInterceptors.forEach(interceptor => fetchWrapper.responseInterceptors.add(interceptor));
  return fetchWrapper;
};

const DefaultFetchWrapper = createFetchWrapper();
export { DefaultFetchWrapper as FetchWrapper };
export default DefaultFetchWrapper;
