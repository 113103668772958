import type { LoadRemoteModuleResult, RemoteModule, Resolve } from './types';
import type { ModuleConfigKey } from '../../types';
import DeprecatedModuleClient from '../../clients/deprecated/module';
import ModuleClient from '../../clients/module';
import MODULES from '../../modules';
import StaticPlatformModule from '../platform-module/static';
import RemoteModuleConfig from '../remote-module-config';
import CreateComponent from './create-component';
import withCssScoping from './with-css-scoping';
import withIntegratedPlatformModule from './with-integrated-platform-module';

type InitInterfaceVersions = {
  v2(scope: string, module: string, Module: RemoteModule): Promise<LoadRemoteModuleResult>;
  [key: string]: (scope: string, module: string, Module: RemoteModule) => Promise<LoadRemoteModuleResult>;
};

const lowestVersion = 2;

const initializedModules = new Map<string, Promise<LoadRemoteModuleResult>>();

const initInterfaceVersions: InitInterfaceVersions = {
  async v2(scope, module, Module) {
    const v2ModuleKeys = [MODULES.PLATFORM?.key, MODULES.MSSO?.key];
    // Allow only specific modules to use version 2 of the remote module interface
    // otherwise throw an error to force the module to upgrade to version 3.
    if (v2ModuleKeys.filter(Boolean).includes(module as (typeof v2ModuleKeys)[number])) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          '[init-remote-module] Version 2 of the remote module interface is no longer being supported, please consider upgrading to version 3.',
        );
      }
    } else {
      throw new Error(
        '[init-remote-module]: Version 2 of the remote module interface is not longer being supported for newer modules, please use version 3.',
      );
    }

    const key = `${scope}/${module}`;

    // Return the promise if it's already initialized
    if (initializedModules.has(key)) {
      const modulePromise = initializedModules.get(key);
      if (modulePromise) {
        return modulePromise;
      }
    }

    let resolve: Resolve | undefined;
    const promise = new Promise<LoadRemoteModuleResult>(res => {
      resolve = res;
    });
    initializedModules.set(`${scope}/${module}`, promise);

    // Load platform core and create module config
    const moduleConfig = DeprecatedModuleClient.createModule(module as ModuleConfigKey);

    // Initialize the module if 'init' method is available
    if (Module?.init) {
      await Module.init(moduleConfig);
    }

    DeprecatedModuleClient.register(moduleConfig);

    // Determine component to return based on Module's Component property
    const result = Module?.Component
      ? { default: withCssScoping(Module.Component, scope) }
      : { default: withCssScoping(CreateComponent(Module), scope) };

    resolve?.(result);
    return promise;
  },

  async v3(scope, module, Module) {
    const key = `${scope}/${module}`;

    // Return the promise if it's already initialized
    if (initializedModules.has(key)) {
      const modulePromise = initializedModules.get(key);
      if (modulePromise) {
        return modulePromise;
      }
    }

    let resolve: Resolve | undefined;
    const promise = new Promise<LoadRemoteModuleResult>(res => {
      resolve = res;
    });

    initializedModules.set(`${scope}/${module}`, promise);

    // Initialize the module if 'init' method is available
    if (Module?.init) {
      // Create a new module configuration for the remote module to configure.
      const moduleConfig = new RemoteModuleConfig(module as ModuleConfigKey);
      await Module.init(moduleConfig, StaticPlatformModule);
      // Register the module configuration with the module manager.
      ModuleClient.registerRemoteModuleConfig(moduleConfig);
    }

    const ModuleComponent = Module?.Component ?? CreateComponent(Module);
    // Wrap the component with a HOC that will pass the instance of the plane from Platform to the component.
    const result = { default: withIntegratedPlatformModule(withCssScoping(ModuleComponent, scope)) };
    resolve?.(result);
    return promise;
  },
};

export { initInterfaceVersions, lowestVersion };
