import type { StorageProviderItem } from '@';
import { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  SearchIcon,
  TextField,
} from '@mtb/ui';
import { useQuery } from '@tanstack/react-query';
import ProviderClient from '../../../../../clients/provider';
import { useTranslation } from '../../../../../services/i18n';
import { filterItems } from '../../../../../utils';
import { useCloudExplorer, useCloudExplorerTable } from '../../../hooks';
import { useDebounce } from './useDebounce';

type FileSearchInputProps = {
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [other: string]: any;
};
const FileSearchInput = ({ loading, ...other }: FileSearchInputProps) => {
  const [t] = useTranslation();
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <TextField
      sx={{ backgroundColor: 'background.50' }}
      {...other}
      InputProps={{
        ...other.InputProps,
        placeholder   : t('search.placeholder'),
        startAdornment: (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
          <InputAdornment placement="start">
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {loading
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
              ? <CircularProgress
                color="inherit"
                size={20} />
              : null
            }
            {other.InputProps.endAdornment}
          </>
        ),
      }} />
  );
};

export const CloudExplorerFileSearch = () => {
  const [t] = useTranslation();
  const { provider } = useCloudExplorer();
  const { filter, openItem } = useCloudExplorerTable();
  const [inputValue, setInputValue] = useState('');

  const debouncedInputValue = useDebounce(inputValue);

  const { data, isFetching, isPlaceholderData, isSuccess } = useQuery({
    queryKey: ['search', provider, debouncedInputValue],
    queryFn : async () => {
      return await ProviderClient.searchItem(provider, debouncedInputValue, 30);
    },
    placeholderData: [],
    enabled        : debouncedInputValue.length > 0,
    // overwrite the default QueryClient options set in CloudExplorer.tsx
    staleTime      : 0,
    refetchInterval: false,
  });

  const handleOnChange = useCallback(
    async (_: unknown, value: StorageProviderItem) => {
      if (!value?.id) {
        return;
      }
      const item = await ProviderClient.getItemById(
        provider,
        value?.id,
        value?.driveId,
        { cache: false },
      );
      if (!item) {
        return;
      }
      await openItem?.(item);
    },
    [openItem, provider],
  );

  const noOptionsText = useMemo(
    () => {
      if (isFetching) {
        return t('connection.loadingPlaceholder');
      }
      if (isPlaceholderData || (isSuccess && data?.length === 0)) {
        return t('search.noResults');
      }
      return '';
    },
    [isFetching, isPlaceholderData, isSuccess, data, t],
  );

  const isOptionEqualToValue = useCallback(
    (option: StorageProviderItem, value: StorageProviderItem) => option?.id === value?.id,
    [],
  );

  const getOptionLabel = useCallback(
    (option: StorageProviderItem) => option?.name ?? '',
    [],
  );

  const handleOnInputChange = useCallback(
    (_: unknown, newInputValue: string) => setInputValue(newInputValue),
    [],
  );

  const handleFilterOptions = useCallback(
    (options: StorageProviderItem[]) => {
      if (!options || options?.length === 0) {
        return options;
      }
      return filterItems(options, filter);
    },
    [filter],
  );

  return (
    <Autocomplete
      autoComplete
      clearOnBlur
      clearOnEscape
      // @ts-expect-error-next-line - Bad MtbUI types
      componentsProps={{ Surface: { elevation: 8 } }}
      // @ts-expect-error-next-line - Bad MtbUI types
      filterOptions={handleFilterOptions}
      // @ts-expect-error-next-line - Bad MtbUI types
      getOptionLabel={getOptionLabel}
      // @ts-expect-error-next-line - Bad MtbUI types
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      // @ts-expect-error-next-line - Bad MtbUI types
      options={data}
      renderInput={FileSearchInput}
      sx={{ width: '65%' }}
      // @ts-expect-error-next-line - Bad MtbUI types
      onChange={handleOnChange}
      onInputChange={handleOnInputChange} />
  );
};
