import type { PlaneState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { checkAllPlanesHealth } from './thunk';

export const checkAllPlanesHealthReducer = (builder: ActionReducerMapBuilder<PlaneState>) => {
  return (
    builder
      .addCase(checkAllPlanesHealth.pending, () => undefined)
      .addCase(checkAllPlanesHealth.fulfilled, () => undefined)
      .addCase(checkAllPlanesHealth.rejected, () => undefined)
  );
};
