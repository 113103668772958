import type { SetLoadingAction } from './types';
import type { LayoutState, WritableLayoutState } from '../types';

export const setLoading = {
  reducer: (state: WritableLayoutState, action: SetLoadingAction) => {
    state.loading = action.payload.loading;
  },
  prepare: (loading: LayoutState['loading']) => {
    return { payload: { loading } };
  },
};
