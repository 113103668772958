import { useCallback } from 'react';
import { Button, OpenInDialogIcon } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuOpenInDesktop() {
  const [t] = useTranslation();
  const { onFlush, onReopenProject, project, menuProps } =
    useStorageProjectMenu();

  const handleOnClick = useCallback(() => {
    menuProps.onClose();

    project.openInDesktop({
      reopenProject: onReopenProject,
      flushProject : onFlush,
    });
  }, [project, onFlush, onReopenProject, menuProps]);

  if (!project.hasDesktopScheme) {
    return null;
  }

  return (
    <Button
      disabled={!project.canOpenInDesktop}
      variant="outlined"
      onClick={handleOnClick}>
      <OpenInDialogIcon />
      {t('connection.openInDesktopApp')}
    </Button>
  );
}
