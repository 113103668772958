import React from 'react';
import {
  FolderOutlinedIcon,
  FolderOpenedOutlinedIcon,
  FolderSharedOutlinedIcon,
  List,
  Collapse,
  Stack,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { CloudProviderCategoryButton } from '../CloudProviderCategoryButton';
import { CloudProviderHeader } from '../CloudProviderHeader';

export const CloudProviderList = ({ provider, providerName, providerIcon }) => {
  const [t] = useTranslation();
  const { isSignedIn } = useProvider(provider);

  return (
    <List>
      <CloudProviderHeader
        provider={provider}
        title={providerName}>
        {providerIcon}
      </CloudProviderHeader>
      <Collapse in={isSignedIn}>
        <Stack
          pl={2.5}
          pt={1}>
          <CloudProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.ALL}
            provider={provider}
            title={t('connection.all')}>
            <FolderOutlinedIcon />
          </CloudProviderCategoryButton>
          <CloudProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.RECENT}
            provider={provider}
            title={t('connection.recent')}>
            <FolderOpenedOutlinedIcon />
          </CloudProviderCategoryButton>
          <CloudProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.SHARED}
            provider={provider}
            title={t('connection.shared')}>
            <FolderSharedOutlinedIcon />
          </CloudProviderCategoryButton>
        </Stack>
      </Collapse>
    </List>
  );
};
