import { createContext } from 'react';

export const initialState =
  /** @type {import('@').StorageProjectMenuContext} */ ({
    oneDrive         : false,
    googleDrive      : false,
    hasAuthedProvider: false,
    project          : {},
    menuProps        : {},
    onFlush          : () => Promise.resolve(),
    onReopenProject  : () => undefined,
  });

export const StorageProjectMenuContext = createContext(initialState);
