import type { ValueOfDefaultFieldOptionsMap } from '@mtb/validation/dist/types/constants';
import { createSchema, STRING_FORMATS } from '@mtb/validation';

export const createSnackbarSchema = () => {
  const schema = createSchema();
  schema
    .addField('id', 'string', { isRequired: true, format: STRING_FORMATS.UUID } as ValueOfDefaultFieldOptionsMap)
    .addField('planeId', 'string', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
    .addField('severity', 'string', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
    .addField('title', 'string', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
    .addField(
      'content',
      null as unknown as undefined,
      {
        isRequired: true,
        type      : undefined,
        oneOf     : [{ type: 'string' }, { type: 'object' }],
      } as unknown as ValueOfDefaultFieldOptionsMap,
    )
    .addField('icon', 'string', { isRequired: true } as ValueOfDefaultFieldOptionsMap)
    .addField('autoHideDuration', 'number', { isRequired: false } as ValueOfDefaultFieldOptionsMap);
  // @ts-expect-error-next-line - anchorOrigin is defined right here so it wont be undefined.
  schema
    .addFieldGroup('anchorOrigin', { isRequired: false } as ValueOfDefaultFieldOptionsMap)
    .getFieldGroup('anchorOrigin')
    .addField('vertical', 'string', {
      isRequired: true,
      pattern   : '^(top|bottom|center)$',
    } as ValueOfDefaultFieldOptionsMap)
    .addField('horizontal', 'string', {
      isRequired: true,
      pattern   : '^(center|left|right)$',
    } as ValueOfDefaultFieldOptionsMap);
  return schema;
};
