import type { AsyncUserStateThunkState } from '../types';
import AuthenticationClient from '../../../../../clients/authentication';
import InterceptorClient from '../../../../../clients/interceptor';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { fetchUserClaims } from '../fetch-user-claims';

export const validateUser = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/validateUser',
  async (_, { dispatch, getState }) => {
    try {
      await dispatch(fetchUserClaims());
      const claims = getState().user.claims;
      if (!claims.valid) {
        throw new Error('Invalid claims');
      }

      await AuthenticationClient.fetchSeatStatus({
        identifyingParty: claims.identifyingparty,
        subscriptionId  : claims.subscriptionid,
        productId       : claims.productid,
        seatId          : claims.seatid,
      });
    } catch (error) {
      InterceptorClient.removeAllInterceptors();
      AuthenticationClient.login();
    }
  },
);
