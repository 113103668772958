import React, { useMemo } from 'react';
import { Button, CircularProgress } from '@mtb/ui';

export const LoadingButton = ({ loading, children, onClick, ...other }) => {
  const buttonContent = useMemo(
    () =>
      loading ? (
        <CircularProgress
          size={15}
          sx={{ color: 'inherit !important' }} />
      ) : (
        children
      ),
    [loading, children],
  );

  return (
    <Button
      disabled={loading}
      sx={{ svg: { margin: 0 } }}
      onClick={onClick}
      {...other}>
      {buttonContent}
    </Button>
  );
};

export default LoadingButton;
