import type { SetAppRailDrawerOpenAction } from './types';
import type { AppRailDrawerIds, WritableLayoutState } from '../types';

export const setAppRailDrawerOpen = {
  reducer: (state: WritableLayoutState, action: SetAppRailDrawerOpenAction) => {
    for (const id in action.payload) {
      if (Object.prototype.hasOwnProperty.call(state.appRailDrawers, id)) {
        state.appRailDrawers[id as AppRailDrawerIds] = action.payload[id as AppRailDrawerIds] ?? false;
      } else {
        throw new Error(`Invalid app rail drawer id: ${id}`);
      }
    }
  },
  prepare: (id: AppRailDrawerIds, open: boolean) => {
    return { payload: { [id]: open } };
  },
};

export default setAppRailDrawerOpen;
